import { useEffect } from 'react'
import {
  getBubbleData,
  getWallet,
  acceptFBidCallUsingReward,
  removeContentRestriction,
} from '../actions/paywall'
import { batch } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../store'
import { getWalletRedirectionUrl, sanitizeCurrentUrlPreRedirect } from '../helpers/login_controller'
import { ApiService } from '../services'
import useWalletWindowMessageHandler from './useWalletWindowMessageHandler'
import { getMediaPlayerElement } from '../helpers/dom'
import { IReward } from '../interfaces'

const winRef = window as any

export const makeArticleVisible = async (config: any) => {
  const unlockDivElement = config?.paywallTargetElement || (await config.functionSelector())
  removeContentRestriction(config, unlockDivElement)
  if (!config?.isMedia) {
    unlockDivElement &&
      !unlockDivElement.classList.contains('fc_content-full-height') &&
      unlockDivElement.classList.add('fc_content-full-height')
  }

  const mediaPlayer = config?.isMedia && unlockDivElement && getMediaPlayerElement(unlockDivElement)
  mediaPlayer && (mediaPlayer as any).pause()
}

export const usePrimaryCard = (bubble: Boolean) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((store: any) => store?.auth?.user)
  const askPrice = useAppSelector((store: any) => store?.paywall?.askPrice)
  const isAuthenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const { launchWallet } = useWalletWindowMessageHandler(bubble)
  const settings = fewCentsBidResponse?.paywallSettings
  const isAuthenticatedNormalUser = isAuthenticated && !user?.isGuestAccount
  const isTipjar = fewCentsBidResponse?.fewCentsTransactionMode === 'tipjar'
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const simUserEmail = config.setSimUserEmail ? config.setSimUserEmail() : ''

  useEffect(() => {
    if (isAuthenticatedNormalUser && fewCentsBidResponse.customerName !== 'N/A') {
      batch(() => {
        dispatch(getWallet(user))
        dispatch(getBubbleData(fewCentsBidResponse))
      })
    }
  }, [isAuthenticatedNormalUser, user, fewCentsBidResponse, dispatch])

  const handleRewardsClick = async (reward: IReward) => {
    if (!isAuthenticatedNormalUser) return

    config.track(['rewards_button_clicked', { isBubble: bubble, price: askPrice }])
    if (reward.alreadyClaimed) {
      acceptUsingReward()
    } else {
      redirectToWalletForReward()
    }
  }

  const redirectToWalletForReward = async () => {
    try {
      let loginKey = ''
      if (config?.isSimEnabled && fewCentsBidResponse) {
        const currentUrl = sanitizeCurrentUrlPreRedirect()
        const payload = {
          loginSource: 'paywall',
          loginPublisherId: fewCentsBidResponse.publisherId,
          loginBidId: fewCentsBidResponse.fewCentsBidId,
          loginRedirectUrl: encodeURIComponent(currentUrl),
          loginMethod: 'sim',
        }
        const response = await ApiService.post(`/v1/customer/createLoginToken`, payload)
        const { data, message, success } = response.data
        if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')
        loginKey = data.loginKey
      }

      const walletUrl = await getWalletRedirectionUrl({
        fewCentsBidResponse,
        askPrice,
        logoUrl: config?.logoUrl,
        simUserEmail,
        loginKey,
        landingPage: 'rewards',
        rewards: settings?.showRewardOption,
        isTopup: isAuthenticatedNormalUser,
      })
      if (fewCentsBidResponse.openWalletInNativeBrowser) {
        // This needs to be fixed for android
        winRef.location = `intent:${walletUrl}#Intent;end`
      } else {
        sessionStorage.setItem('unlockedContentSelector', config?.contentSelector)
        launchWallet(walletUrl)
      }
    } catch (e) {
      console.log('Error in Fewcents paywall', e)
    }
  }

  const acceptUsingReward = () => {
    config.track(['accept_bid_initiated_by_user_using_reward'])
    dispatch(acceptFBidCallUsingReward())
  }

  return {
    isTipjar,
    fcPromptText: settings?.fcPromptText,
    fcTitleText: settings?.fcTitleText,
    fcBalanceText: settings?.fcBalanceText,
    isAuthenticated,
    isAuthenticatedNormalUser,
    rewards: fewCentsBidResponse?.rewards || [],
    user,
    handleRewardsClick,
  }
}
