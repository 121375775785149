import { SocialButton } from '../Button'
import { Apple, Email, Facebook, Google, Guest, Lock } from '../Icons'
import { FC } from 'react'
import { getOsName } from '../../helpers'
import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import Action from '../../constants/actionTypes'
import { Heading3, NormalText } from '../Styled/Typography'
import { Column, Row } from '../Styled/Layouts'

const osName = getOsName().toUpperCase()
const isAppleLoginAllowed = osName === 'IOS' || osName === 'MAC' || osName === 'OS X'

type Props = {
  onClick: Function
  disabled: boolean
  buttonText: string
  isTipjar: boolean
  bubble: Boolean
}

const guestbutton = { title: 'Guest', id: 'guest', icon: <Guest />, visible: true }
const emailButton = { title: 'Email', id: 'fewcents', icon: <Email />, visible: true }

export const LoginMethods: FC<Props> = ({ onClick, disabled, buttonText, isTipjar, bubble }) => {
  const { isHorizontal, fullWidth, isMobile } = useAppSelector(
    (store: any) => store?.paywall?.config
  )
  const showHorizontal = isMobile || bubble ? false : isHorizontal || fullWidth
  const dispatch: any = useAppDispatch()
  const loginMethods = useMemo(() => {
    let methods = [
      {
        title: 'Apple',
        id: 'apple',
        icon: <Apple />,
        visible: isAppleLoginAllowed,
      },
      {
        title: 'Google',
        id: 'google',
        icon: <Google />,
        visible: true,
      },
      // {
      //   title: 'Facebook',
      //   id: 'facebook',
      //   icon: <Facebook />,
      //   visible: true,
      // },
    ]
    if (showHorizontal) {
      methods = [guestbutton, ...methods, emailButton]
    } else {
      methods = [...methods, guestbutton, emailButton]
    }
    guestbutton.visible = isTipjar
    const applicableMethods = methods.filter((x) => x.visible)
    dispatch({ type: Action.UPDATE_LOGIN_METHOD_COUNT, payload: applicableMethods.length })
    return applicableMethods
  }, [isTipjar, showHorizontal, dispatch])

  const slicelength = showHorizontal
    ? loginMethods.length
    : Math.max(3, parseInt(Math.ceil(loginMethods.length / 2).toString()))

  const showTrustElements = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.paywallSettings?.showTrustComponent
  )

  return (
    <Column>
      <Row padding="4px" justifyContent="center">
        {!isTipjar && showTrustElements ? <Lock style={{ marginTop: '-4px' }}></Lock> : null}
        <Heading3>{buttonText}</Heading3>
      </Row>
      {renderButtons(loginMethods.slice(0, slicelength), disabled, onClick, isTipjar)}
      {renderButtons(
        loginMethods.slice(slicelength, loginMethods.length),
        disabled,
        onClick,
        isTipjar
      )}
    </Column>
  )
}

function renderButtons(
  loginMethods: any[],
  disabled: boolean,
  onClick: Function,
  isTipjar: boolean
) {
  return (
    <Row>
      {loginMethods.map((x: any) => (
        <SocialButton
          key={x.title}
          title={x.id}
          onClick={(evt) => onClick(evt, x.id)}
          disabled={disabled}
          isTipjar={isTipjar}
        >
          {x.icon}
          <NormalText fontWeight="500">{x.title}</NormalText>
        </SocialButton>
      ))}
    </Row>
  )
}
