import { isFalsy } from './common'

export function numberWithCommas(value: number) {
  if (value) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      notation: 'compact',
    }).format(value.valueOf())
  }
  return ''
}

export function numberWithCommasAndCurrency(value: number, currency: string) {
  return isFalsy(value) ? `${currency} 0.00` : `${currency} ${numberWithCommas(value)}`
}
