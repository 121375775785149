import { createGlobalStyle } from 'styled-components'
import { fontSizes } from '../components/Styled/FontSizes'

export const GlobalStyles = createGlobalStyle`
  #fewcents-${(props: {
    theme: {
      fontFamily: string
      primaryColor: string
      instanceId: number
      contentPreviewHeight: number
      isPreviewTransparent: boolean
      allowPointerEvents: boolean
      backgroundColor: string
      hasPlayer: boolean
      isLinkout: boolean
    }
  }) => props.theme.instanceId}.fewcents[id^="fewcents-"]:nth-child(n) * {
    box-sizing: border-box;
  }

  .fc_content-full-height {
    position: relative;
  }

  .fc-paywall-container{
    transform: unset !important;
    display: inline-block;
    max-width: 100%;
  }

  .fc_content-preview {   
    height: ${({ theme }) => theme.contentPreviewHeight}px;
    max-height: ${({ theme }) => theme.contentPreviewHeight}px;
    max-width: 100%;
    overflow: hidden;
    pointer-events: ${({ theme }) => (theme.allowPointerEvents ? 'auto' : 'none')};
    user-select: none;
    position: ${({ theme }) => (theme.isPreviewTransparent ? 'unset' : 'relative')};;
  }

  .fc_banner {
    display : flex;
    background-color : ${({ theme }) => theme.primaryColor};
    justify-content : center;
    color : ${({ theme }) => theme.backgroundColor};
    border-radius : 5px;
    width : 60%;
    margin-left : 20%;
    font-size : ${fontSizes.desktop.normal};
    height : 3.5rem;
    align-items : center;
    cursor : pointer;
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: normal;
  }

  .fc_content-preview::before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    max-width: 100%;
    height:  ${({ theme }) => {
      return theme.isPreviewTransparent ? '0px' : '90px'
    }};
    z-index: 1;
    background-color: ${({ theme }) => {
      return theme.isPreviewTransparent ? 'none' : 'rgba(255,255,255,0.78)'
    }};
     
  }

  .fewcents{
    display: ${({ theme }) => (theme?.isLinkout ? 'block' : 'flex')};;
    justify-content: center;
    margin:${({ theme }) => (theme?.hasPlayer || theme?.isLinkout ? '' : '5px 0')};
    align-items: center;
    height:${({ theme }) => (theme?.hasPlayer ? '100%' : 'inherit')};
  }

  .fewcents-hidden{
    display:none;
  }
`
