import { FooterWrapper, StyledImgWrapper, FcLogoContainer } from '../Styled'
import { useAppSelector } from '../../store'
import { Fewcents, Shield } from '../Icons'
import { FC } from 'react'
import styled from 'styled-components'
import { SmallText } from '../Styled/Typography'
import { Row } from '../Styled/Layouts'

export const Img = styled.img`
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 20px;
  height: 22px;
  width: 22px;
  padding: 2px;
  margin-right: 8px;
`
export const TrustText = styled(SmallText)`
  &&& {
    color: ${({ theme }) => theme.backgroundColor};
  }
`

type FooterProps = {
  addOffset?: Boolean
  isBubble?: Boolean
}

export const Footer: FC<FooterProps> = ({ addOffset = false, isBubble = false }) => {
  const popularPaymentMethods = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.popularPaymentMethods
  )
  const showTrustElements = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.paywallSettings?.showTrustComponent
  )
  const showPaymentLogos = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.paywallSettings?.showPaymentLogos    
  )

  const trustText = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.paywallSettings?.trustComponentText
  )

  return (
    <FooterWrapper>
      {showPaymentLogos ? (
      <Row padding="4px">
        {popularPaymentMethods && popularPaymentMethods.length > 0
          ? popularPaymentMethods.slice(0, 5).map((method: any, index: number) => (
              <StyledImgWrapper key={index} addOffset={addOffset} isBubble={isBubble}>
                <img alt="Payment Method" className="method" src={method.pmtMethodLogo}></img>
              </StyledImgWrapper>
            ))
          : null}
        <FcLogoContainer
          title="Know more about fewcents"
          onClick={() => {
            window.open('https://www.fewcents.co/know-more-about-fewcents')
          }}
        >
          <Fewcents height="20" width="88"></Fewcents>
        </FcLogoContainer>
      </Row>) : (
        <Row padding="4px">
        <FcLogoContainer
          title="Know more about fewcents"
          onClick={() => {
            window.open('https://www.fewcents.co/know-more-about-fewcents')
          }}
        >
        <Fewcents height="20" width="88"></Fewcents>
        </FcLogoContainer>
      </Row>
      )
      }
      {showTrustElements ? (
        <Row padding="4px">
          <Shield style={{ marginRight: '4px' }}></Shield>
          <TrustText>{trustText}</TrustText>
        </Row>
      ) : null}
      {
        !isBubble ? <div id = "fc_footer_ad"/> : <div id = "fc_beacon_footer_ad"/>
      }
    </FooterWrapper>
  )
}
