import { createMouseMoveListener } from './utils'
import { PlayerEvents } from './IPlayer'
import { emitter } from '../services/eventemitter'
import { IConfig } from '../interfaces'
import { PlayerBase } from './PlayerBase'

export class JWPlayer extends PlayerBase {
  private instance: HTMLVideoElement

  constructor(video: HTMLVideoElement, config: IConfig, initializedPlayerIds: any[]) {
    super()
    this.instance = video
    //Because the JW controls are outside the video element, we need to
    //wrap the parent of the parent with fc wrapper.
    const parent = video.parentElement?.parentElement?.parentElement
    if (parent) {
      this.targetElement = parent
    }

    this.sourceId = this.assignSourceId()
    this.analyticsData.player = 'JWPlayer'
    if (initializedPlayerIds.includes(this.sourceId)) {
      this.duplicate = true
      return
    }

    this.analyticsData.articleIdentifier = this.sourceId
    this.instance.ontimeupdate = (evt) => {
      emitter.fire(PlayerEvents.timeUpdate, evt)
    }
  }

  private assignSourceId() {
    //The id of the video element for JWPlayer is generally in the format
    //xxx_{videoId}_xxxxxx_div so we split the string by _ and get the videoId
    const id = this.targetElement?.id?.split('_')[1]
    return id !== 'wrapper' ? id || window.location.href : ''
  }

  async play() {
    this.track(['video_played_programmatically'])
    await this.instance.play()
  }

  async pause() {
    this.track(['video_paused_programmatically'])
    await this.instance.pause()
  }

  getCurrentTime() {
    return this.instance.currentTime
  }

  setFullscreenButtonPosition(fsButton: HTMLElement) {
    fsButton.style.padding = '22px 23px'
    fsButton.style.bottom = '0px'
    fsButton.style.right = '11px'
    this.fsButton = fsButton
  }

  setTipjarButton(tipjarButton: any, closeButton: HTMLElement) {
    tipjarButton.style.transition = 'opacity 300ms'
    const wrapper = this.wrapper
    const fsButton = this.fsButton

    closeButton.style.top = '5px'
    closeButton.style.right = '5px'
    closeButton.addEventListener('click', () => {
      this.track(['closing_dismissable_tipjar_button'])
      tipjarButton.style.opacity = '0'
      tipjarButton.FCshown = false
      closeButton.remove()
    })
    const toggleButtons = (show: any) => {
      this.track(
        ['toggling_tipjar_and_fullscreen_button_with_video_controls', { show }],
        this.analyticsData
      )
      const opacity = show ? '1' : '0'
      fsButton && (fsButton.style.opacity = opacity)
      if (!tipjarButton.FCshown) {
        tipjarButton.style.opacity = opacity
      }
    }
    const { onMouseMove, clearTimer } = createMouseMoveListener(toggleButtons, 2000)
    const onLoadOrPlay = () => {
      onMouseMove()
      wrapper?.addEventListener('mousemove', onMouseMove)
      wrapper?.addEventListener('mouseout', onMouseMove)
    }

    this.instance.addEventListener('pause', () => {
      toggleButtons(true)
      wrapper?.removeEventListener('mousemove', onMouseMove)
      wrapper?.removeEventListener('mouseout', onMouseMove)
      clearTimer()
    })
    this.instance.addEventListener('play', onLoadOrPlay)

    return () => {
      this.track(['showing_dismissable_tipjar_button'])
      wrapper?.appendChild(closeButton)
      tipjarButton.style.opacity = '1'
      tipjarButton.FCshown = true
    }
  }
}
