export const fontSizes = {
  desktop: {
    heading1: '20px',
    heading2: '18px',
    heading3: '16px',
    normal: '14px',
    small: '11px',
  },
  mobile: {
    heading1: '20px',
    heading2: '18px',
    heading3: '16px',
    normal: '14px',
    small: '11px',
  },
  smallMobile: {
    heading1: '16px',
    heading2: '13px',
    heading3: '12px',
    normal: '10px',
    small: '8px',
  },
}
