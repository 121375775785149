import { useAppSelector, useAppDispatch } from '../store'
import { useEffect, useState } from 'react'
import { getWalletRedirectionUrl, sanitizeCurrentUrlPreRedirect } from '../helpers/login_controller'
import { ApiService } from '../services/apiService'
import Action from '../constants/actionTypes'
import { updateButtonState } from '../actions/paywall'
import useWalletWindowMessageHandler from './useWalletWindowMessageHandler'

const winRef = window as any

export default function usePreloginPrice(bubble: boolean) {
  const dispatch = useAppDispatch()
  const [loginKey, setLoginKey] = useState('')
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const askPrice = useAppSelector((store: any) => store?.paywall?.askPrice)
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const enableRedirect = useAppSelector((store: any) => store?.paywall?.enableRedirect)
  const isTipjar = fewCentsBidResponse?.fewCentsTransactionMode === 'tipjar'
  const isGuestUser = useAppSelector((store: any) => store?.auth?.user?.isGuestAccount)
  const privacyPolicyCheck = useAppSelector((store: any) => store?.auth?.privacyPolicyCheck)
  const simUserEmail = config.setSimUserEmail ? config.setSimUserEmail() : ''
  const [showPolicyWarning, setShowPolicyWarning] = useState(false)
  const defaultText = isGuestUser ? 'Tip Now' : fewCentsBidResponse?.paywallSettings?.fcButtonText
  const buttonText = isTipjar ? defaultText || 'Tip' : defaultText || 'Unlock'
  const disabled = config?.isSimEnabled ? !enableRedirect : !privacyPolicyCheck
  const { launchWallet } = useWalletWindowMessageHandler(bubble)

  const openFewcentsLogin = async (e: any, loginMethod?: string) => {
    if (!privacyPolicyCheck) {
      setShowPolicyWarning(true)
      return
    }
    e.stopPropagation()
    const eventName = simUserEmail ? 'unlock_button_clicked' : 'login_initialized'
    config.track([eventName, { isBubble: bubble, loginMethod, price: askPrice }])

    let walletUrl = await getWalletRedirectionUrl({
      fewCentsBidResponse,
      askPrice,
      logoUrl: config?.logoUrl,
      loginMethod,
      simUserEmail,
      loginKey,
      rewards: fewCentsBidResponse?.paywallSettings?.showRewardOption,
      isTopup: false,
    })
    if (fewCentsBidResponse?.openWalletInNativeBrowser) {
      // This needs to be fixed for android
      winRef.location = `intent:${walletUrl}#Intent;end`
    } else {
      sessionStorage.setItem('unlockedContentSelector', config?.contentSelector)
      launchWallet(walletUrl)
    }
  }

  useEffect(() => {
    if (config?.isSimEnabled && fewCentsBidResponse) {
      const currentUrl = sanitizeCurrentUrlPreRedirect()
      const payload = {
        loginSource: 'paywall',
        loginPublisherId: fewCentsBidResponse.publisherId,
        loginBidId: fewCentsBidResponse.fewCentsBidId,
        loginRedirectUrl: encodeURIComponent(currentUrl),
        loginMethod: 'sim',
      }
      createLoginKey(payload).then((loginKey) => setLoginKey(loginKey))
    }
  }, [])

  const togglePrivacyPolicy = () => {
    dispatch({ type: Action.TOGGLE_PRIVACY_POLICY_CHECK })
    setShowPolicyWarning(false)
  }

  const handlePolicyClick = (type: string, url: string) => {
    const eventName = type === 'terms' ? 'terms_link_clicked' : 'privacy_link_clicked'
    config.track([eventName, { isBubble: bubble }], {})
    window.open(url)
  }

  const handleSimCheckbox = (e: any) => {
    dispatch(updateButtonState(!enableRedirect))
  }

  return {
    disabled,
    isTipjar,
    buttonText,
    showPreLoginBubbleContentLogo:
      fewCentsBidResponse?.paywallSettings?.showPreLoginBubbleContentLogo,
    showPreLoginBubbleContentText:
      fewCentsBidResponse?.paywallSettings?.showPreLoginBubbleContentText,
    showPreLoginBubbleOpenerIcon: fewCentsBidResponse?.paywallSettings?.showPreLoginBubbleOpenerIcon,
    showPreLoginBubblePrice: fewCentsBidResponse?.paywallSettings?.showPreLoginBubblePrice,
    enableRedirect,
    openFewcentsLogin,
    isMedia: config.isMedia,
    privacyPolicyCheck,
    showPolicyWarning,
    togglePrivacyPolicy,
    handlePolicyClick,
    simUserEmail,
    handleSimCheckbox,
    preLoginBeaconText: fewCentsBidResponse?.paywallSettings?.preLoginBeaconText,
    config,
  }
}

const createLoginKey = async (dataPayload: any) => {
  try {
    const response = await ApiService.post(`/v1/customer/createLoginToken`, dataPayload)
    const { data, message, success } = response.data
    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')
    return data.loginKey
  } catch (e) {
    throw e
  }
}
