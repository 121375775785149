import styled from 'styled-components'
import { fontSizes } from './FontSizes'

interface IText {
  fontWeight?: string
  lineHeight?: string
  color?: string
  ellipsis?: boolean
}

export const Text = styled.span<IText>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${(props) => props.fontWeight || '400'};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.2')};
  ${({ ellipsis }) =>
    ellipsis
      ? ` overflow: hidden;
          text-overflow: ellipsis;
           white-space: nowrap;`
      : ''}
`

export const Heading1 = styled(Text)`
  font-weight: ${(props) => props.fontWeight || '600'};
  font-size: ${fontSizes.desktop.heading1};
  @media (max-width: 540px) {
    font-size: ${fontSizes.mobile.heading1};
  }
  @media (max-width: 280px) {
    font-size: ${fontSizes.smallMobile.heading1};
  }
`

export const Heading2 = styled(Text)`
  font-weight: ${(props) => props.fontWeight || '500'};
  font-size: ${fontSizes.desktop.heading2};
  @media (max-width: 540px) {
    font-size: ${fontSizes.mobile.heading2};
  }
  @media (max-width: 280px) {
    font-size: ${fontSizes.smallMobile.heading2};
  }
`

export const Heading3 = styled(Text)`
  font-weight: ${(props) => props.fontWeight || '500'};
  font-size: ${fontSizes.desktop.heading3};
  @media (max-width: 540px) {
    font-size: ${fontSizes.mobile.heading3};
  }
  @media (max-width: 280px) {
    font-size: ${fontSizes.smallMobile.heading3};
  }
`

export const NormalText = styled(Text)`
  font-size: ${fontSizes.desktop.normal};
  @media (max-width: 540px) {
    font-size: ${fontSizes.mobile.normal};
  }
  @media (max-width: 280px) {
    font-size: ${fontSizes.smallMobile.normal};
  }
`

export const SmallText = styled(Text)`
  font-size: ${fontSizes.desktop.small};
  @media (max-width: 540px) {
    font-size: ${fontSizes.mobile.small};
  }
  @media (max-width: 280px) {
    font-size: ${fontSizes.smallMobile.small};
  }
`

export const Link = styled.a`
  opacity: 0.6;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
