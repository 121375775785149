import { RootContainer } from '../Styled'
import { FC } from 'react'
import TransitionOnMount from '../shared/TransitionOnMount'
import { IConfig } from '../../interfaces'
import { PrimaryCard } from './PrimaryCard'

interface PaywallProps {
  config: IConfig
  baseTransitionStyles?: any
  onClose?: any
  rootContainerStyle?: any
  borderless?: Boolean
  bubble?: Boolean
  handleLogout?: any
}

export const Paywall: FC<PaywallProps> = ({
  config,
  baseTransitionStyles = { width: '100%' },
  rootContainerStyle = {},
  borderless = false,
  bubble = false,
  onClose = null,
  handleLogout,
}) => {
  return (
    <RootContainer style={rootContainerStyle}>
      <TransitionOnMount
        key="paywall"
        type={config?.transition}
        from={config?.transitionFrom}
        customTransition={config?.customTransition}
        duration={config?.transitionDuration}
        baseStyles={baseTransitionStyles}
      >
        <PrimaryCard
          borderless={borderless}
          onClose={onClose}
          config={config}
          bubble={bubble}
          handleLogout={handleLogout}
        ></PrimaryCard>
      </TransitionOnMount>
    </RootContainer>
  )
}
