import { useEffect, useRef, FC } from 'react'
import { IConfig } from '../../interfaces'
import { Paywall } from '../Paywall'
import { UserInfoCard } from './UserInfoCard'

interface Props {
  isContentUnlocked: boolean
  config: IConfig
  handleLogout: any
}

export const BubblePaywall: FC<Props> = ({ isContentUnlocked, config, handleLogout }) => {
  const { track } = config
  const paywallShown = useRef(false)

  useEffect(() => {
    if (!isContentUnlocked && !paywallShown.current) {
      paywallShown.current = true
      track(['show_bubble_fcwall'])
    }

    return () => {
      if (paywallShown.current) {
        paywallShown.current = false
        track(['hide_bubble_fcwall'])
      }
    }
  }, [isContentUnlocked, track])

  return (
    <>
      {isContentUnlocked ? (
        <UserInfoCard handleLogout={handleLogout}></UserInfoCard>
      ) : (
        <>
          <Paywall config={config} borderless bubble={true} handleLogout={handleLogout}></Paywall>
        </>
      )}
    </>
  )
}
