import { WalletDomain } from '../config'
import { getAuthToken } from './auth'
import jwt_decode from 'jwt-decode'
import {isNullOrUndefined} from '../helpers'

interface WalletUrlParams {
  fewCentsBidResponse: any
  askPrice: any
  logoUrl: any
  isTopup?: boolean
  loginMethod?: string
  simUserEmail?: string
  loginKey?: string
  landingPage?: string
  rewards?: boolean
  language?: string
}

export const getWalletRedirectionUrl = async ({
  fewCentsBidResponse,
  askPrice,
  logoUrl,
  isTopup,
  loginMethod,
  simUserEmail,
  loginKey,
  landingPage,
  rewards,
}: WalletUrlParams) => {
  const args: any = {
    rewards: !!rewards,
    login_publisher_id: fewCentsBidResponse.publisherId,
    login_publisher_bid_id: fewCentsBidResponse.fewCentsBidId,
    bid_mode: fewCentsBidResponse?.fewCentsTransactionMode === 'tipjar' ? 'tipjar' : 'paywall',
    bid_price: askPrice,
    landing_page: landingPage,
    bidCurrency: fewCentsBidResponse.customerCurrency,
    bidCountry: fewCentsBidResponse.bidCountry,
    login_publisher_logo_url: logoUrl?.length > 0 ? encodeURIComponent(logoUrl) : '',
    publisher_name:
      fewCentsBidResponse?.publisherName?.length > 0
        ? encodeURIComponent(fewCentsBidResponse.publisherName)
        : '',
    language: fewCentsBidResponse?.languageCode,
  }

  if (isTopup) {
    args.topup_source = 'paywall'
    args.topup_redirect_url = encodeURIComponent(window.location.href)
    const { accessToken } = await getAuthToken()
    let email: any = jwt_decode(String(accessToken))
    if (email && email?.username) {
      args.user_email = encodeURIComponent(email.username)
    }
    if (simUserEmail && simUserEmail.length > 0) {
      args.login_sim_user_email = encodeURIComponent(simUserEmail)
    }
  } else {
    const currentUrl = sanitizeCurrentUrlPreRedirect()
    args.login_source = 'paywall'
    args.login_redirect_url = encodeURIComponent(currentUrl)
    args.login_method = loginMethod
    if (loginKey && simUserEmail && simUserEmail.length > 0) {
      args.login_key = loginKey
      args.login_sim_user_email = encodeURIComponent(simUserEmail)
    }
  }

  const query = Object.keys(args)
    .filter((key) => !!args[key])
    .map((key) => `${key}=${args[key]}`)
    .join('&')

  return `${WalletDomain}?${query}`
}

const deleteFcParams = (searchParams: URLSearchParams) => {
  searchParams.delete('login_key')
  searchParams.delete('device_key')
  searchParams.delete('topup_hash')
  searchParams.delete('next_action')
  return searchParams.toString()
}

export function sanitizeCurrentUrlPreRedirect(): string {
  if (window.location.search) {
    const articleUrl = new URL(window.location.href)
    articleUrl.search = deleteFcParams(articleUrl.searchParams)
    return articleUrl.toString()
  } else if (window.location.hash.includes('?')) {
    const parts = window.location.hash.split('?')
    const searchParams = deleteFcParams(new URLSearchParams(parts[1]))
    console.log('FC-searchParams', searchParams)
    if (!isNullOrUndefined(searchParams)) {
      return `${window.location.origin}${window.location.pathname}${parts[0]}?${searchParams}`
    } else {
      return `${window.location.origin}${window.location.pathname}${parts[0]}`
    }
  }

  return window.location.href
}
