// import { SegmentAnonymousId, SegmentWriteKey } from '../config'
// import { getBrowserName, getDeviceType, getOsName } from '.'
// import { getDeviceIdentity, getOrCreateFCAnonymousID } from './auth'
// import { fc_logger } from './fc_logger'

/**
 * For fewcents-sdk we need to import segment lib from npm to prevent
 * conflict with the host page if the host page also includes their own
 * segment library.
 */

// const defaultProperties: any = {
//   component: 'paywall',
//   componentVersion: '4',
//   deviceKey: getDeviceIdentity(),
//   fcAnonymousID: getOrCreateFCAnonymousID(),
//   os: getOsName(),
//   browser: getBrowserName(),
//   deviceType: getDeviceType(),
//   domain: window.location.hostname,
// }
//@ts-ignore
const analytics = (window.analytics = window.analytics || [])
analytics.methods = ['track', 'addIntegrationMiddleware', 'setAnonymousId']
// Define a factory to create stubs. These are placeholders
// for methods in Analytics.js so that you never have to wait
// for it to load to actually record data. The `method` is
// stored as the first argument, so we can replay the data.
analytics.factory = function (method: any) {
  return function () {
    var args = Array.prototype.slice.call(arguments)
    args.unshift(method)
    analytics.push(args)
    return analytics
  }
}
// For each of our methods, generate a queueing stub.
for (var i = 0; i < analytics.methods.length; i++) {
  var key = analytics.methods[i]
  analytics[key] = analytics.factory(key)
}
// Define a method to load Analytics.js from our CDN,
// and that will be sure to only ever load it once.
analytics.load = function (options: any) {
  // Create an async script element based on your key.
  // const script = document.createElement('script')
  // script.type = 'text/javascript'
  // script.async = true
  // script.src = 'https://cdn.segment.com/analytics.js/v1/' + SegmentWriteKey + '/analytics.min.js'
  // // Insert our script next to the first script element.
  // const first: any = document.getElementsByTagName('script')[0]
  // first.parentNode.insertBefore(script, first)
  // analytics._loadOptions = options
}
analytics._writeKey = 'YOUR_WRITE_KEY'
// Add a version to keep track of what's in the wild.
analytics.SNIPPET_VERSION = '4.15.2'
if (window.performance) {
  track(
    [
      'script_loaded',
      {
        scriptLoadTime: Date.now() - window.performance.timing.navigationStart,
        performanceTiming: JSON.parse(JSON.stringify(window.performance.timing)),
      },
    ],
    {}
  )
} else {
  track(['script_loaded'], {})
}

// const initilize = async () => {
//   try {
//     if (analytics.invoked) return
//     analytics.invoked = true
//     fc_logger.info('initializing the analysis object')
//     // analytics.load({
//     //   integrations: {
//     //     'Segment.io': {
//     //       apiKey: SegmentWriteKey,
//     //       retryQueue: true,
//     //       addBundledMetadata: true,
//     //       unbundledIntegrations: ['Amplitude'],
//     //       protocol: 'https',
//     //     },
//     //   },
//     // })
//     fc_logger.info('analytics initilized')
//     // analytics.setAnonymousId(SegmentAnonymousId)
//   } catch (error) {
//     console.log('failed to load segment')
//   }
// }

export function track(args: any, bidDetails: any) {
  // if (bidDetails.isPaywallEnabledInCountry && bidDetails.articleActive) {
  //   initilize()
  // }
  // let store
  // if (args[0] === 'constructor_called' && args[1]) {
  //   store = args[1]
  //   defaultProperties.config = Object.assign({}, store.getState().paywall['config'])
  //   /**To avoid circular structure error for video players*/
  //   defaultProperties.config.player = {}
  //   defaultProperties.config.paywallTargetElement = {}
  // }
  // if (bidDetails && Object.keys(bidDetails).length) {
  //   delete (bidDetails as any).customerInfo
  //   defaultProperties['bid'] = { ...bidDetails }
  // } else {
  //   if (defaultProperties['bid'] != undefined) delete defaultProperties['bid']
  // }
  // /** Assign default properties to all events */
  // if (args[1]) {
  //   Object.assign(args[1], defaultProperties)
  //   if (args[1].fewCentsBid?.customerInfo) {
  //     /** Filter out customerInfo as it contains sensitive data */
  //     delete args[1].fewCentsBid.customerInfo
  //   }
  // } else {
  //   args[1] = defaultProperties
  // }

  // if (SegmentWriteKey) {
  //   const [eventName, properties, options, callback] = args
  //   analytics.track(eventName, properties, options, callback)
  // }
}

// export const identify = (...args: any) => {
//   if (SegmentWriteKey) {
//     analytics.identify(...args)
//   }
// }
