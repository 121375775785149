import React, { useCallback, useEffect, useState, Fragment, useRef, lazy } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import { Paywall } from '../components/Paywall'
import { Modal } from '../components/Modal'
import { PlayerEvents } from '../video-players/IPlayer'
import { canShowOverlay, updatePaywall } from '../actions/paywall'
import { mediaBaseTransitionStyle } from '../components/Styled'

const SplashScreen = lazy(
  () => import(/* webpackChunkName: "TipjarSplashScreen" */ '../components/Paywall/SplashScreen')
)

const MediaTipjar: React.FC<{
  articleMadeVisible: Boolean
  setArticleMadeVisible: Function
}> = ({ articleMadeVisible, setArticleMadeVisible }) => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const useOverlay = Boolean(config?.showOverlay)
  const isMedia = Boolean(config?.isMedia)
  const [isUnlockedBeforePlay, setIsUnlockedBeforePlay] = useState<boolean>(true)
  const [showTipjar, setShowTipjar] = useState(false)
  const showTipwallButton = useRef<any>(() => {})
  const removeTipButton = useRef<any>(() => {})
  const isPaywallToBePluggedIn =
    fewCentsBidResponse?.isPaywallEnabledInCountry && fewCentsBidResponse?.articleActive
  const isContentUnlocked =
    isPaywallToBePluggedIn === true && fewCentsBidResponse?.unlocked === true
  const isUnlockedRef = useRef(false)
  const showSplash = isContentUnlocked && !articleMadeVisible

  const toggleTipjar = useCallback(() => {
    const show = !showTipjar
    setShowTipjar(show)
    config.track(['toggling_tipjar', { show }])
  }, [showTipjar])

  const makeArticleVisible = useCallback(() => {
    config.player?.hidePaywall()
    setArticleMadeVisible(true)
    if (
      config?.contentSelector === sessionStorage.getItem('unlockedContentSelector') &&
      !isUnlockedBeforePlay
    ) {
      config?.player.play()
      sessionStorage.removeItem('unlockedContentSelector')
    }
  }, [config?.contentSelector, config.player, isUnlockedBeforePlay, setArticleMadeVisible])

  useEffect(() => {
    isUnlockedRef.current = isContentUnlocked
  }, [isContentUnlocked])

  useEffect(() => {
    const player = config?.player
    const paywallIntervalInSeconds = config?.paywallIntervalInSeconds
    if (config.paywallTargetElement) {
      dispatch(canShowOverlay(config.paywallTargetElement, true))
    }
    if (player) {
      player.on(PlayerEvents.timeUpdate, async () => {
        let playTime = await player.getCurrentTime()

        setIsUnlockedBeforePlay(false)
        if (playTime > paywallIntervalInSeconds) {
          if (showTipwallButton.current) {
            showTipwallButton.current()
            showTipwallButton.current = null
          }
        }
      })
    }
    return () => {
      player.off(PlayerEvents.timeUpdate)
    }
  }, [config?.player, config?.paywallIntervalInSeconds])

  useEffect(() => {
    const tipjarButton = document.createElement('button')
    tipjarButton.innerText = 'Support Us'
    tipjarButton.style.background = 'rgba(0,0,0,0.8)'
    tipjarButton.style.padding = '10px 15px'
    tipjarButton.style.position = 'absolute'
    tipjarButton.style.top = '10px'
    tipjarButton.style.right = '10px'
    tipjarButton.style.color = 'white'
    tipjarButton.style.border = 'none'
    tipjarButton.style.cursor = 'pointer'
    tipjarButton.style.border = '1px solid #666666'
    const wrapper = config.player.wrapper

    const closeButton = document.createElement('button')
    closeButton.innerHTML = '&times;'
    closeButton.style.position = 'absolute'
    closeButton.style.border = 'none'
    closeButton.style.background = 'rgba(255,255,255,0.8)'
    closeButton.style.color = 'grey'
    closeButton.style.fontSize = '12px'
    closeButton.style.borderRadius = '1rem'
    closeButton.style.width = '16px'
    closeButton.style.height = '16px'
    closeButton.style.padding = '0px'
    closeButton.style.top = '5px'
    closeButton.style.right = '5px'

    wrapper?.appendChild(tipjarButton)
    tipjarButton.addEventListener('click', onTipjarButtonClicked)
    showTipwallButton.current = config.player.setTipjarButton?.(tipjarButton, closeButton)
    removeTipButton.current = () => {
      config.track(['dismiss_unlock_button_clicked'])
      tipjarButton.remove()
      closeButton.remove()
    }
    if (config.contentType === 'audio') {
      tipjarButton.style.display = 'none'
      dispatch(updatePaywall({ isVisible: false }))
    }

    function onTipjarButtonClicked() {
      toggleTipjar()
      config.track(['unlock_button_clicked'])
    }

    return () => {
      tipjarButton.removeEventListener('click', onTipjarButtonClicked)
      tipjarButton.remove()
      closeButton.remove()
    }
  }, [config.contentType, config.player, toggleTipjar])

  useEffect(() => {
    if (isContentUnlocked) return
    if (showTipjar) {
      config.player.showPaywall()
      config.player.pause()
    } else {
      config.player?.hidePaywall()
      config.player.play()
    }
  }, [showTipjar, isMedia, useOverlay, config.player])

  useEffect(() => {
    if (showSplash) {
      config.player.showPaywall()

      setTimeout(() => {
        makeArticleVisible()
        removeTipButton.current()
      }, 3000)
    }
  }, [config.player, makeArticleVisible, showSplash])

  return (
    <Fragment>
      {isContentUnlocked ? (
        <Fragment>
          {showSplash && (
            <SplashScreen
              useOverlay={useOverlay}
              fewCentsBidResponse={fewCentsBidResponse}
              position="absolute"
              persist={true}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          {showTipjar && (
            <Modal useOverlay={useOverlay} show={showTipjar} toggle={toggleTipjar}>
              <Paywall
                config={config}
                baseTransitionStyles={mediaBaseTransitionStyle}
                borderless={config?.borderless}
                onClose={toggleTipjar}
              />
            </Modal>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default MediaTipjar
