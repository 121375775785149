import styled from 'styled-components'

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  background: none;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  &:hover {
    color: grey !important;
  }
`
