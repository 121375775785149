import { useEffect, useState, FC } from 'react'
import { Modal } from '../Modal'
import { PaywallUnlocked, TipjarUnlocked } from '../Icons'
import { ArtworkUnlockedDiv, RootContainer, StyledCard } from '../Styled'
import TransitionOnMount from '../shared/TransitionOnMount'

const cardStyle = { maxWidth: 'unset' }
interface SplashProps {
  useOverlay: boolean
  fewCentsBidResponse: any
  persist?: boolean
  borderless?: boolean
  position?: string
}
const SplashScreen: FC<SplashProps> = ({
  useOverlay,
  fewCentsBidResponse,
  persist = false,
  borderless = false,
  position = 'fixed',
}) => {
  const [style, setStyle] = useState<any>({
    opacity: 1,
  })

  useEffect(() => {
    if (!persist) return
    let timeout: any = -1
    if (position !== 'absolute') {
      setStyle({
        position,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        opacity: 1,
      })
      timeout = setTimeout(() => setStyle({ opacity: 1 }), 3000)
    }

    return () => clearTimeout(timeout)
  }, [position])

  return (
    <Modal useOverlay={useOverlay} show={useOverlay} toggle={() => {}}>
      <RootContainer style={style}>
        <TransitionOnMount key="splash" type="slide" from="bottom">
          <StyledCard borderless={borderless} style={cardStyle}>
            <ArtworkUnlockedDiv>
              {persist ? (
                <TipjarUnlocked position={position} fewCentsBidResponse={fewCentsBidResponse} />
              ) : (
                <PaywallUnlocked fewCentsBidResponse={fewCentsBidResponse} />
              )}
            </ArtworkUnlockedDiv>
          </StyledCard>
        </TransitionOnMount>
      </RootContainer>
    </Modal>
  )
}

export default SplashScreen
