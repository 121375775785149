import { AUDIO_NOTIFICATION_URL, VIDEO_NOTIFICATION_URL } from '../constants/common'
import { PlayerEvents } from './IPlayer'
import { IConfig } from '../interfaces'
import { emitter } from '../services/eventemitter'
import { PlayerBase } from './PlayerBase'

export class HTML5Audio extends PlayerBase {
  private instance: HTMLAudioElement

  constructor(audio: HTMLAudioElement, config: IConfig, initializedPlayerIds: string[]) {
    super()
    this.instance = audio
    this.sourceId = this.assignSourceId()
    this.analyticsData.player = 'HTML5Audio'
    if (initializedPlayerIds.includes(this.sourceId)) {
      this.duplicate = true
      return
    }

    //Don't apply the paywall to notification sounds from fewcents
    if ([AUDIO_NOTIFICATION_URL, VIDEO_NOTIFICATION_URL].includes(this.sourceId)) {
      return
    }
    this.analyticsData.articleIdentifier = this.sourceId
    this.targetElement = audio
    this.instance.ontimeupdate = (evt) => {
      emitter.fire(PlayerEvents.timeUpdate, evt)
    }
  }

  private assignSourceId = () => {
    let src = this.instance.getAttribute('src')
    if (!src) {
      const sourceElement = this.instance.getElementsByTagName('source')[0]
      src = sourceElement?.getAttribute('src')
    }
    return src || ''
  }

  async play() {
    this.track(['audio_played_programmatically'])
    await this.instance.play()
  }

  async pause() {
    this.track(['audio_paused_programmatically'])
    await this.instance.pause()
  }

  getCurrentTime() {
    return this.instance.currentTime
  }

  setFullscreenButtonPosition(fsButton: HTMLElement) {}
  setTipjarButton(tipjarButton: HTMLElement, closeButton: HTMLElement) {}
}
