export const colors = {
  primary: '#08289A',
  error: '#000000',
  gray: '#a9a9a9',
}

export const boxShadow = '0 5px 30px -2px rgba(154, 161, 171, 0.1)'

export const menuBoxShadow = '0 20px 32px -8px rgba(9, 30, 66, 0.25)'

export const transition = 'all 0.5s'

export const borderRadius = '5px'
