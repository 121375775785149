import styled from 'styled-components'
import { useEffect, useState } from 'react'
import {
  IconContainer,
  LinkContainer,
  RewardContainer,
  RewardDescription,
  RewardsRoot,
} from '../Styled'
import { Close, Info } from '../Icons'
import { Modal } from '../Modal'
import { CloseButton } from '../Modal/styled'
import { useAppSelector } from '../../store'
import { Carousel } from '../Carousel'
import { IReward } from '../../interfaces'
import { Heading3, NormalText, SmallText } from '../Styled/Typography'
import { Row } from '../Styled/Layouts'

const BannerImg = styled.img`
  border-radius: 5px;
  max-width: 100%;
  cursor: pointer;
`

let eventFired = false

const style = { top: '1px', right: '-4px' }

export const Rewards = ({ disabled, rewards, onRewardClick, openFewcentsLogin }: any) => {
  const track = useAppSelector((store: any) => store?.paywall?.config?.track)
  const [showBanner, setShowBanner] = useState(false)
  const [activeReward, setActiveReward] = useState<IReward>(rewards[0])

  const toggleDialog = (evt: any) => {
    if (evt) {
      evt.stopPropagation()
      evt.preventDefault()
    }
    setShowBanner(!showBanner)
  }

  const handleDescriptionClick = (evt: any) => {
    track(['rewards_description_clicked'])
    if (!disabled && onRewardClick) {
      onRewardClick(activeReward)
    } else {
      toggleDialog(evt)
    }
  }

  const handleIconClick = (evt: any) => {
    track(['rewards_icon_clicked'])
    toggleDialog(evt)
  }

  useEffect(() => {
    if (!eventFired) {
      track(['rewards_shown'])
      eventFired = true
    }
  }, [track])

  useEffect(() => {
    if (rewards.length) {
      setActiveReward(rewards[0])
    }
  }, [rewards])

  return (
    <>
      {disabled ? (
        <Carousel
          items={rewards}
          slideShow={!showBanner}
          onActiveSlideChange={(item: IReward) => {
            setActiveReward(item)
          }}
          renderItem={(reward: IReward) => {
            return (
              // <div onClick={handleIconClick}>
              //   <BannerImg alt="dummy" src={reward.paywallBannerUrl}></BannerImg>
              // </div>
              <div>
                <BannerImg alt="dummy" src={reward.paywallBannerUrl} onClick={(evt) => openFewcentsLogin(evt)}></BannerImg>
              </div>
            )
          }}
        ></Carousel>
      ) : (
        <LinkContainer
          hasRewards={!disabled && !!activeReward.rewardBalanceText}
          onClick={handleDescriptionClick}
        >
          {!disabled && activeReward.rewardBalanceText ? (
            <NormalText lineHeight="25px !important"> {activeReward.rewardBalanceText}</NormalText>
          ) : null}
          <Row>
            <Heading3 lineHeight="25px !important" fontWeight="600">
              {activeReward.shortDescriptionLoggedIn}
            </Heading3>
            {/* <IconContainer onClick={handleIconClick}>
              <Info></Info>
            </IconContainer> */}
          </Row>
        </LinkContainer>
      )}

      {showBanner ? (
        <Modal useOverlay={true} show={showBanner} toggle={toggleDialog}>
          <RewardContainer
            onClick={(evt) => {
              //Do not remove this code, I will hount you in your dreams when you are most happy :)
              evt.stopPropagation()
              evt.preventDefault()
            }}
          >
            <RewardsRoot>
              {activeReward.paywallBannerUrl ? (
                <div style={{ padding: '8px' }}>
                  <BannerImg
                    style={{ width: 'calc(100% - 16px) !important', borderRadius: '3px' }}
                    src={activeReward.paywallBannerUrl}
                    alt="banner"
                  ></BannerImg>
                </div>
              ) : null}
              <RewardDescription>{activeReward.campaignLongDescription}</RewardDescription>
            </RewardsRoot>

            <CloseButton onClick={toggleDialog} style={style}>
              <Close />
            </CloseButton>
          </RewardContainer>
        </Modal>
      ) : null}
      {!disabled ? <SmallText style={{ margin: '4px' }}>OR</SmallText> : null}
    </>
  )
}
