import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { useAppDispatch, useAppSelector } from '../store'
import { Bubble } from '../components/Bubble'
import { executeNextAction, addContentBlock, WalletActions } from '../actions/paywall'
import { hasAuthToken, isNullOrUndefined, setDeviceIdentity } from '../helpers'
import { AuthService } from '../services'
import { setCurrentUser } from '../actions/auth'
import { fc_logger } from '../helpers/fc_logger'
import { logoutUser } from '../actions/auth'
import { sanitizeCurrentUrlPreRedirect } from '../helpers/login_controller'
import Action from '../constants/actionTypes'
import useSearchParam from '../hooks/useSearchParams'

export const useAuthWrapper = () => {
  //const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch()
  const nextAction = useSearchParam('next_action')
  const deviceKey = useSearchParam('device_key')
  const loginToken = useSearchParam('login_key')
  // const nextAction = searchParams.get('next_action')
  // const deviceKey = searchParams.get('device_key')
  // const loginToken = searchParams.get('login_key')
  const [isRedirectToPaywall, setIsRedirectToPaywall] = useState<Boolean | null>(null)
  const isGuestUser = useAppSelector((store: any) => store?.auth?.user?.isGuestAccount)
  const [articleMadeVisible, setArticleMadeVisible] = useState<any>(false)
  const [openMenu, setOpenMenu] = useState<any>(false)
  const user = useAppSelector((store: any) => store?.auth?.user)
  const isAuthenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const isPaywallToBePluggedIn =
    fewCentsBidResponse?.isPaywallEnabledInCountry && fewCentsBidResponse?.articleActive
  const isContentUnlocked =
    isPaywallToBePluggedIn === true && fewCentsBidResponse?.unlocked === true

  const { contentSelector, isMedia } = config

  useEffect(() => {
    console.log('FC- urls params for next action', loginToken, deviceKey, nextAction)
    if (
      !isNullOrUndefined(loginToken) &&
      !isNullOrUndefined(deviceKey) &&
      !isNullOrUndefined(nextAction)
    ) {
      if (
        sessionStorage.getItem('unlockedContentSelector') === contentSelector ||
        (sessionStorage.getItem('unlockedContentSelector') === 'undefined' &&
          contentSelector === undefined)
      ) {
        fc_logger.info(`loginToken=${loginToken},deviceKey=${deviceKey},nextAction=${nextAction}`)
        setDeviceIdentity(deviceKey)
        dispatch(executeNextAction(loginToken, deviceKey, nextAction))
      } else {
        window.history.replaceState(
          { additionalInformation: 'reset original url' },
          document.title,
          sanitizeCurrentUrlPreRedirect()
        )
      }

      if (!isMedia) {
        sessionStorage.removeItem('unlockedContentSelector')
      }
    }
  }, [loginToken, deviceKey, nextAction, contentSelector, isMedia])

  useAsync(async () => {
    fc_logger.info('useAsync for setting up of user called in Auth.tsx')
    if (!user?.isGuestAccount)
      if ((await hasAuthToken()) && !isAuthenticated) {
        fc_logger.info('setting up user inside useAsync in Auth.tsx')
        AuthService.getUser().then((response: any) => {
          const { data } = response.data
          dispatch(setCurrentUser(data, {}))
        })
      }
  }, [isAuthenticated])

  useEffect(() => {
    fc_logger.info('inside useEffect for setting isRedirectToPaywall')
    const value = loginToken !== null && deviceKey !== null
    if (value !== isRedirectToPaywall) {
      fc_logger.info('setting isRedirectToPaywall ' + value)
      setIsRedirectToPaywall(value)
    }
  }, [loginToken, deviceKey])

  useEffect(() => {
    setOpenMenu(false)
  }, [isContentUnlocked])

  const handleLogout = async () => {
    // TODO : uncomment this when we have fixed end to end SSO approach
    // await AuthService.logOut(email)
    config.track(['bubble_logout_clicked'], fewCentsBidResponse)
    await AuthService.logOut()
    // removeAuthToken()
    // removeRefreshToken()

    dispatch(logoutUser())
    dispatch({ type: Action.SET_BUBBLE_OPEN, payload: false })
    addContentBlock(!config?.unlockOnLoad, config)
    setIsRedirectToPaywall(false)
    setArticleMadeVisible(false)
  }

  const renderBubble = () => <Bubble handleLogout={handleLogout} />

  return {
    renderBubble,
    isGuestUser,
    articleMadeVisible,
    openMenu,
    isRedirectToPaywall,
    handleLogout,
    setArticleMadeVisible,
    isMedia: config?.isMedia,
    isHorizontal: config?.isHorizontal,
    isCurrencyMismatch: nextAction === WalletActions.CurrencyMismatch,
  }
}
