import { BubbleContainer, MenuOpened } from './styled'
import { useEffect, useRef } from 'react'
import { Articles } from './Articles'
import { Close } from '../Icons'
import { PaywallBubble } from './PaywallBubble'
import { TipjarBubble } from './TipjarBubble'
import { useAppSelector } from '../../store'
import { BubblePaywall } from './BubblePaywall'
import { CloseButton } from '../Modal/styled'
import { useToggleMenu } from '../../hooks/useToggleMenu'
import { UserInfoCard } from './UserInfoCard'

const closeButtonStyle = { top: '-2px', right: '-7px' }

export const Bubble = ({ handleLogout }: any) => {
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const bid = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const authenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const floatingButtonRef = useRef<HTMLDivElement | null>(null)
  const isTipjar = bid?.fewCentsTransactionMode === 'tipjar'
  const preLoginBubbleTipJar = bid?.paywallSettings?.showPreLoginBubbleTipJar && isTipjar
  const preLoginBubblePaywall = bid?.paywallSettings?.showPreLoginBubblePaywall && !isTipjar
  const active = bid?.isPaywallEnabledInCountry && bid.articleActive
  const unlocked = active === true && bid?.unlocked === true
  const { openMenu, verticalTheme, toggleMenu, handleContainerClick, bubblePosition } =
    useToggleMenu()

  useEffect(() => {
    if (openMenu) {
      config.player?.pause()
    } else {
      config.player?.play()
    }
  }, [openMenu])

  const showBubble = active && (authenticated || preLoginBubbleTipJar || preLoginBubblePaywall)

  return showBubble ? (
    <BubbleContainer
      theme={verticalTheme}
      bubblePosition={bubblePosition}
      onClick={handleContainerClick}
      isTipjar={isTipjar}
    >
      {openMenu ? (
        <MenuOpened {...bubblePosition} ref={floatingButtonRef}>
          {!authenticated || isTipjar || !unlocked ? (
            <BubblePaywall
              isContentUnlocked={unlocked}
              config={config}
              handleLogout={handleLogout}
            ></BubblePaywall>
          ) : (
            <>
              <UserInfoCard handleLogout={handleLogout}></UserInfoCard>
              <Articles openMenu={openMenu}></Articles>
            </>
          )}
          <CloseButton onClick={toggleMenu} style={closeButtonStyle}>
            <Close />
          </CloseButton>
        </MenuOpened>
      ) : isTipjar ? (
        <TipjarBubble
          bubblePosition={bubblePosition}
          toggleMenu={toggleMenu}
          bidResponse={bid}
        ></TipjarBubble>
      ) : (
        <PaywallBubble
          bubblePosition={bubblePosition}
          toggleMenu={toggleMenu}
          isContentUnlocked={unlocked}
          bidResponse={bid}
        ></PaywallBubble>
      )}
    </BubbleContainer>
  ) : null
}
