enum Action {
  RESET_AUTH = 'RESET_AUTH',
  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',
  UPDATE_PAYWALL = 'UPDATE_PAYWALL',
  UPDATE_DEFAULT_WALLET = 'UPDATE_DEFAULT_WALLET',
  UPDATE_LAST_TRANSACTION = 'UPDATE_LAST_TRANSACTION',
  UPDATE_CUSTOMER_BALANCE = 'UPDATE_CUSTOMER_BALANCE',
  UPDATE_RECOMMENDED_ARTICLES = 'UPDATE_RECOMMENDED_ARTICLES',
  UPDATE_ASK_PRICE = 'UPDATE_ASK_PRICE',
  SET_IS_LOGGED_OUT = 'SET_IS_LOGGED_OUT',
  UPDATE_CONFIG = 'UPDATE_CONFIG',
  ENABLE_BUTTON = 'ENABLE_BUTTON',
  UPDATE_BID_RESPONSE = 'UPDATE_BID_RESPONSE',
  SET_PARTNER_TERMS_CHECK = 'SET_PARTNER_TERMS_CHECK',
  TOGGLE_PRIVACY_POLICY_CHECK = 'TOGGLE_PRIVACY_POLICY_CHECK',
  TOGGLE_PARTNER_TERMS_CHECK = 'TOGGLE_PARTNER_TERMS_CHECK',
  UPDATE_LOGIN_METHOD_COUNT = 'UPDATE_LOGIN_METHOD_COUNT',
  SET_BUBBLE_OPEN = 'SET_BUBBLE_OPEN',
}

export default Action
