export interface IPlayer {
  analyticsData: any
  isIframe: Boolean
  wrapper?: HTMLElement
  duplicate: Boolean
  sourceId: string
  targetElement?: HTMLElement
  setWrapper: Function
  play: Function
  pause: Function
  getCurrentTime: Function
  setFullscreenButtonPosition: Function
  exitPiPAndFullscreen: Function
  init: Function
  setTipjarButton: Function
  on: Function
  off: Function
  pipResume: Function
  track: Function
}

export enum PlayerEvents {
  mediaChange = 'mediaChange',
  timeUpdate = 'timeUpdate',
  fullscreenChange = 'fullscreenChange',
  screenResize = 'screen_resize',
}
