import { useAppDispatch, useAppSelector } from '../store'
import { WalletDomain } from '../config'
import { fc_logger } from '../helpers/fc_logger'
import { isNullOrUndefined } from '../helpers/common'
import { getBidDetailsPostTopUp } from '../actions/paywall'

export default function useWalletWindowMessageHandler(bubble?: Boolean) {
  let walletWindow: any
  const dispatch: any = useAppDispatch()
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const user = useAppSelector((store: any) => store?.auth?.user)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall.fewCentsBidResponse)
  const isAuthenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const isAuthenticatedNormalUser = isAuthenticated && !user?.isGuestAccount
  const step = isAuthenticatedNormalUser ? 'topup' : 'login'

  const handleNextActionMessage = (event: any) => {
    const eventHead = `${step}:`
    if (event.origin === WalletDomain && event.data.startsWith(eventHead)) {
      fc_logger.info('4.0 flow post message')
      window.removeEventListener('message', handleNextActionMessage)
      let data = event.data.split(eventHead)[1]
      data = JSON.parse(data)
      const loginKey = data['login_key']
      const deviceKey = data['device_key']
      const nextAction = data['next_action']
      if (
        isNullOrUndefined(loginKey) ||
        isNullOrUndefined(deviceKey) ||
        isNullOrUndefined(nextAction)
      ) {
        isAuthenticatedNormalUser
          ? dispatch(getBidDetailsPostTopUp(user, fewCentsBidResponse, config))
          : config.track(['invalid_data_passed_back_from_wallet', { data, isBubble: bubble }])
      } else {
        fc_logger.info(`postMessage Data ${data}`)
        let query = new URLSearchParams(window.location.search)
        query.set('login_key', loginKey)
        query.set('device_key', deviceKey)
        query.set('next_action', nextAction)
        let currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        let authUrl = currentUrl + `?${query.toString()}`
        fc_logger.info(`authUrl ${authUrl}`)
        window.history.replaceState({}, document.title, authUrl)
        config.track([`plugin_refreshed_post_${step}`, { isBubble: bubble }])
      }
    }
  }

  const handleCompleteMessage = (event: any) => {
    console.log('handleCompleteMessage fired')
    if (event.origin === WalletDomain && event.data.startsWith('complete:')) {
      fc_logger.info('wallet processing complete message')
      window.removeEventListener('message', handleCompleteMessage)
      console.log('handleCompleteMessage walletWindow', walletWindow)
      walletWindow?.close()
      console.log('handleCompleteMessage walletWindow closed')
      window.focus()
      config.track([`plugin_focused_post_${step}`, { isBubble: bubble }])
    }
  }

  const launchWallet = (walletUrl: string) => {
    console.log(walletUrl)
    window.addEventListener('message', handleNextActionMessage, false)
    window.addEventListener('message', handleCompleteMessage, false)
    walletWindow = window.open(walletUrl)
    /* setTimeout(() => {
      if (walletWindow === null || walletWindow.closed) {
        window.location.href = walletUrl
      }
    }, 1000)*/
  }

  return { launchWallet }
}
