import Action from '../constants/actionTypes'
import { IAction } from '../interfaces'

const partnerTermsCheckDefaultValue = () => {
  try {
    const item = window.localStorage.getItem('PARTNER_TERMS_CHECK')
    return item && item !== 'undefined' ? JSON.parse(item) : true
  } catch (error) {
    console.warn('Partner terms check not found in local storage!')
    return false
  }
}

const setPartnerTermsCheckValue = (value: any) => {
  window.localStorage.setItem('PARTNER_TERMS_CHECK', JSON.stringify(value))
}

const defaultState = {
  device: null,
  user: null,
  isAuthenticated: false,
  inProgress: false,
  isLoggedOut: false,
  success: false,
  privacyPolicyCheck: true,
  partnerTermsCheck: partnerTermsCheckDefaultValue(),
}

const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case Action.SET_USER:
      return {
        ...state,
        isLoggedOut: false,
        ...action.payload,
      }
    case Action.TOGGLE_PRIVACY_POLICY_CHECK:
      return {
        ...state,
        privacyPolicyCheck: !state.privacyPolicyCheck,
      }
    case Action.SET_PARTNER_TERMS_CHECK: {
      setPartnerTermsCheckValue(action.payload)
      return {
        ...state,
        partnerTermsCheck: action.payload,
      }
    }
    case Action.TOGGLE_PARTNER_TERMS_CHECK: {
      setPartnerTermsCheckValue(!state.partnerTermsCheck)
      return {
        ...state,
        partnerTermsCheck: !state.partnerTermsCheck,
      }
    }
    case Action.RESET_AUTH:
      return {
        ...defaultState,
      }
    case Action.SET_IS_LOGGED_OUT:
      return {
        ...state,
        isLoggedOut: action.payload,
      }
    case Action.LOGOUT:
      return {
        ...defaultState,
        isLoggedOut: true,
      }
    default:
      return state
  }
}

export default reducer
