import { IConfig } from '../interfaces'
import { fc_logger } from './fc_logger'

export function findElementByIdOrClass(identifier: string): HTMLElement | null {
  let element = document.getElementById(identifier)

  if (!element) {
    element = document.querySelector(identifier)
  }

  if (!element) {
    let possibleElements = document.getElementsByClassName(identifier)

    if (possibleElements && possibleElements.length > 0) {
      return possibleElements[0] as HTMLElement
    }

    element = document.querySelector('[itemprop=" + identifier + "]')

    if (!element) {
      element = document.querySelector('[data-element=" + identifier + "]')
    }
  }

  return element
}

export function defaultFunctionSelector(this: unknown, parent?: Node): Promise<HTMLElement> {
  const config = this as IConfig
  parent = (parent || window.document) as Node
  if (!parent) {
    parent = window.document as Node
  }

  return new Promise(async function (resolve) {
    let element = findElementByIdOrClass(config.contentSelector)
    if (element) {
      resolve(element as HTMLElement)
      return
    }

    const observer = new MutationObserver(function (mutations) {
      let selector: string = config.contentSelector
      if (selector.slice(0, 1) === '.' || selector.slice(0, 1) === '#') {
        selector = selector.substr(1)
      }
      mutations.forEach(function (mutation) {
        const nodes = Array.from(mutation.addedNodes)
        for (const node of nodes) {
          if (
            // @ts-ignore
            node.matches &&
            // @ts-ignore
            (node.matches(selector) ||
              // @ts-ignore
              node.matches('#' + selector) ||
              // @ts-ignore
              node.matches('.' + selector))
          ) {
            observer.disconnect()
            // @ts-ignore
            resolve(node)
            return
          }
        }
      })
    })

    if (parent) {
      observer.observe(parent, {
        childList: true,
        subtree: true,
      })
    }
  })
}
export function getMediaPlayerElement(unlockElement: HTMLElement): HTMLElement | null {
  let vidCollection = unlockElement.getElementsByTagName('video')
  let element = null
  if (vidCollection) {
    element = vidCollection[0]
  }
  if (!element) {
    let audCollection = unlockElement.getElementsByTagName('audio')
    if (audCollection) {
      element = audCollection[0]
    }
  }
  return element
}

export function wrapElement(element: HTMLElement, wrapper: HTMLElement) {
  element.parentNode?.insertBefore(wrapper, element)
  wrapper.appendChild(element)
  return wrapper
}

export const isDarkTheme = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    return false
  }
  let r = parseInt(hex.slice(0, 2), 16)
  let g = parseInt(hex.slice(2, 4), 16)
  let b = parseInt(hex.slice(4, 6), 16)

  return r * 0.299 + g * 0.587 + b * 0.114 < 150
}

export const getContainer = (containerId: string, isMedia = false): HTMLElement => {
  let ContainerElement = document.getElementById(containerId)
  if (!ContainerElement) {
    ContainerElement = document.createElement('div')
    ContainerElement.id = containerId
    if (isMedia) {
      ContainerElement.style.position = 'absolute'
      ContainerElement.style.background = 'rgba(255,255,255,0.5)'
      ContainerElement.style.position = 'absolute'
      ContainerElement.style.left = '0px'
      ContainerElement.style.top = '0px'
      ContainerElement.style.height = '100%'
      ContainerElement.style.width = '100%'
      ContainerElement.style.overflow = 'hidden'
    } else {
      ContainerElement.className = 'fc-paywall-container'
      ContainerElement.style.width = '100%'
    }
    fc_logger.info('Found container')
  }

  return ContainerElement
}
