import { ApiService } from './apiService'
import {
  hasAuthToken,
  removeAuthToken,
  removeRefreshToken,
  getDeviceIdentity,
  getFastAuthToken,
} from '../helpers'
import jwt_decode from 'jwt-decode'
import { fc_logger } from '../helpers/fc_logger'

class Auth {
  async isUserLoggedIn() {
    return await hasAuthToken()
  }

  async hasAuthToken() {
    return !!hasAuthToken()
  }

  userNotLoggedIn() {
    return !this.isUserLoggedIn()
  }

  async logOut(email?: any) {
    const deviceDetails = {
      deviceKey: getDeviceIdentity(),
      email,
    }

    return ApiService.put('/v1/customer/logout/device', deviceDetails)
      .then((response) => {
        const { success } = response.data
        if (response.status === 200 && success) {
          return response
        }
      })
      .then(() => {
        fc_logger.info('removing tokens')
        removeAuthToken()
        removeRefreshToken()
        fc_logger.info('removed tokens')
      })
      .catch((err: Error) => {
        removeAuthToken()
        removeRefreshToken()
        fc_logger.info(err)
      })
  }

  getUser() {
    return new Promise((resolve, reject) => {
      if (this.userNotLoggedIn()) {
        resolve(null)
      } else {
        const token = getFastAuthToken()

        if (token) {
          var decoded: any = jwt_decode(String(token))
          const email = decoded.username
          ApiService.get(`/v1/customer/${email}`)
            .then((response) => {
              const { success } = response.data
              if (response.status === 200 && success) {
                return resolve(response)
              }
              return resolve(null)
            })
            .catch((error: Error) => {
              return reject(error)
            })
        } else {
          resolve(null)
        }
      }
    })
  }
}

export const AuthService = new Auth()
