import { useEffect } from 'react'
import { acceptFBidCall, getBubbleData, getWallet } from '../actions/paywall'
import { useAppDispatch, useAppSelector } from '../store'
import { fc_logger } from '../helpers/fc_logger'
import useWalletWindowMessageHandler from './useWalletWindowMessageHandler'
import { getWalletRedirectionUrl } from '../helpers/login_controller'
import { makeArticleVisible } from './usePrimaryCard'

export default function usePostloginPrice() {
  let _interval: any = null
  const dispatch: any = useAppDispatch()
  const user = useAppSelector((store: any) => store?.auth?.user)
  const askPrice = useAppSelector((store: any) => store?.paywall.askPrice)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall.fewCentsBidResponse)
  const config = useAppSelector((store: any) => store?.paywall.config)
  const simUserEmail = config.setSimUserEmail ? config.setSimUserEmail() : ''
  const { launchWallet } = useWalletWindowMessageHandler()
  const isTipjar = fewCentsBidResponse?.fewCentsTransactionMode === 'tipjar'

  useEffect(() => {
    return () => {
      clearInterval(_interval)
    }
  })

  const topup = async () => {
    const walletUpdateFrequencySeconds = 45
    _interval = setInterval(() => callWallet(), walletUpdateFrequencySeconds * 1000)
    let walletUrl = await getWalletRedirectionUrl({
      fewCentsBidResponse,
      askPrice,
      logoUrl: config?.logoUrl,
      simUserEmail,
      rewards: fewCentsBidResponse?.paywallSettings?.showRewardOption,
      isTopup: true,
    })
    launchWallet(walletUrl)
    config.track(['topup_clicked'])
  }

  const callWallet = async () => {
    dispatch(getWallet(user))
  }

  const accept = () => {
    config.track(['accept_bid_initiated_by_user'])
    dispatch(acceptFBidCall()).then(() => {
      if (fewCentsBidResponse) {
        if (fewCentsBidResponse.unlocked) {
          makeArticleVisible(config)
        }
        dispatch(getBubbleData(fewCentsBidResponse))
      }
    })
  }

  const isArticleToBeShownForFree =
    fewCentsBidResponse &&
    fewCentsBidResponse.fewCentsTransactionMode &&
    (fewCentsBidResponse.fewCentsTransactionMode === 'fcnewusercredits' ||
      fewCentsBidResponse.fewCentsTransactionMode === 'metered' ||
      fewCentsBidResponse.fewCentsTransactionMode === 'c2cgiftin' ||
      fewCentsBidResponse.fewCentsTransactionMode === 'alreadyunlocked')

  const needTopup =
    (fewCentsBidResponse?.isCreditAllowed &&
      fewCentsBidResponse?.customerBalance < fewCentsBidResponse?.minBalance) ||
    (!fewCentsBidResponse?.isCreditAllowed && fewCentsBidResponse?.customerBalance < askPrice) ||
    askPrice === -1

  fc_logger.info(`needTopup ${needTopup}`)
  useEffect(() => {
    if (needTopup === true) {
      config.track(['topup_required'])
    }
  }, [fewCentsBidResponse?.fewCentsBidId])

  function handleButtonClick(e: any) {
    e.stopPropagation()
    sessionStorage.setItem('unlockedContentSelector', config?.contentSelector)
    if (needTopup && !isArticleToBeShownForFree) {
      topup()
    } else {
      accept()
    }
  }

  const defaultText =
    fewCentsBidResponse.customerBalance < askPrice
      ? fewCentsBidResponse?.paywallSettings?.fcButtonInSufficientBalanceText
      : fewCentsBidResponse?.paywallSettings?.fcButtonSufficientBalanceText

  const buttonText = isTipjar ? defaultText || 'Tip' : defaultText || 'Unlock'

  return { buttonText, handleButtonClick, isTipjar }
}
