import { FC } from 'react'
import { BackDrop } from './styled'

interface Props {
  children: any
  useOverlay: boolean
  show: boolean
  toggle: Function
}

export const Modal: FC<Props> = ({ children, useOverlay, show, toggle }) => {
  const toggleOverlay = (e: any) => {
    e.stopPropagation()
    toggle()
  }
  if (!useOverlay) {
    return children
  }

  return useOverlay && show ? <BackDrop onClick={toggleOverlay}>{children}</BackDrop> : children
}
