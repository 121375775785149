import Root from 'react-shadow/styled-components'
import { useAuthWrapper } from './useAuthWrapper'
import { useBidWrapper } from './useBidWrapper'
import PaywallContainer from './PaywallContainer'
import { ArticleBubble } from '../components/Bubble/ArticleBubble'
const divStyle = { height: '100%', width: '100%' }

const AuthWrapper: React.FC = () => {
  const {
    renderBubble,
    isGuestUser,
    articleMadeVisible,
    isRedirectToPaywall,
    setArticleMadeVisible,
    isCurrencyMismatch,
  } = useAuthWrapper()

  const { isPaywallEnabled, showRecommendation } = useBidWrapper(
    isRedirectToPaywall,
    isCurrencyMismatch
  )

  return isPaywallEnabled === true ? (
    <Root.Div style={divStyle}>
      <PaywallContainer
        articleMadeVisible={articleMadeVisible}
        setArticleMadeVisible={setArticleMadeVisible}
      />
      {isGuestUser ? null : renderBubble()}
    </Root.Div>
  ) : showRecommendation === true ? (
    <ArticleBubble />
  ) : null
}

export default AuthWrapper
