import { LogoContainer, LogoImg, MainDiv, StyledCard, AnchorLight, Line } from '../Styled'
import { CloseButton } from '../Modal/styled'
import { useEffect, FC, useRef, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useAppDispatch, useAppSelector } from '../../store'
import { usePrimaryCard } from '../../hooks/usePrimaryCard'
import { IConfig } from '../../interfaces'
import { updatePaywall } from '../../actions/paywall'
import { PublisherDefault, User } from '../Icons'
import { Footer } from './Footer'
import { ArticlePrice } from './ArticlePrice'
import { launchFCWallet } from '../../helpers'
import { Column, Flex, Row } from '../Styled/Layouts'
import { Heading3 } from '../Styled/Typography'
import { LogoutButton } from './LogoutButton'
import { Rewards } from './Rewards'
import { PostLogin } from './PostLogin'
import { PreLogin } from './PreLogin'
import { TipjarPrices } from './TipjarPrices'
import  usePreloginPrice  from '../../hooks/usePreloginPrice'
import { FooterAdUnit } from './FooterAdUnit'

function ErrorFallback(props: any) {
  /** TODO: Add UI to be shown when there is render error */
  return <div> </div>
}

let visibilityEventFired = false

const isVisible = (rect: DOMRect): boolean => {
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.y + rect.height / 2 <= (window.innerHeight || document.documentElement.clientHeight)
  )
}

interface PrimaryCardProps {
  borderless?: Boolean
  bubble: Boolean
  onClose?: any
  config: IConfig
  handleLogout?: any
}

let timer: any

export const PrimaryCard: FC<PrimaryCardProps> = ({
  borderless = false,
  bubble = false,
  onClose,
  config,
  handleLogout,
}) => {
  const { track, isMobile, fullWidth } = config
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const user = useAppSelector((store: any) => store?.auth?.user)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const { fcTitleText, fcPromptText, isAuthenticatedNormalUser, handleRewardsClick, rewards } =
    usePrimaryCard(bubble)
  const { openFewcentsLogin } = usePreloginPrice(!!bubble)
  const isTipjar = fewCentsBidResponse?.fewCentsTransactionMode === 'tipjar'
  const showLogoPlugin = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.paywallSettings?.showLogoPlugin
  )
  const fireVisibilityEvent = useCallback(
    (evt: any) => {
      if (!track) return
      let clientRect: any = ref.current?.getBoundingClientRect()
      if (clientRect) {
        const visible = isVisible(clientRect)
        if (!visibilityEventFired && visible) {
          visibilityEventFired = true
          track(['paywall_visible', { isBubble: bubble }])
        }
        if (!bubble && evt !== false) {
          dispatch(updatePaywall({ isVisible: visible }))
        }
      }
    },
    [dispatch, ref, track, bubble]
  )

  const onWindowScroll = useCallback(() => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fireVisibilityEvent(false)
    }, 1000)
  }, [fireVisibilityEvent])

  const isHorizontal = Boolean(config?.isHorizontal)
  useEffect(() => {
    if (ref.current && !visibilityEventFired) {
      track(['paywall_rendered', { isBubble: bubble }])
      window.addEventListener('scroll', onWindowScroll, false)
    }
    window.addEventListener('scroll', fireVisibilityEvent, false)

    let localTimer = setTimeout(() => {
      clearTimeout(localTimer)
      fireVisibilityEvent(false)
    }, 1000)

    return () => {
      clearTimeout(localTimer)
      clearTimeout(timer)
      window.removeEventListener('scroll', fireVisibilityEvent)
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [bubble, isHorizontal, track, fireVisibilityEvent, onWindowScroll])

  function handleRenderError(error: Error) {
    console.log('Paywall render failed', error)
    track(['render_fcwall_failed', { reason: error, isBubble: bubble }])
  }

  const goToButtonClick = () => {
    config.track(['bubble_fc_account_link_clicked'])
    launchFCWallet()
  }

  const showVertical = isMobile || bubble || !fullWidth || !isAuthenticatedNormalUser || isTipjar

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleRenderError}>
      <StyledCard
        ref={ref}
        borderless={borderless}
        isHorizontal={isHorizontal}
        isLinkout={!!config.isLinkout}
        isBubble={bubble}
      >
        {onClose && <CloseButton onClick={onClose}>&times;</CloseButton>}
        <MainDiv isLinkout={!!config.isLinkout}>
          {showLogoPlugin && (
            <LogoContainer>
              <LogoImg>
                {config?.logoUrl ? (
                  <img
                    src={config?.logoUrl}
                    alt="Publisher Logo"
                    hidden={config?.logoUrl == null}
                  />
                ) : (
                  <PublisherDefault></PublisherDefault>
                )}
              </LogoImg>
            </LogoContainer>
          )}

          <Heading3 fontWeight="400">{fcTitleText}</Heading3>
          {!isTipjar ? <ArticlePrice openFewcentsLogin = {openFewcentsLogin} isAuthenticatedNormalUser = {isAuthenticatedNormalUser} /> : null}
          {rewards?.length && !isAuthenticatedNormalUser ? (
            <Row style={{ marginBottom: '15px' }}>
              <Line></Line>
              or
              <Line></Line>
            </Row>
          ) : (
            <Line></Line>
          )}
          {user?.name ? (
            <Row style={{ position: 'relative' }}>
              <User style={{ marginRight: '4px' }}></User>
              <AnchorLight onClick={goToButtonClick}>{user?.name}</AnchorLight>
              {bubble ? <LogoutButton right="0px" onClick={handleLogout}></LogoutButton> : null}
            </Row>
          ) : null}

          <Flex flexDirection={showVertical ? 'column' : 'row'}>
            {isTipjar ? (
              <TipjarPrices bubble={bubble} />
            ) : rewards?.length ? (
              <Rewards
                disabled={!isAuthenticatedNormalUser}
                rewards={rewards}
                onRewardClick={handleRewardsClick}
                openFewcentsLogin = {openFewcentsLogin}
              ></Rewards>
            ) : <div id="fc_inline_ad"/>}

            {isAuthenticatedNormalUser ? (
              <PostLogin fcBalanceText={fcPromptText} />
            ) : (
              <PreLogin bubble={bubble} />
            )}
          </Flex>
        </MainDiv>
        <Footer addOffset={config.isLinkout && !bubble} isBubble={bubble} />
        {/* <FooterAdUnit isBubble={bubble}/> */}
      </StyledCard>
    </ErrorBoundary>
  )
}
