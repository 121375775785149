import { FC } from 'react'
import { useAppSelector } from '../../store'
import { Heading3 } from '../Styled/Typography'
import { MenuClosed } from './styled'

interface Props {
  bubblePosition: any
  toggleMenu: Function
  bidResponse: any
}
export const TipjarBubble: FC<Props> = ({ bubblePosition, toggleMenu, bidResponse }) => {
  const bubbleText = bidResponse?.paywallSettings?.bubbleTipJarText || 'Support Us'
  const isVisible = useAppSelector((store: any) => store?.paywall?.isVisible)

  return !isVisible ? (
    <MenuClosed {...bubblePosition} onClick={toggleMenu} isTipjar={true}>
      <Heading3 title={bubbleText}>{bubbleText}</Heading3>
    </MenuClosed>
  ) : null
}
