import styled, { css } from 'styled-components'
import { Heading3, NormalText, SmallText, Text } from './Typography'
import { showIcon } from '../Button'
import { hexToRgba } from '../../helpers'
import { Column, Row } from './Layouts'

export const HeaderText = styled(Heading3)`
  &&& {
    text-align: center;
    word-wrap: normal;
    overflow: hidden;
    margin-top: 8px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.secondaryColor};
  }
`

export const StyledDiv = styled.div`
  &&& {
    padding: 0;
    width: auto;
  }
`
export const FooterWrapper = styled(Column)`
  width: auto;
  color: ${({ theme }) => theme.backgroundColor};
  background-color: ${({ theme }) => theme.primaryColor};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 8px 4px 4px;
`

export const StyledCaption = styled(SmallText)`
  &&& {
    text-align: center;
    color: ${({ theme }) => theme.secondaryColor};
    opacity: 0.6;
    & a {
      color: ${({ theme }) => hexToRgba(theme.secondaryColor, 0.7)};
    }
    & span {
      margin: 0 4px;
    }
    label {
      width: 60%;
    }
  }
`

export const Anchor = styled(NormalText)`
  font-weight: 600;
  cursor: pointer;
  line-height: 24px !important;
  margin-right: 4px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
export const AnchorLight = styled(Anchor)`
  font-weight: 500;
`

export const Span = styled.a`
  line-height: 24px !important;
  margin-right: 8px;
`

export const StyledParagraph = styled.p`
  margin: 0;
  padding: 8px;
  color: ${({ theme }) => theme.primaryColor};
`
export const StyledSpan = styled.span`
  &&& {
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-right: 2px;
    animation: ${showIcon} 1s ease-in-out 0.1s both;
  }
  div {
    svg {
      fill: ${({ theme }) => theme.primaryColor};
    }
  }
`

export interface ImgWrapper {
  addOffset: Boolean
  isBubble: Boolean
}

export const StyledImgWrapper = styled.div<ImgWrapper>`
  &&& {
    margin: 2px 4px;
    height: 30px;
    width: 33px;
    position: relative;
    img,
    svg {
      position: absolute;
      padding: 4px;
      border-radius: 4px;
      resize: none;
      width: ${({ isBubble }) => (isBubble ? 'calc(100% - 8px)' : '25px')};
      filter: grayscale(1);
      box-sizing: unset !important;
      background-color: ${({ theme }) => theme.backgroundColor};
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`
export const StyledBoxWrapper = styled.div`
  width: 300px;
  margin: 0px auto;
  padding: 0px;
`

export const PrivacyWarning = styled(SmallText)`
  &&& {
    background-color: rgb(211, 47, 47);
    color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 15%), 0px 6px 10px 0px rgb(0 0 0 / 15%),
      0px 1px 18px 0px rgb(0 0 0 / 12%);
  }
`

export const Checkbox = styled.input`
   {
    vertical-align: middle;
    margin: 0px 6px 0px 0px;
    cursor: pointer;
    display: block;
    appearance: auto;
    min-height: 16px;
    min-width: 16px;
  }
`

export const CheckboxLabel = styled(SmallText)`
  &&& {
    position: relative;
    vertical-align: middle;
    font-weight: normal;
    color: ${({ theme }) => theme.secondaryColor};
    text-align: left;
    margin: 0px;
    cursor: default;
    a {
      color: ${({ theme }) => (theme.isDarkTheme ? '#298dd8' : '#063bf9')};
    }
  }
`

export const FcLogoContainer = styled.label`
  cursor: pointer;
  margin-top: 6px;
`
export const RootContainer = styled(Row)`
  &&& {
    margin: 0;
    line-height: 1.2;
    transition: opacity 300ms;
    & * {
      line-height: 1.2;
    }
    ${(props) =>
      props.theme.isMedia &&
      css`
        height: 100%;
        width: 100%;
        position: absolute;
        align-items: center;
      `}
  }
`

export const ArtworkUnlockedDiv = styled.div`
  &&& {
    min-width: 330px;
    max-width: 640px;
    text-align: center;
    position: relative;
    & > .welcome {
      vertical-align: top;
      max-width: 100%;
    }
    img.splach-unlocked {
      height: 220px;
      width: 440px;
    }
    & > .sm {
      display: ${({ theme }) => (theme.isHorizontal ? 'none' : 'inline-block')};
      max-height: 310px;
    }
    & > .lg {
      display: ${({ theme }) => (theme.isHorizontal ? 'inline-block' : 'none')};
    }
    & > .welcomeText {
      position: absolute;
      left: 0;
      bottom: 24px;
      width: 100%;
    }
    & h2 {
      font-size: 18px;
      font-weight: 600;
      word-wrap: normal;
      line-height: 1.2;
      color: hsla(0, 0%, 23%, 1);
      margin-top: 30px;
      padding: 0 10px;
    }
    & img.logo {
      display: inline-block;
      max-width: fit-content;
      height: 26px !important;
      margin: 0;
      padding: 0;
      float: none;
    }
    @media (max-width: 768px) {
      min-width: 0;
      padding: 20px 10px;
      & > img.sm {
        display: inline-block;
      }
      & > img.lg {
        display: none;
      }
      img.splach-unlocked {
        height: 130px;
        width: 300px;
      }
    }
    @media (max-width: 320px) {
      padding: 0;
      max-width: 100%;

      & h2 {
        font-size: 18px;
      }
    }
  }
`
export interface RootLayout {
  isHorizontal?: Boolean
  isLinkout?: Boolean
  isBubble?: Boolean
  borderless: Boolean
}

export const MainDiv = styled(Column)<{ isLinkout: boolean }>`
  position: relative;
  flex: 1;
  text-align: center;
  padding: ${({ isLinkout }) => (isLinkout ? '12px 20px' : '12px')};
  width: auto;

  @media (max-width: 280px) {
    padding: 4px;
  }
`

export const LogoContainer = styled(Row)`
  justify-content: space-evenly;
  margin-bottom: 5px;
  @media (max-width: 540px) {
    margin-top: ${(props) => (props.theme.insideBubble ? '16px' : '4px')};
  }
`

export const LogoImg = styled.div`
  &&& img,
  svg {
    pointer-events: ${({ theme }) => (theme.allowPointerEvents ? 'auto' : 'none')};
    height: 25px;
    display: block;
    text-align: center;
    position: relative;
    &[hidden] {
      display: none;
      visibility: hidden;
    }
  }
  @media (max-width: 320px) {
    max-width: 120px;
    overflow: hidden;
  }
`

export const AbsoluteCenteredWrapper = styled(Row)`
  height: 100%;
  position: absolute;
`

export const TextPaywallRoot = styled.div`
  position: relative;
`
export const StyledCard = styled.div<RootLayout>`
  &&& {
    color: ${({ theme }) => theme.secondaryColor};
    border-width: 0px;
    position: relative;
    height: ${(props) => (props.theme.isMedia ? 'fit-content' : 'auto')};
    border-width: 0px;
    background: ${(props) =>
      props.borderless || props.theme.insideBubble ? '' : props.theme.backgroundColor};
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: normal;
    margin: 0px auto;
    border-radius: 6px;
    max-width: ${({ theme, isBubble }) => {
      return isBubble ? '100%' : theme.maxWidth
    }};
    box-shadow: ${(props) =>
      props.borderless || props.theme.insideBubble
        ? ''
        : props.theme.isMedia
        ? `0 2px 4px 0 rgb(0 0 0 / 15%), 0 2px 4px 0 rgb(0 0 0 / 15%)`
        : `0 4px 10px 0 rgb(0 0 0 / 25%), 0 4px 20px 0 rgb(0 0 0 / 25%)`};
    transition: ${(props) => props.theme.transition};

    @media (max-width: 540px) {
      min-width: 342px;
      max-width: ${({ isLinkout }) => (isLinkout ? '100%' : `max-width: calc(100% - 32px)`)};
      width: ${({ theme }) => (theme.isMedia ? 'auto' : 'unset')};
    }
    @media (max-width: 280px) {
      min-width: 264px;
    }
  }
`

interface ILink {
  disabled?: boolean
  hasRewards: boolean
  invertColors?: boolean
}

export const LinkContainer = styled(Column)<ILink>`
  width: calc(100% - 14px);
  vertical-align: middle;
  text-align: left;
  margin: 4px 0;
  padding: 4px 0;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ hasRewards }) => (hasRewards ? '4px' : '8px 4px')};
  color: ${({ theme, invertColors }) =>
    invertColors ? theme.backgroundColor : theme.primaryColor};
  background-color: ${({ theme, invertColors }) =>
    invertColors ? theme.primaryColor : theme.backgroundColor};
  border-width: 0px;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  border: 1px solid ${({ theme }) => theme.primaryColor};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '')};
  text-align: center;
`

export const TipjarPromptText = styled(NormalText)`
  color: ${({ theme }) => theme.primaryColor};
`

export const IconContainer = styled(Column)`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-left: 12px;
  &:hover {
    background-color: ${({ theme }) => hexToRgba(theme.primaryColor, 0.1)};
  }
  &:active {
    background-color: ${({ theme }) => hexToRgba(theme.primaryColor, 0.2)};
    transform: scale(0.95);
  }
`

export const RewardContainer = styled(Column)`
  position: relative;
  padding: 24px;
  margin: 0px 32px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 20%) 0px 24px 38px 3px,
    rgb(0 0 0 / 20%) 0px 9px 46px 8px;
  background: rgb(237, 237, 237);
  width: fit-content;
  margin: 0px auto;
  max-width: 80%;
  padding: 20px;
`

export const RewardDescription = styled(Column)`
  max-width: 400px;
  align-items: left;
  padding: 8px;
`

export const RewardImage = styled(RewardDescription)`
  max-width: 540px;
  @media (max-width: 540px) {
    max-width: 300px;
  }
`
export const RewardsRoot = styled(RewardDescription)`
  padding: 0px;
  border: 1px solid lightgrey;
  background: #ffffff;
  border-radius: 5px;
`

export const mediaBaseTransitionStyle = {
  position: 'absolute',
  top: 0,
  height: 'max-content',
  minHeight: '100%',
  alignItems: 'center',
  width: '100%',
  display: 'flex',
}

export const Line = styled.hr`
  width: 100%;
  border-width: 0px;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.borderColor};
  margin: 10px;
`

export const LineWithOr = styled.hr`
  background-color: ${({ theme }) => theme.borderColor};
  width: 130px;
  position: relative;
  top: 0px;
  display: inline-block;
  margin: 10px;
`
