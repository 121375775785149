import {
  ArticleContentWrapper,
  ArticleDate,
  ArticleImg,
  ArticleImgWrapper,
  ArticleRow,
  ArticleTitle,
  StyledLink,
} from './styled'
import { FC } from 'react'
import { useAppSelector } from '../../store'

const maxTitleLength = 75
const getNormalizedTitle = (input: string): string =>
  input.length > maxTitleLength ? `${input.substring(0, maxTitleLength)}...` : input

interface Props {
  title: string
  publisher: any
  src: any
  url: string
  date: string
}

export const ArticleCard: FC<Props> = ({ date, title, publisher, src, url }) => {
  const track = useAppSelector((store: any) => store?.paywall?.config?.track)
  const articleClick = () => {
    track(['bubble_content_link_clicked', { url: url, publisher: publisher }])
  }
  return (
    <StyledLink onClick={articleClick} href={url} target="_blank" rel="noreferrer">
      <ArticleRow>
        <ArticleContentWrapper>
          {date ? <ArticleDate>{date}</ArticleDate> : null}
          <ArticleTitle title={title}>{getNormalizedTitle(title)}</ArticleTitle>
        </ArticleContentWrapper>
        {src.includes('N/A') ? null : (
          <ArticleImgWrapper>
            <ArticleImg src={src} />
          </ArticleImgWrapper>
        )}
      </ArticleRow>
    </StyledLink>
  )
}
