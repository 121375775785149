import { emitter } from '../services/eventemitter'
import { IConfig } from '../interfaces'
import { PlayerEvents } from './IPlayer'
import { PlayerBase } from './PlayerBase'

export class VimeoPlayer extends PlayerBase {
  private instance?: any

  constructor(iframe: HTMLElement, config: IConfig, initializedPlayerIds: string[]) {
    super()
    this.sourceId = iframe.getAttribute('src')?.split('?')[0] || ''
    if (initializedPlayerIds.includes(this.sourceId)) {
      this.duplicate = true
      return
    }

    //@ts-ignore Vimeo is loaded by the vimeo script
    this.instance = new Vimeo.Player(iframe)
    this.analyticsData.articleIdentifier = this.sourceId
    this.analyticsData.player = 'Vimeo'
    //For responsive vimeo players, we need to take the immediate div wrapping the iframe
    //But for non-responsive ones, we need to take the iframe itself
    this.targetElement =
      iframe.parentElement && iframe.parentElement.childElementCount === 1
        ? iframe.parentElement
        : iframe

    this.instance.on('timeupdate', (evt: any) => {
      emitter.fire(PlayerEvents.timeUpdate, evt)
    })
  }

  async play() {
    this.track(['video_played_programmatically'])
    await this.instance.play()
  }

  async pause() {
    this.track(['video_paused_programmatically'])
    await this.instance.pause()
  }

  getCurrentTime() {
    return this.instance.getCurrentTime()
  }

  setFullscreenButtonPosition(fsButton: HTMLElement) {
    fsButton.style.padding = '10px'
    fsButton.style.bottom = '15px'
    fsButton.style.right = '85px'
  }

  setTipjarButton(tipjarButton: HTMLElement, closeButton: HTMLElement) {
    tipjarButton.style.transition = 'opacity 600ms'
    tipjarButton.style.top = '10px'
    const wrapper = this.wrapper

    const hideButton = () => {
      tipjarButton.style.opacity = '0'
    }

    wrapper?.appendChild(closeButton)
    this.track(['showing_dismissable_tipjar_button'])
    closeButton.addEventListener('click', () => {
      this.track(['closing_dismissable_tipjar_button'])
      hideButton()
      closeButton.remove()
    })
  }

  setWrapper(wrapper: HTMLElement) {
    super.setWrapper(wrapper)
    wrapper.style.height = 'unset'
    wrapper.style.width = 'unset'
  }

  async exitPiPAndFullscreen() {
    try {
      //await super.exitPiPAndFullscreen()
      const isFullscreen = await this.instance.getFullscreen()
      if (isFullscreen) {
        await this.instance.exitFullscreen()
      }
      const isPip = await this.instance.getPictureInPicture()
      if (isPip) {
        await this.instance.exitPictureInPicture()
      }
    } catch (error) {
      console.error('Error in exiting vimeo pip mode or fullscreen mode', error)
    }
  }
}
