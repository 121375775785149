import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from './common'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { ApiBaseUrl } from '../config'
import { fc_logger } from './fc_logger'
import { v4 as uuidv4 } from 'uuid'

const getAuthTokenName = (): string => {
  return 'fcAuthToken'
}

const getRefreshTokenName = (): string => {
  return 'fcRefreshToken'
}

const getDeviceIdentityKey = (): string => {
  return 'fcDeviceIdentity'
}

export function setAuthToken(accessToken: string) {
  setLocalStorageItem(getAuthTokenName(), accessToken)
}

export function setDeviceIdentity(deviceIdentity: any) {
  setLocalStorageItem(getDeviceIdentityKey(), deviceIdentity)
}

export function getDeviceIdentity() {
  return getLocalStorageItem(getDeviceIdentityKey())
}

export const getOrCreateFCAnonymousID = (): string => {
  const fcAnonymousIDKey = 'fcAnonymousID'
  const fcAnonymousID = getLocalStorageItem(fcAnonymousIDKey)

  if (fcAnonymousID) {
    return fcAnonymousID
  } else {
    const uid = uuidv4()
    setLocalStorageItem(fcAnonymousIDKey, uid)
    return uid
  }
}

const handleNextAccessTokenError = () => {
  removeDeviceIdentity()
  removeAuthToken()
  removeRefreshToken()
  window.location.reload()
  fc_logger.info('handling nextAccessToken error, clearing env cookies, reloading page')
}

export const getAuthToken = async () => {
  let currentAuthToken = getLocalStorageItem(getAuthTokenName())
  try {
    if (
      currentAuthToken &&
      'UNDEFINED' !== currentAuthToken.toUpperCase() &&
      'INVALID' !== currentAuthToken.toUpperCase()
    ) {
      const decoded: any = jwt_decode(String(currentAuthToken))
      const milliSecondsIn5Minutes = 5 * 60 * 1000
      const isAuthTokenAboutToExpire = decoded.exp * 1000 - Date.now() <= milliSecondsIn5Minutes
      if (isAuthTokenAboutToExpire) {
        fc_logger.info('auth token is about to expire, refreshing')
        const url = ApiBaseUrl + '/v1/customer/nextAccessToken'
        const response = await axios.put(url, {
          email: decoded.username,
          refreshToken: getRefreshToken(),
          deviceKey: getDeviceIdentity(),
        })
        const {
          success,
          data: { accessToken },
        } = response.data
        if (response.status === 200 && success) {
          setAuthToken(accessToken)
          fc_logger.info('auth token refreshed')
          return { accessToken }
        } else {
          handleNextAccessTokenError()
          return { accessToken: null }
        }
      } else {
        fc_logger.info('returning currentAuthToken')
        return { accessToken: currentAuthToken }
      }
    } else {
      fc_logger.info('auth token is null')
      return { accessToken: null }
    }
  } catch (error) {
    handleNextAccessTokenError()
    fc_logger.info(error)
    return { accessToken: null }
  }
}

export function getFastAuthToken() {
  return getLocalStorageItem(getAuthTokenName())
}

export const hasAuthToken = async () => {
  const { accessToken } = await getAuthToken()
  return !!accessToken
}

export function removeAuthToken() {
  removeLocalStorageItem(getAuthTokenName())
}

export function setRefreshToken(refreshToken: string) {
  setLocalStorageItem(getRefreshTokenName(), refreshToken)
}

export function getRefreshToken() {
  return getLocalStorageItem(getRefreshTokenName())
}

export function removeRefreshToken() {
  removeLocalStorageItem(getRefreshTokenName())
}

function removeDeviceIdentity() {
  removeLocalStorageItem(getDeviceIdentityKey())
}
