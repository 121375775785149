import { fc_logger } from '../helpers/fc_logger'

export function showSuccessMessage(message: string) {
  const toastMessage = message || 'Successful...'
  fc_logger.info(toastMessage)
}

export function showFailureMessage(message: string) {
  const toastMessage = message || 'Failed...'
  fc_logger.info(toastMessage)
}

export function setForcedLogOutMessage(message: string) {
  return window.localStorage.setItem('fc_forced_logout_error', message)
}
