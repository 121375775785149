import { combineReducers } from 'redux'
import auth from './auth'
import paywall from './paywall'
import bubble from './bubble'

function lastAction(state = null, action: any) {
  return action
}

export default combineReducers({
  auth,
  paywall,
  lastAction,
  bubble,
})
