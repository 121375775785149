import React, { useEffect, useMemo, useRef } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import { Fonts, GlobalStyles } from './styles'
import { updateConfig , updateBidResponse } from './actions/paywall'
import { useAppDispatch, useAppSelector } from './store'
import AuthWrapper from './containers/Auth'

//const AuthWrapper = lazy(() => import(/* webpackChunkName: "Auth" */'./containers/Auth'))

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const isMobile = window.matchMedia('screen and (max-width: 540px)').matches
  const isTipjar = fewCentsBidResponse?.fewCentsTransactionMode === 'tipjar'
  const ref = useRef<number>(0)

  ref.current += 1

  const showHorizontal = useMemo(() => {
    if (isMobile) {
      return false
    }
    if (config.layout === 'horizontal' || config.isHorizontal) {
      return true
    }
    if (config.layout === 'vertical') {
      return false
    }
    return config.contentType !== 'media' && isTipjar
  }, [config.layout, isMobile, isTipjar, config.isHorizontal, config.contentType])

  useEffect(() => {
    dispatch(updateConfig({ isHorizontal: false, isMobile }))
  }, [showHorizontal, dispatch])

  const themeValue = useMemo(() => {
    return {
      ...theme,
      instanceId: ref.current,
      fontFamily: config.fontFamily,
      primaryColor: (config?.primaryColor || theme.primaryColor).trim(),
      contentPreviewHeight: config?.contentPreviewHeight,
      isPreviewTransparent: config?.isPreviewTransparent,
      isMedia: config?.contentType === 'media',
      hasPlayer: !!config?.player,
      isHorizontal: false,
      allowPointerEvents: config.allowPointerEvents,
      backgroundColor: config.backgroundColor,
      secondaryColor: config.secondaryColor,
      borderColor: config.borderColor,
      isDarkTheme: config.isDarkTheme,
      isLinkout: config.isLinkout,
      maxWidth:
        config.isLinkout || config.fullWidth ? '100%' : config.isHorizontal ? '540px' : '350px',
    }
  }, [config, showHorizontal])

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose)
    function handleWindowClose(evt: any) {
      config.track(['browser_closed'])
    }
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
  }, [config.track])

  return (
    <ThemeProvider theme={themeValue}>
      <Fonts id={`fewcents-${ref.current}`} isLinkout={config.isLinkout} className="fewcents">
        <AuthWrapper />
        <GlobalStyles />
      </Fonts>
      <div id = "fc_footer_ad1"/>
    </ThemeProvider>
  )
}

export default App
