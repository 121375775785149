import { applyMiddleware, createStore, compose } from 'redux'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import combinedReducers from './reducers'

export const storeBuilder = () =>
  createStore(combinedReducers, compose(applyMiddleware(thunkMiddleware)))
const emptyStore = storeBuilder()

export type RootState = ReturnType<typeof emptyStore.getState>
export type AppDispatch = typeof emptyStore.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
