import * as React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Absolute } from '../Bubble/styled'
import { Line } from '../Styled'

const CarouselWrapper = styled.div`
  display: flex;
  width: 100%;
`
const Container = styled(CarouselWrapper)`
  display: flex;
  flex-direction: column;
`
const Indicators = styled(CarouselWrapper)`
  display: flex;
  width: 100%;
  justify-content: center;
`

interface ICarouselSlide {
  frozen?: boolean
}

const animation = keyframes`
  { 0% 
    { 
      opacity:0 
    }
    50%
    {
      opacity:1
    }
    100%
    {
      opacity:0
    }
  }
`
/* animation-name: ${({ frozen }) => (frozen ? '' : animation)};
  animation-duration: 5s;
  animation-iteration-count: infinite;*/

const CarouselSlide = styled.div<ICarouselSlide>`
  flex: 0 0 auto;
  width: 100%;
`

interface ICarouselProps {
  currentSlideIndex: number
}

const CarouselSlides = styled.div<ICarouselProps>`
  position: relative;
  display: flex;
  width: 100%;
`
interface Indication {
  active?: boolean
}
const Indicator = styled.span<Indication>`
  margin: 5px;
  height: 5px;
  width: 5px;
  border-radius: 3px;
  background-color: ${({ active, theme }) => {
    return active ? theme.secondaryColor : theme.borderColor
  }};
`

const EmptyIndicator = styled.span<Indication>`
  margin: 5px;
  height: 3px;
  width: 5px;
  border-radius: 3px;
`

const AbsoluteButton = styled(Absolute)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 4px;
  width: 40px;
  opacity: 0.8;
  &:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    &:before {
      opacity: 1;
    }
  }
  &:before {
    margin: 0;
    display: inline-block;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    content: ' ';
    color: white;
  }
`
const Left = styled(AbsoluteButton)`
  &:before {
    margin-right: 16px;
    border-right: 16px solid white;
  }
`
const Right = styled(AbsoluteButton)`
  &:before {
    margin-left: 16px;
    border-left: 16px solid white;
  }
`

interface IProps {
  items: JSX.Element[]
  slideInterval?: number
  onItemClick?: Function
  renderItem: Function
  onActiveSlideChange: Function
  slideShow?: boolean
}

export const Carousel = ({
  items,
  onItemClick,
  onActiveSlideChange,
  slideInterval = 5000,
  renderItem,
  slideShow = true,
}: IProps) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)
  const activeItem = items.find((x, i) => i === currentSlideIndex)
  const handleClick = () => {
    if (onItemClick) {
      onItemClick(items[currentSlideIndex])
    }
  }
  const changeSlide = (offset: number) => {
    let nextSlide = (currentSlideIndex + offset) % items.length
    if (nextSlide === -1) nextSlide = items.length - 1
    setCurrentSlideIndex(nextSlide)
    onActiveSlideChange(items[nextSlide])
  }

  useEffect(() => {
    if (items.length < 2 || !slideShow) return
    const timeout = setTimeout(() => changeSlide(1), slideInterval)
    return () => clearTimeout(timeout)
  }, [slideInterval, currentSlideIndex, items, slideShow])

  return (
    <Container onClick={handleClick}>
      <CarouselWrapper>
        <CarouselSlides currentSlideIndex={currentSlideIndex}>
          <CarouselSlide frozen={!slideShow}>{renderItem(activeItem)}</CarouselSlide>
          {items.length > 1 ? (
            <>
              <Left left="0px" top="0px" bottom="0px" onClick={() => changeSlide(-1)}></Left>
              <Right right="0px" top="0px" bottom="0px" onClick={() => changeSlide(1)}></Right>
            </>
          ) : null}
        </CarouselSlides>
      </CarouselWrapper>
      {items?.length > 1 ? (
        <Indicators>
          {items.map((item: any, index: number) => (
            <Indicator key={`indicator-${index}`} active={index === currentSlideIndex}></Indicator>
          ))}
        </Indicators>
      ) : 
      (
        <EmptyIndicator key={`indicator-${0}`} active={0 === currentSlideIndex}></EmptyIndicator>
      )
      }
      {/* <Indicators>
          {items.map((item: any, index: number) => (
            <Indicator key={`indicator-${index}`} active={index === currentSlideIndex}></Indicator>
          ))}
        </Indicators> */}
    </Container>
  )
}
