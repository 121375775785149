import { IConfig } from '../interfaces'
import { iframeDomains } from './utils'

export const getMediaPlayer = async (
  config: IConfig,
  playerEle: any,
  initializedPlayerIds: string[]
) => {
  if (config.mediaPlayer) {
    switch (config.mediaPlayer) {
      case 'fc_audio': {
        const { HTML5Audio } = await import(/* webpackChunkName: "audio" */ './audio')
        return new HTML5Audio(playerEle, config, initializedPlayerIds)
      }
      case 'fc_video': {
        const { HTML5Video } = await import(/* webpackChunkName: "video" */ './video')
        return new HTML5Video(playerEle, config, initializedPlayerIds)
      }
      case 'fc_dailymotion': {
        const { DMPlayer } = await import(/* webpackChunkName: "dailymotion" */ './dailymotion')
        return new DMPlayer(playerEle, config, initializedPlayerIds)
      }
      case 'fc_vimeo': {
        const { VimeoPlayer } = await import(/* webpackChunkName: "vimeo" */ './vimeo')
        return new VimeoPlayer(playerEle, config, initializedPlayerIds)
      }
      case 'fc_brightcove': {
        const { BrightCovePlayer } = await import(
          /* webpackChunkName: "brightcove" */ './brightcove'
        )
        return new BrightCovePlayer(playerEle, config, initializedPlayerIds)
      }
      case 'fc_jwplayer': {
        const { JWPlayer } = await import(/* webpackChunkName: "jw-player" */ './jw-player')
        return new JWPlayer(playerEle, config, initializedPlayerIds)
      }
    }
  }
}

export const findPlayerElements = async (mediaPlayer: string) => {
  switch (mediaPlayer) {
    case 'fc_audio':
      return findMediaplayers(() => Array.from(document.getElementsByTagName('audio')))
    case 'fc_video':
    case 'fc_jwplayer':
      return findMediaplayers(() => Array.from(document.getElementsByTagName('video')))
    case 'fc_vimeo':
    case 'fc_dailymotion':
      return findMediaplayers(() => {
        const iframes = Array.from(document.getElementsByTagName('iframe'))
        return iframes.filter((iframe) =>
          iframeDomains[mediaPlayer].test(iframe.getAttribute('src') || '')
        )
      })
    case 'fc_brightcove':
      return findMediaplayers(() => Array.from(document.getElementsByTagName('video-js')))
    default:
      return []
  }
}

let interval: any

const findMediaplayers = async (callback: Function) => {
  return new Promise((resolve, reject) => {
    interval = setInterval(() => {
      const players = callback() || []
      if (players && players.length > 0) {
        clearInterval(interval)
        return resolve(players)
      }
    }, 500)
  })
}
