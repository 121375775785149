const configs = {
  dev: {
    ApiBaseUrl: 'https://api.hounds.fewcents.co',
    WalletDomain: 'https://wallet.hounds.fewcents.co',
    SegmentWriteKey: '17dXUASk0RvuJhYy265yE2gByxkFIfV4',
    SegmentAnonymousId: '2673365c-b501-4908-9f96-4d2b33d361e0',
  },
  qa: {
    ApiBaseUrl: 'https://api.demo.fewcents.co',
    WalletDomain: 'https://wallet.demo.fewcents.co',
    SegmentWriteKey: 'iK3e8qAI9npnDkhRQtfOJ6azDPrb4snZ',
    SegmentAnonymousId: 'e0780c84-e0c2-4312-aa18-f5664ded30d8',
  },
  demo: {
    ApiBaseUrl: 'https://api.demo.fewcents.co',
    WalletDomain: 'https://wallet.demo.fewcents.co',
    SegmentWriteKey: 'iK3e8qAI9npnDkhRQtfOJ6azDPrb4snZ',
    SegmentAnonymousId: 'e0780c84-e0c2-4312-aa18-f5664ded30d8',
  },
  prod: {
    ApiBaseUrl: 'https://api.fewcents.co',
    WalletDomain: 'https://wallet.fewcents.co',
    SegmentWriteKey: 'L0sQKVxD76MWlLBvKEyG2K21MFCOjxkK',
    SegmentAnonymousId: '49866f61-5b75-46b3-936b-e554da1cb011',
  },
}

const environment = String(process.env.REACT_APP_STAGE || 'dev').trim()
export const config = (configs as any)[environment]
export const ApiBaseUrl = config.ApiBaseUrl
export const WalletDomain = config.WalletDomain
export const SegmentWriteKey = config.SegmentWriteKey
export const SegmentAnonymousId = config.SegmentAnonymousId
