import { useAppSelector } from '../../store'
import { numberWithCommasAndCurrency } from '../../helpers/display'
import styled from 'styled-components'
import usePaywallPrice from '../../hooks/usePaywallPrice'
import { Heading1 } from '../Styled/Typography'

const PaywallPriceContainer = styled.div`
  &&& {
    font-weight: 600;
    text-align: center;
    justify-content: center;
    color: ${({ theme }) => theme.secondaryColor};
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: 18px;
    padding: 4px 12px 4px 6px;
    margin: 8px 6px 6px;
    display: inline-block;
    cursor: pointer;
    &[hidden] {
      display: none;
      visibility: hidden;
    }
  }
`

const FlagImg = styled.img`
  border-radius: 50%;
  margin-right: 8px;
  height: 23px;
  width: 23px;
  margin-bottom: -4px;
  object-fit: cover;
`

export const ArticlePrice  = ({openFewcentsLogin, isAuthenticatedNormalUser }:any) => {
  const { price, showPrice, countryFlag, currencyDisplayString } = usePaywallPrice()
  const track = useAppSelector((store: any) => store?.paywall?.config?.track)

  const showTrustElements = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.paywallSettings?.showTrustComponent
  )

  const handlePriceIconClick = (evt: any) => {
    track(['login_iniitialized_price_icon'])
    if (!isAuthenticatedNormalUser && openFewcentsLogin) {
      openFewcentsLogin(evt)
    } 
  }

  return showPrice ? (
    <PaywallPriceContainer onClick={(evt) => handlePriceIconClick(evt)} translate="no">
      {showTrustElements && countryFlag != null ? <FlagImg src={countryFlag} alt="Country" /> : null}
      <Heading1 fontWeight="600">
        {numberWithCommasAndCurrency(Number(price), currencyDisplayString)}
      </Heading1>
    </PaywallPriceContainer>
  ) : null
}
