import { useEffect } from 'react'
import { useAppSelector } from '../../store'
import { numberWithCommasAndCurrency } from '../../helpers/display'
import { CardInfo, CardInfoTop } from './styled'
import { launchFCWallet } from '../../helpers'
import { LogoutButton } from '../Paywall/LogoutButton'
import { NormalText, SmallText } from '../Styled/Typography'
import { Anchor } from '../Styled'

export const UserInfoCard = ({ handleLogout }: any) => {
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const lastTransaction = useAppSelector((store: any) => store?.paywall?.fewCentsLastTransaction)
  const track = useAppSelector((store: any) => store?.paywall?.config?.track)
  const customerBalance = lastTransaction?.balance
  const currentBalanceText = fewCentsBidResponse?.paywallSettings?.bubbleBalanceText;

  const formatPrice = (price: any, currency: string) => {
    return price
      ? numberWithCommasAndCurrency(Number(price), currency)
      : numberWithCommasAndCurrency(0, currency)
  }
  const balance = formatPrice(
    customerBalance || fewCentsBidResponse?.customerBalance,
    fewCentsBidResponse?.customerCurrency
  )

  if (lastTransaction) {
    var { customerEmail, customerName } = lastTransaction
  }

  let email = customerEmail && customerName && customerName !== customerEmail ? customerEmail : ''

  let name = fewCentsBidResponse?.customerName
    ? fewCentsBidResponse.customerName
    : customerName
    ? customerName
    : ''

  useEffect(() => {
    track(['bubble_account_details', { balanceAmount: balance, name, email }])
  }, [track])

  const goToButtonClick = () => {
    track(['bubble_fc_account_link_clicked'])
    launchFCWallet()
  }

  return (
    <CardInfo>
      <CardInfoTop margin="0px 0px 12px">
        <SmallText>{currentBalanceText}</SmallText>
        <NormalText fontWeight="600">{balance}</NormalText>
      </CardInfoTop>

      <CardInfoTop>
        <Anchor fontWeight="600" onClick={goToButtonClick}>
          {name}
        </Anchor>
        <SmallText>{email}</SmallText>
        <LogoutButton bottom="-4px" right="0px" onClick={handleLogout}></LogoutButton>
      </CardInfoTop>
    </CardInfo>
  )
}
