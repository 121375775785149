interface MyNamespacedWindow extends Window {
  Paywall: any
  VideoPaywall: any
  AudioPaywall: any
  __q: any
}

declare var window: MyNamespacedWindow
;(function (window) {
  if (!window.__q) {
    window.__q = {
      q: [],
      l: [],
      v: [],
    }
    window.Paywall = function () {
      window.__q.q.push(arguments)
    }

    window.Paywall.on = function () {
      window.__q.l.push(arguments)
    }

    window.AudioPaywall = function () {
      window.__q.v.push(arguments)
    }

    window.VideoPaywall = function () {
      window.__q.v.push(arguments)
    }
  }
})(window)

// @ts-ignore
const url = new URL(document.currentScript.src)
// @ts-ignore
__webpack_public_path__ = url.origin + __webpack_public_path__

import(/* webpackChunkName: "SDK" */ './sdk').then(() => {
  let __q = window.__q

  if (__q?.q?.length > 0) {
    __q.q.forEach((queueItem: any) => {
      window.Paywall(...queueItem)
    })
  }

  if (__q?.l?.length > 0) {
    __q.l.forEach((listener: any) => {
      const [name, callback] = listener
      window.Paywall.on(name, callback)
    })
  }

  if (__q?.v?.length > 0) {
    __q.v.forEach((queueItem: any) => {
      window.VideoPaywall(...queueItem)
    })
  }

  delete window.__q

  const scriptElement = document.getElementById('fewcents_paywall')
  if (scriptElement) {
    const contentSelector = scriptElement.getAttribute('contentSelector')
    const category = scriptElement.getAttribute('category')
    const primaryColor = scriptElement.getAttribute('primaryColor')
    const accessKey = scriptElement.getAttribute('accessKey')
    const functionSelector = scriptElement.getAttribute('functionSelector')

    if (contentSelector) {
      window.Paywall({
        contentSelector,
        functionSelector,
        category,
        primaryColor,
        accessKey,
      })
    }
  }
})

export {}
