import { useEffect, useState } from 'react'

const getValue = (search: string, param: string) => new URLSearchParams(search).get(param)

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>))
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>))
  }
}

export type UseQueryParam = (param: string) => string | null

const useSearchParam: UseQueryParam = (param) => {
  const location = window.location
  const search = location.href.includes('?') ? location.href.split('?')[1] : ''
  const [value, setValue] = useState<string | null>(() => getValue(search, param))

  useEffect(() => {
    const onChange = () => {
      setValue(getValue(location.search, param))
    }

    on(window, 'popstate', onChange)
    on(window, 'pushstate', onChange)
    on(window, 'replacestate', onChange)

    return () => {
      off(window, 'popstate', onChange)
      off(window, 'pushstate', onChange)
      off(window, 'replacestate', onChange)
    }
  }, [])

  return value
}

export default useSearchParam
