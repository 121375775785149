/* eslint-disable react/jsx-no-comment-textnodes */
import { FC, useState, useEffect } from 'react'
import { Expand, Video, Book, Diamond } from '../Icons'
import { numberWithCommas } from '../../helpers/display'
import usePreloginPrice from '../../hooks/usePreloginPrice'
import {
  MenuClosed,
  PaywallBubbleBannerText,
  PaywallBubblePriceText,
  PriceContainer,
  ArticleBubbleLogo,
  IsMenuClosed,
} from './styled'
import usePaywallPrice from '../../hooks/usePaywallPrice'
import { emitter } from '../../services/eventemitter'
import { useAppSelector } from '../../store'
import { Column } from '../Styled/Layouts'
import { Heading3, SmallText } from '../Styled/Typography'
interface Props {
  position: string
}

const PreloginBubble: FC<Props> = ({ position }) => {
  const {
    showPreLoginBubbleContentLogo,
    showPreLoginBubbleContentText,
    showPreLoginBubbleOpenerIcon,
    showPreLoginBubblePrice,
    preLoginBeaconText,
    isMedia,
  } = usePreloginPrice(true)

  const { price, currencyDisplayString } = usePaywallPrice()
  const formattedPrice = numberWithCommas(price)
  const direction = position.indexOf('left') > -1 ? 'left' : 'right'
  const texts = preLoginBeaconText.split(' ')

  const showTrustElements = useAppSelector(
    (store: any) => store?.paywall?.fewCentsBidResponse?.paywallSettings?.showTrustComponent
  )

  return (
    <Column>
      {showPreLoginBubbleContentLogo && showTrustElements ? isMedia ? <Video></Video> : <Book></Book> : null}
      {showPreLoginBubbleContentText
        ? texts.map((text: string, index: number) => (
            <PaywallBubbleBannerText key={`${text}-${index}`} title={text}>
              {text}
            </PaywallBubbleBannerText>
          ))
        : null}
      {showPreLoginBubblePrice ? (
        <PriceContainer>
          <PaywallBubblePriceText title={currencyDisplayString} style={{ marginRight: '2px' }}>
            {currencyDisplayString}{' '}
          </PaywallBubblePriceText>
          <PaywallBubblePriceText title={formattedPrice}>{formattedPrice}</PaywallBubblePriceText>
        </PriceContainer>
      ) : null}

      {showPreLoginBubbleOpenerIcon ? <Expand direction={direction}></Expand> : null}
    </Column>
  )
}

export const PaywallBubble = (props: any) => {
  const isAuthenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const { bubblePosition, isContentUnlocked, toggleMenu } = props
  const isMedia = useAppSelector((store: any) => store?.paywall?.config?.isMedia)
  const [showPaywall, setShowPaywall] = useState(isContentUnlocked)
  const isVisible = useAppSelector((store: any) => store?.paywall?.isVisible)
  const bid = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  useEffect(() => {
    emitter.on('onMediaPaywallShown', function () {
      setShowPaywall(true)
    })
    return () => emitter.off('onMediaPaywallShown')
  }, [])

  return showPaywall || !isMedia ? (
    <>
      {isContentUnlocked ? (
        <IsMenuClosed {...bubblePosition} native={isAuthenticated} onClick={toggleMenu}>
          <ArticleBubbleLogo>
            <Column style={{ marginRight: '12px', width: 'auto' }}>
              <Diamond height="30px" width="30px"></Diamond>
            </Column>

            <Column alignItems="start">
              <SmallText fontWeight="600">{bid?.paywallSettings?.onsiteRecoClosedText1}</SmallText>
              <Heading3 style={{ whiteSpace: 'nowrap', fontSize: '14px' }} fontWeight="600">
                {bid?.paywallSettings?.onsiteRecoClosedText2}
              </Heading3>
            </Column>
          </ArticleBubbleLogo>
        </IsMenuClosed>
      ) : !isVisible ? (
        <MenuClosed {...bubblePosition} native={isAuthenticated} onClick={toggleMenu}>
          <PreloginBubble position={props.bubblePosition.position}></PreloginBubble>
        </MenuClosed>
      ) : null}
    </>
  ) : null
}
