import styled from 'styled-components'
import { fontSizes } from '../Styled/FontSizes'

export const SvgText = styled.span`
  color: ${({ theme }) => theme.secondaryColor};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily};
  position: absolute;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  top: 0px;
  left: 16px;
  right: 16px;
  bottom: 0px;
  margin-top: 8px;
  font-size: ${fontSizes.desktop.heading3};
  @media (max-width: 540px) {
    font-size: ${fontSizes.mobile.heading3};
  }
`

export const SvgContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  position: relative;
  border-radius: 8px;
`

const loc = `https://images.fewcents.co/paywall`
export const Icon = (props: any) => {
  const isDarkTheme = window?.paywallInstance?.store?.getState()?.paywall?.config?.isDarkTheme
  const src = isDarkTheme ? `${loc}/${props.name}-Dark.svg` : `${loc}/${props.name}.svg`
  let altString = 'fewcents_' + props.name
  return <img alt={altString} src={src} {...props} />
}
