import usePreloginPrice from '../../hooks/usePreloginPrice'
import { UnlockButton } from '../Button'
import { LoginMethods } from './LoginMethods'
import { Checkbox, CheckboxLabel } from '../Styled'
import { useAppDispatch, useAppSelector } from '../../store'
import Action from '../../constants/actionTypes'
import { Column, Row } from '../Styled/Layouts'
import { Heading3 } from '../Styled/Typography'

type PrimaryCardProps = {
  bubble?: Boolean
}
const linkStyle = {
  cursor: 'pointer',
}

// const termsUrl = 'https://www.fewcents.co/terms'
// const policyUrl = 'https://www.fewcents.co/privacy'

export const PreLogin = ({ bubble = false }: PrimaryCardProps) => {
  const {
    disabled,
    openFewcentsLogin,
    buttonText,
    isTipjar,
    privacyPolicyCheck,
    togglePrivacyPolicy,
    handlePolicyClick,
    handleSimCheckbox,
    simUserEmail,
    enableRedirect,
    config,
  } = usePreloginPrice(!!bubble)

  const dispatch = useAppDispatch()
  const partnerTermsCheck = useAppSelector((store: any) => store?.auth?.partnerTermsCheck)
  const { hasPartnerTerms, partnerTermsText } = config
  const togglePartnerTerms = () => {
    dispatch({ type: Action.TOGGLE_PARTNER_TERMS_CHECK })
  }
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const termsPolicyStartText = fewCentsBidResponse?.paywallSettings?.termsPolicyStartText
  const termsText = fewCentsBidResponse?.paywallSettings?.termsText
  const policyText = fewCentsBidResponse?.paywallSettings?.policyText
  const termsLink = fewCentsBidResponse?.paywallSettings?.termsLink
  const policyLink = fewCentsBidResponse?.paywallSettings?.policyLink
  const gdprText = fewCentsBidResponse?.paywallSettings?.gdprText
  let ssoPromptText = fewCentsBidResponse?.paywallSettings?.ssoPromptText
  let ssoPromptTextFinal = ssoPromptText.replace('{customer_email}', simUserEmail)
  const andSign = fewCentsBidResponse?.languageCode == 'ja' ? 'と' : '&'
  const dotSign = fewCentsBidResponse?.languageCode == 'ja' ? '' : '.'

  return simUserEmail ? (
    <>
      <Row>
        <UnlockButton onClick={(evt) => openFewcentsLogin(evt)} disabled={disabled}>
          <Heading3>{buttonText}</Heading3>
        </UnlockButton>
      </Row>
      <Row margin="6px 0px 6px">
        <Checkbox type="checkbox" checked={enableRedirect} onChange={handleSimCheckbox} />
        <CheckboxLabel>{ssoPromptTextFinal}</CheckboxLabel>
      </Row>
    </>
  ) : (
    <>
      <LoginMethods
        onClick={openFewcentsLogin}
        disabled={disabled}
        buttonText={buttonText}
        isTipjar={isTipjar}
        bubble={bubble}
      ></LoginMethods>
      <Column>
        <Row justifyContent="left" margin="8px 0px 0px 8px">
          <Checkbox
            type="checkbox"
            id="privacy-policy"
            name="privacyPolicyCheck"
            checked={privacyPolicyCheck}
            onInput={togglePrivacyPolicy}
          />
          <CheckboxLabel>
            {termsPolicyStartText}{' '}
            <a
              title={termsLink}
              onClick={() => handlePolicyClick('terms', termsLink)}
              style={linkStyle}
            >
              {termsText}{' '}
            </a>
            {andSign}{' '}
            <a
              title={policyLink}
              style={linkStyle}
              onClick={() => handlePolicyClick('policy', policyLink)}
            >
              {policyText}
            </a>
            {dotSign} {gdprText}
          </CheckboxLabel>
        </Row>
        {hasPartnerTerms && partnerTermsText ? (
          <Row justifyContent="left" margin="8px 0px 0px 8px">
            <Checkbox
              type="checkbox"
              id="partner-terms"
              name="partnerTermsCheck"
              checked={partnerTermsCheck}
              onInput={togglePartnerTerms}
            />
            <CheckboxLabel>{partnerTermsText}</CheckboxLabel>
          </Row>
        ) : null}
      </Column>
    </>
  )
}
