import styled, { keyframes } from 'styled-components'

interface IStyledButton {
  leftOffset?: Boolean
}
const StyledButton = styled.button<IStyledButton>`
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily};
  height: 40px;
  border: none;
  width: 100%;
  outline: none;
  line-height: 40px;
  cursor: pointer;
`
interface ISocialButton {
  isTipjar: boolean
}

export const SocialButton = styled.button<ISocialButton>`
  overflow: hidden;
  margin-top: 15px;
  color: #111111;
  background-color: #ffffff;
  margin: 4px;
  border-radius: 4px;
  border-width: 0px;
  border-top: 1px solid #dfdfdf;
  height: 36px;
  display: flex;
  font-weight: 500;
  width: calc(100% - 8px);
  min-width: 40px;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  cursor: pointer;
  padding: 0 4px;
  box-shadow: 2px 2px 2px -2px rgb(0 0 0 / 20%), -2px 2px 3px 0px rgb(0 0 0 / 14%),
    3px 1px 5px 0px rgb(0 0 0 / 12%);

  svg {
    fill: currentColor;
  }
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  &:active {
    transform: scale(0.99);
  }
`

export const showIcon = keyframes`
    0% {
    opacity: 0;
    }
    50%{
      opacity: 0.4;
    transform: scale(0.7)
    }

    100% {
    opacity: 1;
    transform:scale(1.1)
    }
`

export const RewardsButton = styled(StyledButton)`
  color: ${({ theme }) => theme.primaryColor};
  background: ${({ theme }) => theme.backgroundColor};
  display: block;
  margin: ${({ theme }) => (theme.isHorizontal ? 'auto 0 auto 0px' : '0px auto 10px')};
  max-width: 200px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  height: 40px;
  line-height: 40px;
  padding: 0 4px;
`

export const UnlockButton = styled(StyledButton)`
  font-weight: 600;
  width: calc(100% - 8px);
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 4px;
  display: inline-block;
  justify-content: center;
  color: ${({ theme }) => theme.backgroundColor};
  cursor: pointer;
  border: 0;
  background: ${({ theme }) => theme.primaryColor};
  margin: 4px auto;
  margin-left: ${({ leftOffset }) => (leftOffset ? '12px' : '')};
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily};
  outline: none;
  padding: 0 4px;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  span {
    padding: 8px;
    line-height: 18px !important;
  }
`
