import { FC } from 'react'
import styled from 'styled-components'
import useTipjarPrices from '../../hooks/useTipjarPrices'
import { hexToRgba } from '../../helpers'
import { Row } from '../Styled/Layouts'
import { numberWithCommas } from '../../helpers/display'
import { Heading2 } from '../Styled/Typography'

interface ITipjarOption {
  selected: boolean
}

const TipjarOption = styled.button<ITipjarOption>`
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? hexToRgba(theme.primaryColor, 0.05) : theme.backgroundColor};
  border-radius: 5px;
  border: ${({ theme }) => `1px solid ${theme.primaryColor}`};
  cursor: pointer;
  display: flex;
  height: 40px;
  margin: 8px 4px;
  justify-content: center;
  line-height: 40px;
  outline: none;
  min-width: 90px;
  width: calc(100% - 8px);
  @media (max-width: 288px) {
    min-width: 63px;
  }
  &:hover {
    color: ${({ theme }) => theme.primaryColor} !important;
    background-color: ${({ theme }) => hexToRgba(theme.primaryColor, 0.05)} !important;
  }
`

const TipjarOptionsContainer = styled.div`
  &&& {
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.secondaryColor};
    display: flex;
    justify-content: center;
    &[hidden] {
      display: none;
      visibility: hidden;
    }
    width: 100%;
  }
`

const PriceNumber = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  &&& {
    pointer-events: ${({ theme }) => (theme.allowPointerEvents ? 'auto' : 'none')};
  }
`

const PriceCurrency = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  &&& {
    margin-right: 6px;
    pointer-events: ${({ theme }) => (theme.allowPointerEvents ? 'auto' : 'none')};
  }
`

type PriceProps = {
  bubble?: Boolean | undefined
}

export const TipjarPrices: FC<PriceProps> = ({ bubble }) => {
  const { priceOptions, currency, selectedIndex, handleTipjarOptionChange, currencyDisplayString } = useTipjarPrices(
    bubble
  )

  return (
    <TipjarOptionsContainer>
      {priceOptions.prices.map((price: any, index: number) => (
        <TipjarOption
          translate="no"
          selected={selectedIndex === index}
          onClick={(e) => handleTipjarOptionChange(e, price, index)}
          value={price}
          key={index}
        >
          <Heading2 fontWeight={selectedIndex === index ? '600' : '500'}>
            <PriceCurrency>{currencyDisplayString}</PriceCurrency>
            <PriceNumber>{numberWithCommas(Number(price))}</PriceNumber>
          </Heading2>
        </TipjarOption>
      ))}
    </TipjarOptionsContainer>
  )
}
