import Action from '../constants/actionTypes'

interface IAction {
  type: string
  payload: boolean
}

const defaultState = {
  open: false,
}

const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case Action.SET_BUBBLE_OPEN:
      return {
        ...state,
        open: action.payload,
      }

    default:
      return state
  }
}

export default reducer
