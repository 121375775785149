import { AUDIO_NOTIFICATION_URL, VIDEO_NOTIFICATION_URL } from '../constants/common'
import { useEffect, useRef, useState, lazy } from 'react'
import { Paywall } from '../components/Paywall'
import { UnlockButton } from '../components/Button'
import { Modal } from '../components/Modal'
import { useAppDispatch, useAppSelector } from '../store'
import { canShowOverlay } from '../actions/paywall'
import { emitter } from '../services/eventemitter'
import { PlayerEvents } from '../video-players/IPlayer'
import { AbsoluteCenteredWrapper, mediaBaseTransitionStyle } from '../components/Styled'

const SplashScreen = lazy(
  () => import(/* webpackChunkName: "SplashScreen" */ '../components/Paywall/SplashScreen')
)

const MediaPaywall: React.FC<{
  articleMadeVisible: Boolean
  setArticleMadeVisible: Function
}> = ({ articleMadeVisible, setArticleMadeVisible }) => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const useOverlay = Boolean(config?.showOverlay)
  const [isUnlockedBeforePlay, setIsUnlockedBeforePlay] = useState<boolean>(true)
  const [showOverlay, setShowOverlay] = useState(useOverlay)
  const [intervalOver, setIntervalOver] = useState(false)
  const timeRef = useRef<null | number>(null)
  const isContentUnlocked = fewCentsBidResponse?.unlocked === true
  const showPaywall = isContentUnlocked ? !articleMadeVisible : intervalOver
  const showSplash = isContentUnlocked && !articleMadeVisible
  const track = config.track

  const togglePaywallOverlay = () => {
    const show = !showOverlay
    setShowOverlay(show)
    track(['toggling_paywall_overlay', { show }])
  }

  async function makeArticleVisible() {
    config.player?.hidePaywall()
    if (
      config?.contentSelector === sessionStorage.getItem('unlockedContentSelector') &&
      !isUnlockedBeforePlay
    ) {
      await config?.player.pipResume()
      await config?.player.play()

      sessionStorage.removeItem('unlockedContentSelector')
    }

    setArticleMadeVisible(true)
  }

  useEffect(() => {
    setShowOverlay(useOverlay)
  }, [useOverlay, setShowOverlay])

  //Sets up the timeUpdate listener for MediaPaywall after the bid has been fetched
  useEffect(() => {
    const player = config?.player
    const timeInSeconds = config?.paywallIntervalInSeconds || 0
    if (!player) return

    if (config.paywallTargetElement) {
      dispatch(canShowOverlay(config.paywallTargetElement, false))
    }

    player.on(PlayerEvents.timeUpdate, onTimeUpdate)
    player.on(PlayerEvents.mediaChange, onMediaChange)

    async function onTimeUpdate() {
      if (!isContentUnlocked) {
        let playTime = await player.getCurrentTime()
        setIsUnlockedBeforePlay(false)
        if (playTime > timeInSeconds) {
          await player.exitPiPAndFullscreen()
          await player.pause()
          emitter.fire('onMediaPaywallShown', {})
          setIntervalOver(true)
          if (player.isIframe) {
            timeRef.current = playTime
            player.setSeekOnPlay(timeRef)
          }
        }
      }
    }

    async function onMediaChange() {
      if (!intervalOver) {
        track(['paywall_render_skipped'])
      } else {
        setIntervalOver(false)
      }
    }
    return () => {
      player.off(PlayerEvents.timeUpdate, onTimeUpdate)
      player.off(PlayerEvents.mediaChange, onMediaChange)
    }
  }, [
    track,
    dispatch,
    isContentUnlocked,
    intervalOver,
    config?.player,
    config?.paywallIntervalInSeconds,
    config.paywallTargetElement,
  ])

  //Updates the style of the paywall and the show state based on the value of showPaywall.
  //Also plays the notification sound when the paywall shows up

  useEffect(() => {
    if (showPaywall) {
      config.player?.showPaywall()
      if (!isContentUnlocked) {
        const audio = document.createElement('audio')
        const src =
          config?.mediaPlayer === 'fc_audio' ? AUDIO_NOTIFICATION_URL : VIDEO_NOTIFICATION_URL

        audio.setAttribute('autoplay', 'true')
        audio.style.display = 'none'
        audio.autoplay = true
        audio.setAttribute('src', src)
        document.body.appendChild(audio)
      }
    } else {
      config.player?.hidePaywall()
    }
  }, [
    config.player,
    showPaywall,
    isContentUnlocked,
    fewCentsBidResponse,
    config?.mediaPlayer,
    config?.paywallIntervalInSeconds,
  ])

  //Manages the state of the paywall based on the bidResponse
  useEffect(() => {
    if (!fewCentsBidResponse.unlocked) return

    const timer = setTimeout(() => {
      config.player?.hidePaywall()
      makeArticleVisible()
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [config.player, fewCentsBidResponse])

  useEffect(() => {
    if (showPaywall) {
      config?.player.hideFSButton()
    }
  }, [config?.player, showPaywall])

  return isContentUnlocked ? (
    <>
      {showSplash ? (
        <SplashScreen
          useOverlay={useOverlay}
          fewCentsBidResponse={fewCentsBidResponse}
          position="absolute"
        />
      ) : null}
    </>
  ) : (
    <>
      {showPaywall && (
        <>
          {useOverlay && !showOverlay ? (
            <AbsoluteCenteredWrapper>
              <UnlockButton style={{ maxWidth: '150px' }} onClick={togglePaywallOverlay}>
                Unlock
              </UnlockButton>
            </AbsoluteCenteredWrapper>
          ) : (
            <Modal useOverlay={useOverlay} show={showOverlay} toggle={togglePaywallOverlay}>
              <Paywall
                config={config}
                baseTransitionStyles={mediaBaseTransitionStyle}
                onClose={useOverlay ? togglePaywallOverlay : null}
                borderless={config?.borderless}
              />
            </Modal>
          )}
        </>
      )}
    </>
  )
}

export default MediaPaywall
