import { colors, boxShadow, menuBoxShadow, transition, borderRadius } from './constants'

const theme = {
  primaryColor: colors.primary,
  error: colors.error,
  gray: colors.gray,
  boxShadow,
  menuBoxShadow,
  transition,
  borderRadius,
}

export default theme
