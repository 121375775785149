import {
  ArticleBubbleLogo,
  ArticleMenuClosed,
  BubbleContainer,
  CardInfo,
  MenuOpened,
} from './styled'
import { useEffect, useRef, useState } from 'react'
import { Articles } from './Articles'
import { Close, Diamond } from '../Icons'
import { useAppDispatch, useAppSelector } from '../../store'
import { CloseButton } from '../Modal/styled'
import * as CommonMessages from '../../constants/common'
import { useToggleMenu } from '../../hooks/useToggleMenu'
import { handleApiErrors } from '../../helpers/handleApiErrors'
import { updateRecommendedArticles } from '../../actions/paywall'
import { showFailureMessage } from '../../actions/toast'
import { config } from '../../config'
import { Column, Row } from '../Styled/Layouts'
import { Heading1, Heading3, NormalText, SmallText } from '../Styled/Typography'

const closeButtonStyle = { top: '-2px', right: '-4px' }
const menuStyle = { maxHeight: '90vh' }
const articlesStyle = { maxHeight: '400px', overflow: 'hidden auto' }

export const ArticleBubble = () => {
  const [closeMenuStyle, setCloseMenuStyle] = useState({
    marginRight: '',
  })
  const dispatch = useAppDispatch()
  const bubbleRef = useRef<any>()
  const bid = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const {
    openMenu,
    verticalTheme,
    toggleMenu,
    handleContainerClick,
    floatingButtonRef,
    bubblePosition,
  } = useToggleMenu()

  useEffect(() => {
    if (!bid) return
    ;(async function () {
      try {
        const { customerId, fewCentsBidId, publisherId } = bid
        const response = await fetch(`${config.ApiBaseUrl}/v2/fbid/onsiteRecommendations`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ customerId, fewCentsBidId, publisherId }),
        })
        const verifiedResponse = handleApiErrors(response)
        const json = await verifiedResponse.json()
        const { data, message, success } = json

        if (!success) {
          throw new Error(message ?? CommonMessages.COMMON_FAILURE)
        }
        const { recommendations } = data
        dispatch(updateRecommendedArticles(recommendations))
      } catch (error: any) {
        showFailureMessage(error.message)
      }
    })()
  }, [bid])

  useEffect(() => {
    if (
      !closeMenuStyle.marginRight &&
      bubbleRef.current &&
      bid?.paywallSettings?.onsiteRecoClosedText2
    ) {
      const elm = bubbleRef.current

      setCloseMenuStyle({
        marginRight: `-${Math.floor((elm.clientWidth - elm.clientHeight) / 2)}px`,
      })
    }
  }, [
    closeMenuStyle.marginRight,
    bubbleRef.current?.clientHeight,
    bid?.paywallSettings?.onsiteRecoClosedText2,
  ])

  return (
    <BubbleContainer
      theme={verticalTheme}
      bubblePosition={bubblePosition}
      onClick={handleContainerClick}
    >
      {openMenu ? (
        <MenuOpened style={menuStyle} {...bubblePosition} ref={floatingButtonRef}>
          <CardInfo style={{ padding: '8px 16px', marginBottom: '8px' }}>
            <Row>
              <Column width="50px" margin="0px 16px 0px 0px">
                <Diamond width="100%"></Diamond>
              </Column>
              <Column width="calc(100% - 50px)" alignItems="left">
                <Heading1>{bid.publisherName}</Heading1>
                <NormalText style={{ marginTop: '4px' }}>
                  {bid?.paywallSettings?.onsiteRecoOpenText}
                </NormalText>
              </Column>
            </Row>
          </CardInfo>
          <Articles style={articlesStyle} openMenu={openMenu}></Articles>
          <CloseButton onClick={toggleMenu} style={closeButtonStyle}>
            <Close />
          </CloseButton>
        </MenuOpened>
      ) : (
        <ArticleMenuClosed
          ref={bubbleRef}
          style={closeMenuStyle}
          {...bubblePosition}
          onClick={toggleMenu}
          native={true}
        >
          <ArticleBubbleLogo>
            <Column style={{ marginRight: '12px', width: 'auto' }}>
              <Diamond height="30px" width="30px"></Diamond>
            </Column>

            <Column alignItems="start">
              <SmallText fontWeight="600">{bid?.paywallSettings?.onsiteRecoClosedText1}</SmallText>
              <Heading3 style={{ whiteSpace: 'nowrap' }} fontWeight="600">
                {bid?.paywallSettings?.onsiteRecoClosedText2}
              </Heading3>
            </Column>
          </ArticleBubbleLogo>
        </ArticleMenuClosed>
      )}
    </BubbleContainer>
  )
}
