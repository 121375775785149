import { useCallback, lazy, useEffect, useState } from 'react'
import { Paywall } from '../components/Paywall'
import { TextPaywallRoot } from '../components/Styled'
import { addContentBlock, removeContentRestriction } from '../actions/paywall'
import { useAppSelector } from '../store'

const SplashScreen = lazy(
  () => import(/* webpackChunkName: "TextSplashScreen" */ '../components/Paywall/SplashScreen')
)

const TextPaywall: React.FC<{
  articleMadeVisible: Boolean
  setArticleMadeVisible: Function
}> = ({ articleMadeVisible, setArticleMadeVisible }) => {
  const [showPaywall, setShowPaywall] = useState(true)
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const isLinkout = config?.isLinkout
  const borderless = config?.borderless
  const bidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const transactionMode = bidResponse?.fewCentsTransactionMode
  
  const needSplash = !config?.hideSplash &&
    ((transactionMode !== 'normal' && transactionMode !== 'tipjar') ||
    (transactionMode === 'normal' && (bidResponse?.unlocked || isLinkout === true)) ||
    (transactionMode === 'tipjar' && bidResponse?.unlocked === true))

  const isTipjar = transactionMode === 'tipjar'
  const isContentUnlocked = bidResponse?.unlocked === true
  const showSplash = needSplash && isContentUnlocked && (!articleMadeVisible || isLinkout)

  const makeArticleVisible = useCallback(async () => {
    const unlockDivElement = config?.paywallTargetElement || (await config.functionSelector())
    removeContentRestriction(config, unlockDivElement)
    unlockDivElement &&
      !unlockDivElement.classList.contains('fc_content-full-height') &&
      unlockDivElement.classList.add('fc_content-full-height')

    setArticleMadeVisible(true)
  }, [config])

  const blockContent = useCallback(() => {
    addContentBlock(!isTipjar, config)
  }, [isTipjar, config])

  //Manages the state of the paywall based on the bidResponse
  useEffect(() => {
    if (bidResponse.unlocked) {
      if (needSplash === false) {
        /**
         * When paywall is disabled we show the content right away.
         * We also show the content right away when transaction mode is normal.
         */
        makeArticleVisible()
      } else {
        /**
         * In other all cases when content is unlocked,
         * we show the splash first.
         */
        blockContent()
        setShowPaywall(true)
        if (!isTipjar) {
          setTimeout(() => {
            makeArticleVisible()
          }, 3000)
          setTimeout(() => {
            setShowPaywall(false)
          }, 2700)
        }
      }
    } else {
      blockContent()
      setShowPaywall(true)
    }
  }, [bidResponse, needSplash, isTipjar])

  return (
    <TextPaywallRoot>
      {isContentUnlocked ? (
        showSplash ? (
          <SplashScreen
            useOverlay={false}
            fewCentsBidResponse={bidResponse}
            borderless={borderless}
            persist={isTipjar || isLinkout}
            position={isLinkout ? 'absolute' : 'fixed'}
          />
        ) : null
      ) : (
        showPaywall && <Paywall config={config} borderless={borderless}></Paywall>
      )}
    </TextPaywallRoot>
  )
}

export default TextPaywall
