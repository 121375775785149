import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import { fc_logger } from '../helpers/fc_logger'
import { updateAskPrice } from '../actions/paywall'
import { findFlagUrlByIso2Code } from 'country-flags-svg'

const usePaywallPrice = () => {
  const dispatch: any = useAppDispatch()
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall.fewCentsBidResponse)
  const askPrice = useAppSelector((store: any) => store?.paywall.askPrice)
  const isAuthenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const showPrice = isAuthenticated || fewCentsBidResponse?.paywallSettings?.showPreLoginPrice
  const customerCountry = fewCentsBidResponse?.customerCountry
  const countryFlag = findFlagUrlByIso2Code(customerCountry) //fewCentsBidResponse?.countryFlag
  let price = fewCentsBidResponse?.articlePrice
  let currency = fewCentsBidResponse?.publisherCurrency

  if (isAuthenticated || fewCentsBidResponse?.customerCurrency) {
    price = fewCentsBidResponse.articlePriceInLocalCurrency
    currency = fewCentsBidResponse?.customerCurrency
  }
  const currencyDisplayString = fewCentsBidResponse?.currencyDisplayString


  useEffect(() => {
    if (askPrice) return

    if (fewCentsBidResponse?.customerCurrency || isAuthenticated) {
      fc_logger.info('setting askPrice by customer')
      dispatch(updateAskPrice(fewCentsBidResponse?.articlePriceInLocalCurrency))
    } else {
      fc_logger.info('setting askPrice by publisher')
      dispatch(updateAskPrice(fewCentsBidResponse?.articlePrice))
    }
  }, [
    dispatch,
    askPrice,
    isAuthenticated,
    fewCentsBidResponse?.customerCurrency,
    fewCentsBidResponse?.articlePriceInLocalCurrency,
    fewCentsBidResponse?.articlePrice,
  ])

  return { price, currency, showPrice, countryFlag, currencyDisplayString }
}

export default usePaywallPrice
