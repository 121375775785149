import { IPlayer, PlayerEvents } from './IPlayer'
import { emitter, EventHandler } from '../services/eventemitter'
import { getContainer, wrapElement } from '../helpers/dom'

export class PlayerBase implements IPlayer {
  public readonly isIframe: boolean = false
  public sourceId: string = ''
  public targetElement?: HTMLElement
  public ContainerElement?: HTMLElement
  public wrapper?: HTMLElement
  public exitFullscreen: Boolean = false
  public duplicate: Boolean = false
  public analyticsData: any = { player: '', articleIdentifier: '' }
  public containerId: string = ''
  public track: Function = () => {}
  public fsButton?: HTMLElement

  setWrapper(wrapper: HTMLElement) {
    this.wrapper = wrapper
    this.addFullscreenButton()
  }
  setFullscreenButtonPosition(fsButton: HTMLElement) {}
  play() {}
  pause() {}
  getCurrentTime() {}
  setTipjarButton(tipjarButton: any, closeButton: HTMLElement) {}

  on(eventType: PlayerEvents, callback: EventHandler) {
    emitter.on(eventType, callback)
  }

  off(eventType: PlayerEvents, handler?: EventHandler) {
    emitter.off(eventType, handler)
  }

  async getSourceId() {
    return this.sourceId
  }

  hideFSButton() {
    if (this.fsButton) {
      this.fsButton.style.display = 'none'
    }
  }
  hidePaywall() {
    this.ContainerElement?.style.removeProperty('height')
    this.ContainerElement?.style.removeProperty('width')
  }

  showPaywall() {
    if (this.ContainerElement) {
      this.ContainerElement.style.height = '100%'
      this.ContainerElement.style.width = '100%'
    }
  }

  listenResizeEvent() {
    const that = this
    window.addEventListener('resize', () => {
      that.track(['screen_resize'])
      emitter.fire(PlayerEvents.screenResize, {})
    })
  }

  listenFullScreenChangeEvent() {
    const that = this
    window.addEventListener('fullscreenchange', () => {
      const fullscreenElement = document.fullscreenElement
      const fullscreen = Boolean(fullscreenElement)
      const isFCWrapper = fullscreenElement?.getAttribute('name') === 'fc_wrapper'
      that.track(['fullscreen_change', { fullscreen, isFCWrapper }])
      emitter.fire(PlayerEvents.fullscreenChange, {
        fullscreen,
      })
    })
  }

  init(containerId: string, track: Function) {
    const that = this
    that.track = track
    that.containerId = containerId
    const wrapper = document.createElement('div')
    if (that.targetElement) {
      const { height, width } = that.targetElement.getBoundingClientRect()
      wrapper.style.height = `${height}px`
      wrapper.style.width = `${width}px`
      wrapper.setAttribute('name', 'fc_wrapper')
      wrapper.style.position = 'relative'
      this.listenResizeEvent()
      this.listenFullScreenChangeEvent()
      that.targetElement.style.width = '100%'
      that.targetElement.style.height = '100%'
      that.targetElement.style.position = 'relative'
      this.track(['media_wrapped_in_fc_wrapper'])
      wrapElement(that.targetElement, wrapper)
    }
    this.ContainerElement = getContainer(containerId, true)
    wrapper.appendChild(this.ContainerElement)
    this.setWrapper(wrapper)
  }

  public addFullscreenButton() {
    const that = this
    const fsButton = document.createElement('button')
    fsButton.style.position = 'absolute'
    fsButton.style.border = 'none'
    fsButton.style.background = 'none'
    fsButton.style.cursor = 'pointer'

    fsButton.addEventListener('click', () => {
      that.track(['fewcents_fullscreen_button_clicked'])
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        that.wrapper?.requestFullscreen()
      }
    })
    this.fsButton = fsButton
    this.wrapper?.appendChild(fsButton)
    this.setFullscreenButtonPosition(fsButton)
  }

  public async exitPiPAndFullscreen() {
    if (document.fullscreenElement) {
      await document.exitFullscreen()
    }
    if (document.pictureInPictureElement) {
      await document.exitPictureInPicture()
    }
  }

  public async pipResume() {}
}
