import DeviceDetector from 'device-detector-js'
import { WalletDomain } from '../config'
import { FC_PREBID_PAYLOAD } from '../constants/prebid';

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(window.navigator.userAgent);
let preBidApiPayload: any;
/**
 * To prevent the loop in data layer object,
 * We get the prebid payload from the data layer and keep in in a variable.
 */
const getPreBidApiPayload = () => {
  if(!preBidApiPayload) {
    const { onboard_api_payload } = (window as any).dataLayer?.find((d: any) => d[FC_PREBID_PAYLOAD]) || { onboard_api_payload: undefined };
    if (onboard_api_payload) {
      preBidApiPayload = onboard_api_payload;
    }
  }
  return preBidApiPayload;
};

export function isNullOrUndefined(value: any) {
  return value === undefined || value === null || value === 'null' || value === 'undefined'
    || value === ''
}

export function isFalsy(value: any) {
  return !value || value === 'null' || value === 'undefined'
}

export function setLocalStorageItem(key: any, value: any) {
  window.localStorage.setItem(key, value)
}

export function getLocalStorageItem(key: any) {
  return window.localStorage.getItem(key)
}

export function removeLocalStorageItem(key: any) {
  window.localStorage.removeItem(key)
}

export const getDeviceType = () => {
  /**
   * Check the device type is already exist in data layer from pre-bidjs
   */
  const preBidData = getPreBidApiPayload();
  if (preBidData) {
    return device.bot ? true : preBidData.deviceType;
  }
  return device.bot ? true : device.device?.type
}

export function getBrowserName() {
  /**
   * Check the device type is already exist in data layer from pre-bidjs
   */
  const preBidData = getPreBidApiPayload();
  if (preBidData) {
    return preBidData.deviceBrowser;
  }
  return device.client?.name || ''
}
export const getOsName = () => {
  /**
   * Check the device type is already exist in data layer from pre-bidjs
   */
  const preBidData = getPreBidApiPayload();
  if (preBidData) {
    return preBidData.deviceOS;
  }
  return device.os?.name || ''
}

export const launchFCWallet = () => {
  window.open(`${WalletDomain}/dashboard`, '_blank')
  return false
}

export const parseQueryString = (value: string) => {
  if (typeof value === 'string') {
    const array = value.split('&')
    return array.reduce((accum: any, entry) => {
      const [key, value] = entry.split('=')
      accum[key] = value
      return accum
    }, {})
  }
  return {}
}

export const hexToRgba = (hex: string, opacity = 1) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c: any = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${opacity})`
  }
  return hex //conversion failed so return original color
}
