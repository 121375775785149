import { useCallback, useEffect, useRef } from 'react'
import { IPosition } from '../components/Bubble/styled'
import Action from '../constants/actionTypes'
import { useAppDispatch, useAppSelector } from '../store'

export const useToggleMenu = () => {
  const dispatch = useAppDispatch()
  const openMenu = useAppSelector((store: any) => store.bubble.open)
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const isHorizontal = false
  const floatingButtonRef = useRef<HTMLDivElement | null>(null)

  const setOpenMenuInt = (value: boolean) => {
    dispatch({ type: Action.SET_BUBBLE_OPEN, payload: value })
    if (value === true) {
      config.track(['bubble_opened'])
    } else {
      config.track(['bubble_closed'])
    }
  }

  const closeMenuHandler = (event: any) => {
    if (floatingButtonRef.current && !floatingButtonRef.current.contains(event.target)) {
      setOpenMenuInt(false)
    }
  }

  useEffect(() => {
    window.focusOnWall = () => {
      !openMenu && setOpenMenuInt(true)
    }
    document.body.addEventListener('click', closeMenuHandler, false)
    return () => {
      document.body.removeEventListener('click', closeMenuHandler, false)
    }
  }, [])

  const toggleMenu = (evt: any) => {
    if (openMenu === false) {
      config.track(['bubble_clicked'])
    }
    evt.stopPropagation()
    setOpenMenuInt(!openMenu)
  }

  const verticalTheme = useCallback(
    (theme: any) => {
      return {
        ...theme,
        isHorizontal,
        insideBubble: true,
        mobileOffset: config.mobileOffset,
        desktopOffset: config.desktopOffset,
      }
    },
    [isHorizontal]
  )

  const handleContainerClick = (e: any) => {
    e.stopPropagation()
  }
  const bubblePosition: IPosition = {
    position: config?.bubblePosition,
    isTipjar: false,
    bubbleOpenWidgetPosition: config?.bubbleOpenWidgetPosition
  }

  return {
    setOpenMenuInt,
    openMenu,
    verticalTheme,
    toggleMenu,
    handleContainerClick,
    isHorizontal,
    closeMenuHandler,
    floatingButtonRef,
    bubblePosition,
  }
}
