import { createMouseMoveListener } from './utils'
import { PlayerEvents } from './IPlayer'
import { emitter } from '../services/eventemitter'
import { PlayerBase } from './PlayerBase'
import { IConfig } from '../interfaces'

const winRef = window as any

export class BrightCovePlayer extends PlayerBase {
  private instance: any

  constructor(videoJSElement: HTMLElement, config: IConfig, initializedPlayerIds: any[]) {
    super()
    let id = videoJSElement.getAttribute('id')
    this.sourceId = (videoJSElement as any).getAttribute('data-video-id')
    if (initializedPlayerIds?.includes(this.sourceId)) {
      this.duplicate = true
      return
    }

    this.analyticsData.articleIdentifier = this.sourceId
    this.analyticsData.player = 'Brightcove'

    if (!id) {
      videoJSElement.setAttribute('id', this.sourceId || '')
      id = this.sourceId || null
    }

    //@ts-ignore videojs is loaded by the brightcove script
    const that = this
    winRef.videojs.getPlayer(id).ready(function () {
      //@ts-ignore
      that.setInstance(this)
    })
    this.targetElement = videoJSElement
  }

  private setInstance(instance: any) {
    if (!this.instance) {
      this.instance = instance
      this.instance.on('timeupdate', (evt: any) => {
        emitter.fire(PlayerEvents.timeUpdate, evt)
      })
    }
  }

  async play() {
    this.track(['video_played_programmatically'])
    await this.instance?.play()
  }

  async pause() {
    this.track(['video_paused_programmatically'])
    await this.instance?.pause()
  }

  getCurrentTime() {
    return this.instance.currentTime()
  }

  setFullscreenButtonPosition(fsButton: HTMLElement) {
    fsButton.style.padding = '14px 18px'
    fsButton.style.bottom = '0px'
    fsButton.style.right = '0px'
    this.fsButton = fsButton
  }

  setTipjarButton(tipjarButton: any, closeButton: HTMLElement) {
    tipjarButton.style.transition = 'opacity 300ms'
    const wrapper = this.wrapper
    const fsButton = this.fsButton

    closeButton.style.top = '5px'
    closeButton.style.right = '5px'
    closeButton.addEventListener('click', () => {
      this.track(['closing_dismissable_tipjar_button'])
      tipjarButton.style.opacity = '0'
      tipjarButton.FCshown = false
      closeButton.remove()
    })
    const toggleButtons = (show: any) => {
      this.track(
        ['toggling_tipjar_and_fullscreen_button_with_video_controls', { show }],
        this.analyticsData
      )
      const opacity = show ? '1' : '0'

      fsButton && (fsButton.style.opacity = opacity)
      if (!tipjarButton.FCshown) {
        tipjarButton.style.opacity = opacity
      }
    }

    const { onMouseMove, clearTimer } = createMouseMoveListener(toggleButtons, 5000)
    const onMouseOut = () => {
      toggleButtons(false)
      clearTimer()
    }
    const onLoadOrPlay = () => {
      onMouseMove()
      wrapper?.addEventListener('mousemove', onMouseMove)
      wrapper?.addEventListener('mouseout', onMouseOut)
    }

    this.instance.on('pause', () => {
      toggleButtons(true)
      clearTimer()
    })
    this.instance.on('load', onLoadOrPlay)
    this.instance.on('play', onLoadOrPlay)

    return () => {
      this.track(['showing_dismissable_tipjar_button'])
      wrapper?.appendChild(closeButton)
      tipjarButton.style.opacity = '1'
      tipjarButton.FCshown = true
    }
  }

  async exitPiPAndFullscreen() {
    await super.exitPiPAndFullscreen()
    const player = (this.targetElement as any)?.player
    if (player) {
      try {
        if (player.isFullscreen()) {
          await player.exitFullscreen()
        }

        if (document.pictureInPictureElement) {
          await player.exitPictureInPicture()
        }
      } catch (error) {
        console.warn('Failed to exit fullscreen', error)
      }
    }
  }
}
