import axios from 'axios'
import { ApiBaseUrl } from '../config'
import { hasAuthToken, removeAuthToken, removeRefreshToken, getFastAuthToken } from '../helpers'
import * as analytics from '../helpers/analytics'

const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
  timeout: 1000 * 60 * 2,
  responseType: 'json',
  validateStatus: function (status) {
    return status <= 500
  },
})

axiosInstance.interceptors.request.use(
  async (config: any) => {
    if (await hasAuthToken()) {
      config.headers['Authorization'] = `Bearer ${getFastAuthToken()}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      removeAuthToken()
      removeRefreshToken()
    }
    if (response.status >= 400 || (response.status == 200 && !response.data.success)) {
      let url = ''
      if (response.config.baseURL) {
        url += response.config.baseURL
      }
      if (response.config.url) {
        url += response.config.url
      }
      /** We consider 4xx and 5xx as API Error */
      analytics.track(
        [
          'api_error',
          {
            apiEndpoint: url,
            responseData: response.data,
            status: response.status,
            statusText: response.statusText,
          },
        ],
        {}
      )
    }
    return response
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.message)
  }
)

class FewcentsApiService {
  private static instance: FewcentsApiService
  private axios: any
  private constructor() {
    this.axios = axiosInstance
  }

  public static getInstance(): FewcentsApiService {
    if (!this.instance) {
      this.instance = new FewcentsApiService()
    }
    return this.instance
  }

  get(url: string): Promise<any> {
    return this.axios.get(url)
  }

  post(url: string, payload?: { [k: string]: any }): Promise<any> {
    return this.axios.post(url, payload)
  }

  put(url: string, payload?: { [k: string]: any }): Promise<any> {
    return this.axios.put(url, payload)
  }

  patch(url: string, payload?: { [k: string]: any }): Promise<any> {
    return this.axios.patch(url, payload)
  }

  delete(url: string): Promise<any> {
    return this.axios.delete(url)
  }
}

export const ApiService = FewcentsApiService.getInstance()
