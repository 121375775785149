import { ArticleCard } from './ArticleCard'
import { useArticleFetch } from './bubbleHooks'
import { useAppSelector } from '../../store'
import { Column, Row } from '../Styled/Layouts'
import { FcLogoContainer } from '../Styled'
import { FewcentsDark } from '../Icons'

export const Articles = ({ openMenu, style = {} }: any) => {
  const articles = useAppSelector((store: any) => store?.paywall.articles)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall.fewCentsBidResponse)
  useArticleFetch({ fewCentsBidResponse, openMenu })

  return (
    <>
      {articles && articles.length > 0 ? (
        <Column margin="0px 16px" width="auto" justifyContent="start" style={style}>
          {articles.map((article: any) => (
            <ArticleCard
              key={article.articleId}
              date={article.articlePublishedDate}
              title={article.articleTitle ?? 'N/A'}
              publisher={article.publisherName}
              src={article.articleImageUrl ?? 'N/A'}
              url={article.articleUrl}
            />
          ))}
        </Column>
      ) : null}
      <Row padding="4px">
        <FcLogoContainer
          title="Know more about fewcents"
          onClick={() => {
            window.open('https://www.fewcents.co/know-more-about-fewcents')
          }}
        >
          <FewcentsDark height="20" width="88"></FewcentsDark>
        </FcLogoContainer>
      </Row>
    </>
  )
}
