import JwtDecode from 'jwt-decode'
import { useCallback, useEffect, FC, lazy } from 'react'
import { useAsync, useLocation } from 'react-use'
import { logoutUser } from '../actions/auth'
import { acceptFBidCall, callPaywallAction } from '../actions/paywall'
import { getAuthToken, hasAuthToken, removeAuthToken, removeRefreshToken } from '../helpers'
import { fc_logger } from '../helpers/fc_logger'
import { AuthService } from '../services'
import { updateConfig, updateBidResponse } from '../actions/paywall'
import { PlayerEvents } from '../video-players/IPlayer'
import { useAppDispatch, useAppSelector } from '../store'

export const useBidWrapper = (isRedirectToPaywall: Boolean | null, isCurrencyMismatch: boolean) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const config = useAppSelector((store: any) => store?.paywall?.config)
  const isAuthenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const isLoggedOut = useAppSelector((store: any) => store?.auth?.isLoggedOut)
  const isMedia = config.isMedia
  const selector = sessionStorage.getItem('unlockedContentSelector')
  const shouldCallPaywall =
    !isRedirectToPaywall || (!isLoggedOut && selector && config.contentSelector !== selector)

  //Based on authentication(isAuthenticated, isLoggedOut) and whether the user had been redirected to the paywall
  //(isRedirectedToPaywall) - invoke the callPaywall function
  useAsync(async () => {
    if (isRedirectToPaywall === null) return
    fc_logger.info('inside useAsync in Paywall.tsx')

    if ((await hasAuthToken()) && !isAuthenticated) {
      fc_logger.info('just returning in Paywall.tsx')
      return
    }
    if (shouldCallPaywall) {
      fc_logger.info('calling paywall in Paywall.tsx')
      callPaywall()
    }
  }, [isAuthenticated, isRedirectToPaywall, shouldCallPaywall, config.articleIdentifier])

  useEffect(() => {
    /** Handle auto acceptFBidCall for redirect flow */
    if (isAuthenticated && isRedirectToPaywall && !isCurrencyMismatch && fewCentsBidResponse) {
      if (
        fewCentsBidResponse.fewCentsTransactionMode === 'normal' &&
        fewCentsBidResponse.unlocked === false
      ) {
        const isTopUpRequired =
          (fewCentsBidResponse?.isCreditAllowed &&
            fewCentsBidResponse?.customerBalance < fewCentsBidResponse?.minBalance) ||
          (!fewCentsBidResponse?.isCreditAllowed &&
            fewCentsBidResponse?.customerBalance < fewCentsBidResponse?.articlePriceInLocalCurrency)
        if (isTopUpRequired === false) {
          dispatch(acceptFBidCall())
        }
      }
    }

    if (isMedia && fewCentsBidResponse) {
      if (!fewCentsBidResponse.articleActive || !fewCentsBidResponse.isPaywallEnabledInCountry) {
        window.paywallInstance.hideMediaOverlay()
      } else {
        window.paywallInstance.showMediaOverlay()
      }
    }
  }, [dispatch, fewCentsBidResponse, isAuthenticated, isMedia, isRedirectToPaywall])

  const callPaywall = useCallback(async () => {
    const payload: any = {
      category: config?.category || '',
      contentType: config?.contentType,
      accessKey: config?.accessKey,
      articleIdentifier: config?.player
        ? await config?.player.getSourceId()
        : config?.articleIdentifier || '',
      articleUrl: document.URL,
      publisherCustomerId: config?.publisherCustomerId || '',
      publisherDomain: location?.hostname?.indexOf('www.')
        ? location?.hostname
        : location?.hostname?.replace('www.', ''),
      userAgent: navigator.userAgent && navigator.userAgent.substring(0, 500),
      monetizeType: config?.monetizeType,
      contentPublishedDate: config?.contentPublishedDate,
      contentAuthor: config?.contentAuthor,
      contentTags: config?.contentTags,
      segmentRule: config?.segmentRule,
      column1: config?.column1,
      column2: config?.column2,
    }

    if (config?.isSimEnabled && typeof config?.setSimUserEmail === 'function') {
      fc_logger.info('inside sim case')
      const simUserEmail = config.setSimUserEmail()

      const { accessToken } = await getAuthToken()

      if (accessToken && accessToken !== 'undefined' && accessToken !== 'INVALID') {
        const decoded: any = JwtDecode(String(accessToken))
        const tokenEmail = decoded.username

        if (tokenEmail && tokenEmail !== simUserEmail) {
          removeAuthToken()
          removeRefreshToken()

          await AuthService.logOut(tokenEmail)
          dispatch(logoutUser())
        }
      }
      payload.simUserEmail = simUserEmail
    }

    dispatch(callPaywallAction(payload, isMedia))
  }, [config, dispatch, isMedia, location?.hostname])

  useEffect(() => {
    const player = config?.player
    if (!isMedia || !player || isRedirectToPaywall === null) return

    player.on(PlayerEvents.mediaChange, onMediaChange)
    let timer: any
    function onMediaChange(args: any) {
      timer = setTimeout(() => {
        dispatch(updateBidResponse({ reload: true }))
        dispatch(updateConfig({ articleIdentifier: args.data.videoId }))
        config.player?.pipResume()
      })
    }

    return () => {
      clearTimeout(timer)
      player.off(PlayerEvents.mediaChange, onMediaChange)
    }
  }, [dispatch, isMedia, config?.player])

  const isPaywallEnabled =
    fewCentsBidResponse?.isPaywallEnabledInCountry && fewCentsBidResponse?.articleActive

  return {
    isPaywallEnabled,
    showRecommendation:
      fewCentsBidResponse?.paywallSettings?.showOnsiteRecommendation && !isPaywallEnabled,
  }
}
