import { parseQueryString } from '../helpers'
import { fc_logger } from '../helpers/fc_logger'
import { IConfig } from '../interfaces'
import { PlayerEvents } from './IPlayer'
import { iframeDomains } from './utils'
import { emitter } from '../services/eventemitter'
import { PlayerBase } from './PlayerBase'

const winRef = window as any
export class DMPlayer extends PlayerBase {
  private static timeRef: any = { current: null }
  private currentTime: number
  private elementId?: string | null
  private instance: any
  private handlePlayEvent: boolean = false
  private muted: boolean = false
  constructor(iframe: HTMLElement, config: IConfig, initializedPlayerIds: any[]) {
    super()
    const that = this
    this.currentTime = 0
    const split = iframe.getAttribute('src')?.split('?') || []
    const url = split[0]
    const query = split[1]
    that.analyticsData.player = 'Dailymotion'

    that.resetPlayerInstance(iframe)
    if (initializedPlayerIds.includes(that.sourceId)) {
      that.setSeekOnPlay(DMPlayer.timeRef)
      that.duplicate = true
      return
    }

    this.targetElement = that.getCorrectParent(iframe)
    const setCurrentTime = that.setCurrentTime.bind(that)
    const getEventData = that.getEventData.bind(that)
    const setExitFullscreen = () => (that.exitFullscreen = true)
    const seek = that.seek.bind(that)
    this.sourceId = that.extractVideoId(url, query)
    this.analyticsData.articleIdentifier = that.sourceId

    if (this.instance.getState) {
      this.instance
        .getState()
        .then((state: any) => {
          if (state) {
            that.sourceId = state.videoId
            that.analyticsData.articleIdentifier = state.videoId
            window.addEventListener('message', dailymotionEventListener)
          }
        })
        .catch((error: any) => {
          console.log(error)
          window.addEventListener('message', dailymotionEventListener)
        })
    } else {
      window.addEventListener('message', dailymotionEventListener)
    }

    function dailymotionEventListener(e: any) {
      try {
        const hasOriginatedFromDailymotion = iframeDomains.fc_dailymotion.test(e.origin)

        if (!hasOriginatedFromDailymotion || e.data === 'get') return

        const { id, event, time, videoId } = getEventData(e.data)

        if (event === 'player_heavyadsintervention') {
          console.error(`dailymotion event: ${event}!`)
          that.resetPlayerInstance(iframe)
        }
        if (event === 'fullscreenchange') {
          setExitFullscreen()
        } else if (id === that.elementId) {
          if (event === 'timeupdate') {
            setCurrentTime(time)
            emitter.fire(PlayerEvents.timeUpdate, {})
          } else if (
            videoId &&
            videoId !== that.sourceId &&
            (event === 'videochange' || event === 'playerstate')
          ) {
            that.sourceId = videoId
            that.analyticsData.articleIdentifier = videoId
            emitter.fire(PlayerEvents.mediaChange, { videoId })
          } else if (
            event === 'play' &&
            DMPlayer.timeRef.current &&
            that.handlePlayEvent &&
            winRef.DM?.Player
          ) {
            that.handlePlayEvent = false
            seek(DMPlayer.timeRef.current)
            that.instance.pause()
          }
        }
      } catch (err) {
        fc_logger.info(e.data)
        fc_logger.info(err)
      }
    }
  }

  async getSourceId() {
    const that = this
    if (this.instance.getState) {
      const state = await this.instance.getState()
      if (state) {
        that.sourceId = state.videoId
      }
    }
    return this.sourceId
  }

  private resetPlayerInstance(iframe: any) {
    const that = this
    const wrapper = iframe.parentElement
    if (wrapper.className === 'dailymotion-player-wrapper') {
      that.elementId = wrapper.parentElement.id
      that.instance =
        winRef.dailymotion._do_not_use_74374f47ffcd3d04b2a5.players[that.elementId as string]
    } else if (iframe.id && winRef.DM.Player._INSTANCES['dailymotionPlayer']) {
      that.elementId = iframe.id
      that.instance = winRef.DM.Player._INSTANCES[iframe.id]
    } else {
      that.elementId = iframe.id
      that.instance = iframe
    }
  }

  private getCorrectParent(iframe: any) {
    if (iframe.parentElement.className === 'dailymotion-player-wrapper') {
      let playerRoot = iframe.parentElement.parentElement
      if (playerRoot.parentElement.className.indexOf('dm-player-wrapper') > -1) {
        playerRoot = playerRoot.parentElement
      }
      return playerRoot
    }
    return iframe
  }

  setWrapper(wrapper: HTMLElement) {
    super.setWrapper(wrapper)
    this.resizePlayer()
  }

  private extractVideoId(url: string, query: string) {
    if (url.indexOf('/video/') > -1) {
      return url?.split('/video/')[1] || ''
    }
    return (
      query
        ?.split('&')
        ?.find((attr) => attr.split('=')[0] === 'video')
        ?.split('=')[1] || ''
    )
  }

  async play() {
    this.track(['video_played_programmatically'])
    const that = this
    try {
      if (that.instance.play) {
        await that.instance.play()
      } else {
        await that.send('play', [])
      }
    } catch (error) {
      console.error(error)
    }
  }

  async exitPiPAndFullscreen() {
    const dm = winRef.dailymotion || winRef.DM || this.instance
    if (dm && dm.pipClose) {
      await dm.pipClose()
    }
    if (this.instance.setFullscreen) {
      this.instance.setFullscreen(false)
    }
    if (this.instance.getState) {
      const state = await this.instance.getState()
      if (state && !state.playerIsViewable) {
        this.targetElement?.scrollIntoView()
      }
    }
  }

  public async pipResume() {
    const dm = winRef.dailymotion || winRef.DM || this.instance
    if (dm && dm.pipResume) {
      await dm.pipResume()
    }
  }

  async pause() {
    this.track(['video_paused_programmatically'])
    try {
      this.muted = this.instance.muted
      if (this.instance.pause) {
        await this.instance.pause()
      } else {
        await this.send('pause', [])
      }
    } catch (error) {
      console.error(error)
    }
  }

  private async seek(time: number) {
    try {
      if (this.instance.seek) {
        await this.instance.seek(time)
      } else {
        await this.send('seek', [time])
      }
    } catch (error) {
      console.error(error)
    }
  }

  private async send(command: string, parameters: any) {
    if (this.instance.contentWindow) {
      this.instance.contentWindow.postMessage(
        JSON.stringify({
          command: command,
          parameters: parameters || [],
        }),
        '*'
      )
    }
  }

  listenResizeEvent() {
    const that = this
    window.addEventListener('resize', () => {
      that.track(['screen_resize'])
      emitter.fire(PlayerEvents.screenResize, {})
      this.resizePlayer()
    })
  }

  private resizePlayer() {
    if (this.wrapper && winRef.dailymotion) {
      const width =
        window.innerWidth > 700 && document.querySelector('.dm__playlist-wrapper') ? '75%' : '100%'
      this.wrapper.style.height = '100%'
      this.wrapper.style.width = width
    }
  }

  getCurrentTime() {
    return this.currentTime
  }

  private setCurrentTime(time: number) {
    this.currentTime = time
  }

  private getEventData(rowData: string) {
    try {
      return JSON.parse(rowData)
    } catch (error) {
      return parseQueryString(rowData)
    }
  }

  setSeekOnPlay(timeRef: any) {
    this.handlePlayEvent = true
    DMPlayer.timeRef = timeRef
  }

  setFullscreenButtonPosition(fsButton: HTMLElement) {
    fsButton.style.padding = '18px 32px'
    fsButton.style.bottom = '15px'
    fsButton.style.right = '5px'
    this.fsButton = fsButton
  }

  setTipjarButton(tipjarButton: HTMLElement, closeButton: HTMLElement) {
    tipjarButton.style.transition = 'opacity 600ms'
    tipjarButton.style.top = '10px'
    closeButton.style.top = '5px'
    closeButton.style.right = '5px'
    const wrapper = this.wrapper

    const hideButton = () => {
      tipjarButton.style.opacity = '0'
    }

    this.track(['showing_dismissable_tipjar_button'])
    wrapper?.appendChild(closeButton)
    closeButton.addEventListener('click', () => {
      this.track(['closing_dismissable_tipjar_button'])
      hideButton()
      closeButton.remove()
    })
  }
}
