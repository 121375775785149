import { useEffect } from 'react'
import { useAppDispatch } from '../../store'
import { handleApiErrors } from '../../helpers/handleApiErrors'
import * as CommonMessages from '../../constants/common'
import { showFailureMessage } from '../../actions/toast'
import { config } from '../../config'
import { getAuthToken } from '../../helpers'
import {
  burstPublisher,
  updateLastTransaction,
  updateRecommendedArticles,
} from '../../actions/paywall'

interface Props {
  fewCentsBidResponse: any
  openMenu: boolean
}

export const useArticleFetch = (props: Props) => {
  const { fewCentsBidResponse, openMenu } = props
  const dispatch = useAppDispatch()

  useEffect(() => {
    const ac = new AbortController()
    const signal = ac.signal
    if (
      fewCentsBidResponse &&
      fewCentsBidResponse.customerName !== 'N/A' &&
      openMenu &&
      fewCentsBidResponse.customerId
    ) {
      ;(async () => {
        const { accessToken }: any = await getAuthToken()
        try {
          const { customerId, fewCentsBidId, publisherId } = fewCentsBidResponse
          const response = await fetch(
            `${config.ApiBaseUrl}/v2/fbid/getBalanceAndArticleRecommendation`,
            {
              signal,
              method: 'POST',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ customerId, fewCentsBidId, publisherId }),
            }
          )
          const verifiedResponse = handleApiErrors(response)
          const json = await verifiedResponse.json()
          const { data, message, success } = json

          if (!success) {
            throw new Error(message ?? CommonMessages.COMMON_FAILURE)
          }
          const { recommendations, ...dataPayload } = data
          dispatch(updateRecommendedArticles(recommendations))
          dispatch(updateLastTransaction(dataPayload))
        } catch (e: any) {
          if (e.message.includes('Unauthorized')) {
            dispatch(burstPublisher(e.message, e.message))
          } else if (signal?.aborted) {
            console.error(e)
          } else {
            showFailureMessage(e.message)
          }
        }
      })()
    }

    return () => {
      ac.abort()
    }
  }, [dispatch, fewCentsBidResponse, openMenu])
}
