import { lazy, useEffect, FC, Suspense } from 'react'
import { useAppSelector } from '../store'

const MediaPaywall = lazy(() => import(/* webpackChunkName: "MediaPaywall" */ './MediaPaywall'))
const MediaTipjar = lazy(() => import(/* webpackChunkName: "MediaTipjar" */ './MediaTipjar'))
const TextPaywall = lazy(() => import(/* webpackChunkName: "TextPaywall" */ './TextPaywall'))

interface ContainerProps {
  articleMadeVisible: Boolean
  setArticleMadeVisible: Function
}
const PaywallContainer: FC<ContainerProps> = ({ articleMadeVisible, setArticleMadeVisible }) => {
  const isMedia = useAppSelector((store: any) => store?.paywall?.config?.isMedia)
  const track = useAppSelector((store: any) => store?.paywall?.config?.track)
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const isTipjar = fewCentsBidResponse?.fewCentsTransactionMode === 'tipjar'

  const bannerClickHandler = (e: any) => {
    e.stopPropagation()
    track(['fc_banner_clicked'])
    window.focusOnWall && window.focusOnWall()
  }
  const appendBanner = () => {
    let bannerElements = Array.from(
      (document.getElementsByClassName('fc_banner') as HTMLCollectionOf<HTMLElement>) || []
    )

    if (bannerElements && bannerElements.length > 0) {
      track(['fc_banner_injected'])
    }

    bannerElements.forEach((htmlEl: HTMLElement) => {
      let spanTag = document.createElement('span')
      spanTag.innerText = 'Support Us'
      spanTag.style.margin = 'unset !important'
      spanTag.style.padding = 'unset !important'
      spanTag.style.fontSize = '1.4rem !important'
      spanTag.style.alignContent = 'unset !important'
      if (htmlEl.innerHTML.trim() === '' && htmlEl.innerText.trim() === '')
        htmlEl.appendChild(spanTag)
      htmlEl.addEventListener('click', bannerClickHandler)
      //  bannerElement.addEventListener('click', bannerClickHandler)
    })
    return () => {
      bannerElements.forEach((bannerElement) => {
        bannerElement.style.display = 'none'
        bannerElement.removeEventListener('click', bannerClickHandler)
      })
    }
  }

  function hideBanner() {
    let bannerElements = Array.from(
      document.getElementsByClassName('fc_banner') as HTMLCollectionOf<HTMLElement>
    )
    bannerElements.forEach((bannerElement) => {
      bannerElement.style.display = 'none'
    })
  }

  useEffect(() => {
    let cleanup
    if (isTipjar) {
      cleanup = appendBanner()
    } else {
      hideBanner()
    }

    return cleanup
  }, [isTipjar])

  const Component = isMedia ? (isTipjar ? MediaTipjar : MediaPaywall) : TextPaywall

  return (
    <Suspense fallback={<div />}>
      <Component
        articleMadeVisible={articleMadeVisible}
        setArticleMadeVisible={setArticleMadeVisible}
      />
    </Suspense>
  )
}

export default PaywallContainer
