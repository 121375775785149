import * as analytics from '../helpers/analytics'

export type EventHandler = ((evt: Event) => void) & {
  _once?: boolean
}

export interface Event {
  type: string
  data: any
  timestamp: number
  once: boolean
}

export class EventEmitter {
  _eventHandlers: Record<string, EventHandler[] | undefined>

  constructor() {
    this._eventHandlers = {}
  }

  isValidType(type: string) {
    return typeof type === 'string'
  }
  isValidHandler(handler: EventHandler) {
    return typeof handler === 'function'
  }
  on(type: string, handler: EventHandler) {
    if (!type || !handler) return false
    if (!this.isValidType(type)) return false
    if (!this.isValidHandler(handler)) return false
    let handlers = this._eventHandlers[type]
    if (!handlers) handlers = this._eventHandlers[type] = []
    // when the same handler is passed, listen it by only once.
    if (handlers.indexOf(handler) >= 0) return false
    handler._once = false
    handlers.push(handler)
    return true
  }

  once(type: string, handler: EventHandler) {
    if (!type || !handler) return false
    if (!this.isValidType(type)) return false
    if (!this.isValidHandler(handler)) return false
    let ret = this.on(type, handler)
    if (ret) {
      // set `_once` private property after listened,
      // avoid to modify event handler that has been listened.
      handler._once = true
    }
  }

  off(type?: string, handler?: EventHandler) {
    // listen off all events, when if no arguments are passed.
    // it does samething as `offAll` method.
    if (!type) return this.offAll()
    // listen off events by type, when if only type argument is passed.
    if (!handler) {
      this._eventHandlers[type] = []
      return
    }
    if (!this.isValidType(type)) return
    if (!this.isValidHandler(handler)) return
    var handlers = this._eventHandlers[type]
    if (!handlers || !handlers.length) return
    // otherwise, listen off the specified event.
    for (let i = 0; i < handlers.length; i++) {
      let fn = handlers[i]
      if (fn === handler) {
        handlers.splice(i, 1)
        break
      }
    }
  }

  offAll() {
    this._eventHandlers = {}
  }

  fire(type: string, data?: any, bidResponse = {}) {
    try {
      if (!type || !this.isValidType(type)) return
      let handlers = this._eventHandlers[type]
      if (!handlers || !handlers.length) return
      let event = this.createEvent(type, data)
      for (let _i = 0, handlers_1 = handlers; _i < handlers_1.length; _i++) {
        let handler = handlers_1[_i]
        if (!this.isValidHandler(handler)) continue
        if (handler._once) event.once = true
        // call event handler, and pass the event argument.
        handler(event)
        // if it's an once event, listen off it immediately after called handler.
        if (event.once) this.off(type, handler)
      }
    } catch (error) {
      analytics.track(['fc_event_error', { reason: error }], bidResponse)
    }
  }

  has(type: string, handler?: EventHandler) {
    if (!type || !this.isValidType(type)) return false
    let handlers = this._eventHandlers[type]
    // if there are no any events, return false.
    if (!handlers || !handlers.length) return false
    // at lest one event, and no pass `handler` argument, then return true.
    if (!handler || !this.isValidHandler(handler)) return true
    // otherwise, need to traverse the handlers.
    return handlers.indexOf(handler) >= 0
  }

  getHandlers(type: string) {
    if (!type || !this.isValidType(type)) return []
    return this._eventHandlers[type] || []
  }

  createEvent(type: string, data?: any, once?: boolean) {
    if (once === void 0) {
      once = false
    }
    let event = { type: type, data: data, timestamp: Date.now(), once: once }
    return event
  }
}

const emitter = new EventEmitter()

export { emitter }
