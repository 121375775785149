import ActionTypes from '../constants/actionTypes'
import { emitter } from '../services/eventemitter'

export const setCurrentUser = (user: any, device: any) => {
  return {
    type: ActionTypes.SET_USER,
    payload: {
      user,
      device,
      isAuthenticated: !user?.isGuestAccount,
    },
  }
}

export const logoutUser = () => {
  emitter.fire('onFewcentsLogout', {
    loggedOut: true,
  })
  return {
    type: ActionTypes.LOGOUT,
  }
}

export const resetAuth = () => {
  return {
    type: ActionTypes.RESET_AUTH,
  }
}

export const setIsLoggedOut = (isLoggedOut: boolean) => ({
  type: ActionTypes.SET_IS_LOGGED_OUT,
  payload: isLoggedOut,
})
