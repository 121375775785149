//Regex that matches the start of the string with "http(s)://*.dailymotion.*"
export const iframeDomains = {
  fc_dailymotion: /^https?:\/\/[^\/\?]+\.dailymotion\..+/,
  fc_vimeo: /^https?:\/\/[^\/\?]+\.vimeo\..+/,
}

export const createMouseMoveListener = (toggleButtons: Function, delayInMs: number) => {
  let timer: any
  return {
    clearTimer: () => {
      clearTimeout(timer)
    },
    onMouseMove: () => {
      toggleButtons(true)
      clearTimeout(timer)
      timer = setTimeout(() => {
        toggleButtons(false)
      }, delayInMs)
    },
  }
}
