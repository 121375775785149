import { useState, useRef, useMemo, useEffect } from 'react'

const getSlideDirections = (from) => {
  switch (from) {
    case 'right':
      return { initial: 'translateX(100%)', final: 'translateX(0)' }
    case 'top':
      return { initial: 'translateY(-100%)', final: 'translateY(0)' }
    case 'bottom':
      return { initial: 'translateY(100%)', final: 'translateY(0)' }
    case 'left':
    default:
      return { initial: 'translateX(-100%)', final: 'translateX(0)' }
  }
}

const getTransitionStyles = (type, duration, from) => {
  switch (type) {
    case 'spin':
      return {
        initial: {
          transform: 'rotateZ(0deg)',
          opacity: 0,
          transition: `transform ${duration}, opacity ${duration}`,
        },
        final: {
          transform: 'rotateZ(360deg)',
          opacity: 1,
          transition: `transform ${duration}, opacity ${duration}`,
        },
      }
    case 'slide':
      const transformStyles = getSlideDirections(from)
      return {
        initial: {
          transform: transformStyles.initial,
          opacity: 0,
          transition: `opacity ${duration}, transform ${duration}`,
        },
        final: {
          transform: transformStyles.final,
          opacity: 1,
          transition: `opacity ${duration}, transform ${duration}`,
        },
      }
    case 'fade':
    default:
      return {
        initial: { opacity: 0, transition: `opacity ${duration}` },
        final: { opacity: 1, transition: `opacity ${duration}` },
      }
  }
}

const TransitionOnMount = ({
  children,
  type = 'fade',
  duration = '0.5s',
  from = 'left',
  baseStyles = {},
  customTransition = {},
}) => {
  const mounted = useRef(false)
  const transitionStyle = useMemo(() => {
    if (type === 'custom') {
      return customTransition
    }
    return getTransitionStyles(type, duration, from)
  }, [duration, type, customTransition])

  const [style, setStyle] = useState(transitionStyle?.initial || {})
  let timeout = null

  useEffect(() => {
    if (!mounted.current) {
      //Because the component gets mounted again for vimeo and dailymotion(in exitFullscreen)
      //the setTimeout ensures that the transition occurs after the component remounts successfully
      //The transitions do not appear in case the setTimeout is not used. (Discovered by trial and error)
      timeout = setTimeout(() => {
        setStyle(transitionStyle?.final || {})
      }, 0)

      mounted.current = true
      return () => timeout && clearTimeout(timeout)
    }
  }, [style])

  return <div style={{ ...baseStyles, ...style }}>{children}</div>
}

export default TransitionOnMount
