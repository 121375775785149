import { Row } from '../Styled/Layouts'
import { SvgContainer, SvgText, Icon } from './Styled'

export const iconStyle = {
  width: '26px',
  height: '26px',
  display: 'inline-block',
  fill: 'currentColor',
  fontSize: '26px',
  cursor: 'pointer',
  color: 'currentColor',
  borderRadius: '4px',
}
const emailStyle = { margin: 'auto 4px' }
const closeStyle = {
  userSelect: 'none',
  width: '20px',
  height: '20px',
  display: 'inline-block',
  fill: 'currentColor',
  fontSize: '25px',
}
const imgStyle = { marginTop: '-24px' }
const unlockStyle = { marginTop: '10px', marginBottom: '50px' }
const linkoutStyle = { width: '100%', marginTop: '16px' }
const fbStyle = { marginLeft: '-4px', marginRight: '4px' }
const googleStyle = { marginRight: '-4px', marginLeft: '-14px' }
const appleStyle = { marginRight: '8px' }
const errorStyle = { height: '20px', marginRight: '8px' }
const guestStyle = { marginRight: '2px' }
const empty = {}
const bookStyle = {
  width: '26px',
  height: '26px',
  display: 'inline-block',
  fill: 'currentColor',
  fontSize: '26px',
  cursor: 'pointer',
  color: 'currentColor',
  borderRadius: '4px',
  marginBottom: '3px',
}

export const Book = (props: any) => <Icon name="Book" style={bookStyle} {...props} />
export const Close = (props: any) => <Icon style={closeStyle} name="Close" {...props} />
export const Reward = () => <Icon name="Reward" />
export const Fewcents = (props: any) => <Icon name="Fewcents" {...props} />
export const FewcentsDark = (props: any) => <Icon name="Fewcents-Dark" {...props} />
export const PublisherDefault = (props: any) => <Icon name="PublisherDefault" {...props} />
export const Video = () => <Icon style={iconStyle} name="Video" />
export const Wallet = (props: any) => <Icon name="wallet" width="19px" height="25px" {...props} />
export const Facebook = () => <Icon width="18" height="18" name="Facebook" style={fbStyle} />
export const Email = () => <Icon width="22" height="22" name="Email" style={emailStyle} />
export const Google = () => <Icon width="40" height="40" style={googleStyle} name="Google" />
export const Apple = () => <Icon height="20px" width="20px" style={appleStyle} name="Apple" />
export const Error = () => <Icon style={errorStyle} name="Error" />
export const Info = () => <Icon name="Info" width="20" height="20" />
export const User = (props: any) => <Icon width="20" height="20" {...props} name="User" />
export const Diamond = (props: any) => <Icon {...props} name="Diamond" />
export const Guest = (props: any) => <Icon style={guestStyle} {...props} name="Guest" />
export const Lock = (props: any) => <Icon width="20" height="20" {...props} name="Lock" />
export const Logout = (props: any) => <Icon width="16" height="16" {...props} name="Logout" />
export const Shield = (props: any) => <Icon name="Shield" width="24" height="24" {...props} />
export const TipjarUnlocked = ({ fewCentsBidResponse, position }: any) => {
  return (
    <SvgContainer style={position === 'absolute' ? linkoutStyle : empty}>
      <SvgText>{fewCentsBidResponse?.paywallSettings?.fcAutoUnlockText}</SvgText>
      <Icon name="TipjarUnlocked" />
    </SvgContainer>
  )
}
export const Expand = ({ direction }: any) => {
  direction = direction || 'right'
  const style =
    direction === 'left'
      ? { ...iconStyle, transform: 'rotate(270deg)' }
      : {
          ...iconStyle,
          transform: 'rotate(90deg)',
          marginTop: '4px',
        }

  return <Icon style={style} name="Expand" />
}
export const PaywallUnlocked = ({ fewCentsBidResponse }: any) => {
  const bannerUrl = fewCentsBidResponse?.paywallSettings?.rewardBanner
  return (
    <SvgContainer>
      <SvgText>
        <Row style={imgStyle}>
          {bannerUrl ? <img src={bannerUrl} alt="Publisher Banner" width="100%" /> : null}
        </Row>{' '}
        {bannerUrl ? (
          <Row style={unlockStyle}>{fewCentsBidResponse?.paywallSettings?.fcAutoUnlockText}</Row>
        ) : (
          <Row>
            <h2>{fewCentsBidResponse?.paywallSettings?.fcAutoUnlockText}</h2>
          </Row>
        )}
      </SvgText>
      <Icon className="splach-unlocked" name="PaywallUnlocked" />
    </SvgContainer>
  )
}
