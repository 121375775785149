import { Link, NormalText, SmallText } from '../Styled/Typography'
import styled, { ThemeProvider } from 'styled-components'
import { hexToRgba } from '../../helpers'
import { Column, Row } from '../Styled/Layouts'
import { Suspense } from 'react'

export interface IPosition {
  position?: 'left' | 'right' | 'top-left' | 'top-right'
  isTipjar: boolean,
  bubbleOpenWidgetPosition: any
}

export const Container = styled.div<IPosition>`
  display: inline;
  position: fixed;
  transition: all 1s;
  z-index: 2147483645;
  font-family: ${(props) => props.theme.fontFamily};

  ${(props) => {
    const theme = props.theme
    const isTipjar = props.isTipjar
    switch (props.position) {
      case 'left':
        return `
          bottom: ${theme.desktopOffset.vertical};
          left: ${isTipjar ? theme.desktopOffset.horizontal : 0};
          @media (max-width: 540px) {
            bottom: ${theme.mobileOffset.vertical};
            left: ${isTipjar ? theme.mobileOffset.horizontal : 0};
          }
        `
      case 'right':
        return `
          bottom: ${theme.desktopOffset.vertical};
          right: ${isTipjar ? theme.desktopOffset.horizontal : 0};
          @media (max-width: 540px) {
            bottom: ${theme.mobileOffset.vertical};
            right: ${isTipjar ? theme.mobileOffset.horizontal : 0};
          }
        `
      case 'top-left':
        return `
          top: ${theme.desktopOffset.vertical};
          left: ${isTipjar ? theme.desktopOffset.horizontal : 0};
          @media (max-width: 540px) {
            top: ${theme.mobileOffset.vertical};
            left: ${isTipjar ? theme.mobileOffset.horizontal : 0};
          }
        `
      case 'top-right':
        return `
          top: ${theme.desktopOffset.vertical};
          right:${isTipjar ? theme.desktopOffset.horizontal : 0};
          @media (max-width: 540px) {
            top: ${theme.mobileOffset.vertical};
            right: ${isTipjar ? theme.mobileOffset.horizontal : 0};
          }
        `
      default:
        return `
          bottom: 20%;
          right: ${isTipjar ? '2%' : 0};
          @media (max-width: 540px) {
              bottom: 16px;
              right: ${isTipjar ? '16px' : 0};
          }
        `
    }
  }}
`

export const MenuOpened = styled.div<IPosition>`
  &&& {
    position: fixed;
    transition: all 0.5s;
    height: auto;
    border-radius: ${({ theme }) => theme.borderRadius};
    background: ${({ theme }) => theme.backgroundColor};
    box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 20%) 0px 24px 38px 3px,
      rgb(0 0 0 / 20%) 0px 9px 46px 8px;
    min-width: 326px;
    outline: ${({ theme }) => `1px solid ${hexToRgba(theme.primaryColor, 0.1)}`};
    max-width: 350px;
    @media (max-width: 360px) {
      max-width: 340px;
    }
    @media (max-width: 280px) {
      max-width: 260px;
    }

    ${(props) => {
      switch (props.position) {
        case 'left':
          return `
            left: 32px;
            bottom: ${props.bubbleOpenWidgetPosition};
            @media (max-width: 540px) {
              top: 50%;
              left: 50%;
              right: unset;
              bottom: unset;
              transform: translate(-50%, -50%);
            }
          `
        case 'right':
          return `
            right: 32px;
            bottom: ${props.bubbleOpenWidgetPosition};
            @media (max-width: 540px) {
              top: 50%;
              left: 50%;
              right: unset;
              bottom: unset;
              transform: translate(-50%, -50%);
            }
          `
        case 'top-left':
          return `
              top: ${props.bubbleOpenWidgetPosition};
              left: 32px;
              @media (max-width: 540px) {
                top: 50%;
                left: 50%;
                right: unset;
                bottom: unset;
                transform: translate(-50%, -50%);
              }
            `
        case 'top-right':
          return `
              top: ${props.bubbleOpenWidgetPosition};
              right: 32px;
              @media (max-width: 540px) {
                top: 50%;
                left: 50%;
                right: unset;
                bottom: unset;
                transform: translate(-50%, -50%);
              }
            `
      }
    }}
  }
`

function getBubbleBorder(props: any) {
  if (props.isTipjar) {
    return '5px'
  } else if (props.native && props.position.indexOf('left') > -1) {
    return '5px 5px 5px 5px'
  } else if (props.native && props.position.indexOf('right') > -1) {
    return '5px 5px 5px 5px'
  } else if (props.position.indexOf('left') > -1) {
    return '5px 5px 5px 5px'
  }
  return '5px 5px 5px 5px'
}

interface IMenuClosed extends IPosition {
  native?: boolean
}

interface MenuClosedIsLogin extends IPosition {
  native?: boolean
}

export const MenuClosed = styled.div<IMenuClosed>`
  &&& {
    display: flex;
    flex-direction: column;
    border-radius: ${(props) => getBubbleBorder(props)};
    width: ${(props) => (props.isTipjar ? 'auto' : 'unset')};
    height: ${(props) => (props.isTipjar ? '40px' : 'unset')};
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.backgroundColor};
    background: ${(props: any) => props.theme.primaryColor};
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
    text-align: center;
    vertical-align: middle;
    box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.25);
    padding: ${(props) => (props.isTipjar ? '0px 10px 2px' : '6px 8px')};
  }
`

export const IsMenuClosed = styled.div<MenuClosedIsLogin>`
  &&& {
    padding: 0px;
    border-radius: 5px;
    transform: rotate(270deg);
    background: ${(props: any) => props.theme.primaryColor};
    margin-right: -28px;
  }
`

export const ArticleMenuClosed = styled(MenuClosed)`
  &&& {
    padding: 0px;
    border-radius: 5px;
    transform: rotate(270deg);
    background: ${(props: any) => props.theme.primaryColor};
  }
`

export const BubbleLogo = styled.div`
  height: 55px;
  width: 55px;
  max-width: none;
  margin: -8px;
  padding-right: 0px;
`

export const ArticleBubbleLogo = styled(BubbleLogo)`
  justify-content: center;
  display: flex;
  width: unset;
  flex-direction: row;
  align-items: center;
  margin: -2px 12px;
  color: ${({ theme }) => theme.backgroundColor};
`
export const PaywallBubbleBannerText = styled(SmallText)`
  line-height: 14px;
  font-weight: 600;
`

export const PaywallBubblePriceText = styled(SmallText)`
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
`

export const CustomerName = styled(NormalText)`
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledLink = styled(Link)`
  width: 100%;
  margin: 8px 0px;
  font-weight: 400;
  color: hsla(0, 0%, 73%, 1);
  opacity: 1;
  &:link,
  &:active,
  &:visited {
    color: hsla(0, 0%, 73%, 1);
  }
`

export const CardInfo = styled.div`
  position: relative;
  border: 0.1rem solid ${({ theme }) => hexToRgba(theme.borderColor, 0.8)};
  border-radius: 0.3rem;
  background-color: ${({ theme }) => hexToRgba(theme.borderColor, 0.8)};
  color: ${({ theme }) => theme.secondaryColor};
  margin: 16px;
  padding: 4px 12px 8px 12px;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  @media (max-width: 540px) {
    width: auto;
  }
`

export const CardInfoTop = styled(Column)`
  color: ${({ theme }) => theme.secondaryColor};
  z-index: 3;
  justify-content: left;
  align-items: start;
`

export const ButtonRow = styled(Row)`
  height: 35px;
  width: calc(100% - 10px);
`

export const Button = styled(Row)`
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 400;
  border: 1px solid;
  border-color: ${({ theme }) => hexToRgba(theme.primaryColor, 0.5)};
  background: ${({ theme }) => theme.backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  height: inherit;
  padding: 0 12px;
  width: 100%;
`

export const ArticleRow = styled(Row)`
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.borderColor};
  padding: 8px;
  box-sizing: border-box;
  align-items: start;
  @media (max-width: 540px) {
    width: auto;
  }
`

export const ArticleImgWrapper = styled.div`
  width: 76px;
  height: 69px;
  border-radius: 5px;
  overflow: hidden;
`

export const ArticleImg = styled.img`
  width: inherit;
  height: inherit;
`

export const ArticleContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: calc(100% - 84px);
`

export const ArticleTitle = styled(SmallText)`
  font-weight: 600;

  color: ${({ theme }) => hexToRgba(theme.secondaryColor, 0.7)};
  margin: 0;
`

export const ArticleDate = styled(SmallText)`
  font-size: 9px;
  margin-bottom: 8px;
`
export const PaywallWrapper = styled.div`
  margin-top: 4px;
`

export const ArticleContainer = styled.div`
  margin-bottom: 12px;
`

export const PriceContainer = styled(Row)`
  margin-top: 4px;
`
export const LogoutContainer = styled(Row)`
  margin: 0px 18px;
`
interface IAbsolute {
  left?: string
  right?: string
  top?: string
  bottom?: string
}

export const Absolute = styled.div<IAbsolute>`
  position: absolute;
  left: ${({ left }) => left || 'unset'};
  right: ${({ right }) => right || 'unset'};
  top: ${({ top }) => top || 'unset'};
  bottom: ${({ bottom }) => bottom || 'unset'};
  cursor: pointer;
`

export const BubbleContainer = ({ theme, onClick, bubblePosition, isTipjar, children }: any) => {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div />}>
        <Container
          className="fc-bubble-container"
          {...bubblePosition}
          onClick={onClick}
          isTipjar={isTipjar}
        >
          {children}
        </Container>
      </Suspense>
    </ThemeProvider>
  )
}
