import { createMouseMoveListener } from './utils'
import { PlayerEvents } from './IPlayer'
import { IConfig } from '../interfaces'
import { emitter } from '../services/eventemitter'
import { PlayerBase } from './PlayerBase'

export class HTML5Video extends PlayerBase {
  private instance: HTMLVideoElement

  constructor(video: HTMLVideoElement, config: IConfig, initializedPlayerIds: string[]) {
    super()
    this.instance = video
    this.sourceId = this.assignSourceId()
    //If no source is found or source is duplicate, don't initialize the Paywall
    if (!this.sourceId || initializedPlayerIds.includes(this.sourceId)) {
      this.duplicate = true
      return
    }
    this.analyticsData.articleIdentifier = this.sourceId
    this.analyticsData.player = 'HTML5Video'
    this.targetElement = video
    this.instance.ontimeupdate = (evt) => {
      emitter.fire(PlayerEvents.timeUpdate, evt)
    }
  }

  private assignSourceId() {
    let src = this.instance.getAttribute('src')
    if (!src) {
      const sourceElement = this.instance.getElementsByTagName('source')[0]
      src = sourceElement?.getAttribute('src')
    }
    return src || ''
  }

  async play() {
    this.track(['video_played_programmatically'])
    await this.instance.play()
  }

  async pause() {
    this.track(['video_paused_programmatically'])
    this.instance.pause()
  }

  setWrapper(wrapper: HTMLElement) {
    super.setWrapper(wrapper)
    wrapper.style.height = 'unset'
    wrapper.style.width = 'unset'
  }

  getCurrentTime() {
    return this.instance.currentTime
  }

  setFullscreenButtonPosition(fsButton: HTMLElement) {
    fsButton.style.padding = '20px'
    fsButton.style.bottom = '28px'
    fsButton.style.right = '52px'
    fsButton.style.transition = 'opacity 300ms ease-in'
    this.fsButton = fsButton
  }

  setTipjarButton(tipjarButton: any, closeButton: HTMLElement) {
    tipjarButton.style.transition = 'opacity 300ms'
    const wrapper = this.wrapper
    const fsButton = this.fsButton
    const toggleButtons = (show: any) => {
      const opacity = show ? '1' : '0'
      this.track(['toggling_tipjar_and_fullscreen_button_with_video_controls', { show }])
      fsButton && (fsButton.style.opacity = opacity)
      if (!tipjarButton.FCshown) {
        tipjarButton.style.opacity = opacity
      }
    }
    const { onMouseMove, clearTimer } = createMouseMoveListener(toggleButtons, 2500)
    const onMouseOut = () => {
      toggleButtons(false)
      clearTimer()
    }
    const hideButton = () => {
      tipjarButton.style.opacity = '0'
      setTimeout(() => (tipjarButton.FCshown = false), 2000)
    }

    closeButton.style.top = '5px'
    closeButton.style.right = '5px'
    closeButton.addEventListener('click', () => {
      this.track(['closing_dismissable_tipjar_button'])
      hideButton()
      closeButton.remove()
    })
    const onLoadOrPlay = () => {
      onMouseMove()
      wrapper?.addEventListener('mousemove', onMouseMove)
      wrapper?.addEventListener('mouseout', onMouseOut)
    }
    this.instance.addEventListener('pause', () => {
      toggleButtons(true)
      clearTimer()
      wrapper?.removeEventListener('mouseout', onMouseOut)
    })
    if (this.instance.autoplay) {
      onLoadOrPlay()
    }
    this.instance.addEventListener('play', onLoadOrPlay)

    return () => {
      this.track(['showing_dismissable_tipjar_button'])
      wrapper?.appendChild(closeButton)
      tipjarButton.style.opacity = '1'
      tipjarButton.FCshown = true
    }
  }
}
