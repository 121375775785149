import Action from '../constants/actionTypes'
import { IAction } from '../interfaces'

const defaultState = {
  fewCentsBidResponse: null,
  fewCentsLastTransaction: null,
  defaultWallet: null,
  inProgress: false,
  success: false,
  successEmails: [],
  failedEmails: [],
  articles: [],
  config: { isLinkout: false, borderless: false },
  enableRedirect: true,
  isBubbleOpen: false,
  isVisible: true,
  loginMethodCount: 0,
}

const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case Action.UPDATE_PAYWALL:
      return {
        ...state,
        ...action.payload,
      }
    case Action.UPDATE_BID_RESPONSE:
      return {
        ...state,
        fewCentsBidResponse: {
          ...action.payload,
        },
      }
    case Action.UPDATE_DEFAULT_WALLET:
      const wallet = action.payload
        ? action.payload.customerwallets.filter((item: any) => item.isDefault)
        : null
      return {
        ...state,
        defaultWallet: wallet[0],
      }
    case Action.UPDATE_LAST_TRANSACTION:
      return {
        ...state,
        fewCentsLastTransaction: {
          ...action.payload,
        },
      }
    case Action.LOGOUT:
      return {
        ...defaultState,
        config: state.config,
      }
    case Action.UPDATE_CUSTOMER_BALANCE:
      if (state.fewCentsBidResponse !== null) {
        return {
          ...state,
          fewCentsBidResponse: {
            //@ts-ignore
            ...state.fewCentsBidResponse,
            customerBalance: action.balance,
          },
        }
      } else {
        return state
      }
    case Action.UPDATE_RECOMMENDED_ARTICLES:
      return {
        ...state,
        articles: action.articles,
      }
    case Action.UPDATE_ASK_PRICE:
      return {
        ...state,
        askPrice: action.payload,
      }
    case Action.UPDATE_CONFIG:
      return {
        ...state,
        config: { ...state.config, ...action.config },
      }
    case Action.ENABLE_BUTTON:
      return {
        ...state,
        enableRedirect: action.enableButton,
      }
    case Action.UPDATE_LOGIN_METHOD_COUNT:
      return {
        ...state,
        loginMethodCount: action.payload,
      }
    default:
      return state
  }
}

export default reducer
