import styled, { css } from 'styled-components'

interface IFlex {
  justifyContent?: string
  alignItems?: string
  padding?: string
  margin?: string
  flexDirection?: string
  width?: string
}
export const Flex = styled.div<IFlex>`
  display: flex;
  ${(props) => css`
    justify-content: ${props.justifyContent || 'center'};
    align-items: ${props.alignItems || 'center'};
    margin: ${props.margin || '0'};
    padding: ${props.padding || '0'};
    flex-direction: ${props.flexDirection || 'row'};
    width: ${props.width || '100%'};
  `}
`
export const Row = styled(Flex)``
export const Column = styled(Flex)`
  flex-direction: column;
`
