import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import { fc_logger } from '../helpers/fc_logger'
import { updateAskPrice } from '../actions/paywall'

const useTipjarPrices = (bubble: Boolean | undefined) => {
  const dispatch: any = useAppDispatch()
  const fewCentsBidResponse = useAppSelector((store: any) => store?.paywall?.fewCentsBidResponse)
  const track = useAppSelector((store: any) => store?.paywall?.config?.track)
  const isAuthenticated = useAppSelector((store: any) => store?.auth?.isAuthenticated)
  const askPrice = useAppSelector((store: any) => store?.paywall.askPrice)
  let selectedIndex = -1
  if (askPrice && fewCentsBidResponse.customerPriceOptions) {
    selectedIndex = fewCentsBidResponse.customerPriceOptions?.prices.indexOf(askPrice)
  }
  const [selectedTipjarPriceIndex, setSelectedTipjarPriceIndex] = useState(selectedIndex)
  const priceRef = useRef<Boolean>(true)
  const currencyDisplayString = fewCentsBidResponse?.currencyDisplayString


  function handleTipjarOptionChange(e: any, price: number, index: number) {
    e.stopPropagation()
    track(['price_option_selected', { price: price, isBubble: bubble, index }])
    fc_logger.info(price)
    dispatch(updateAskPrice(price))
  }

  function getIndexToBeSelected(prices: number[], balance: Number) {
    var selectedIndex = -1
    var middleIndex = Math.floor((prices?.length - 1) / 2)
    if (balance < prices[0]) return middleIndex
    for (let i = 0; i < prices?.length; i++) {
      if (balance >= prices[i] && i <= middleIndex) selectedIndex = i
    }
    return selectedIndex
  }

  useEffect(() => {
    if (askPrice) return
    fc_logger.info('setting askPrice')
    if (fewCentsBidResponse.customerCurrency || isAuthenticated) {
      fc_logger.info('setting askPrice by customer')
      const indexToBeSelected = isAuthenticated
        ? getIndexToBeSelected(
            fewCentsBidResponse.customerPriceOptions.prices,
            fewCentsBidResponse.customerBalance
          )
        : Math.floor((fewCentsBidResponse?.customerPriceOptions?.prices?.length - 1) / 2)
      if (indexToBeSelected !== -1) {
        dispatch(updateAskPrice(fewCentsBidResponse.customerPriceOptions.prices[indexToBeSelected]))
      } else {
        dispatch(updateAskPrice(-1))
      }
    } else {
      fc_logger.info('setting askPrice by publisher')
      const indexToBeSelected = Math.floor(
        (fewCentsBidResponse?.publisherPriceOptions?.prices?.length - 1) / 2
      )
      if (indexToBeSelected !== -1) {
        dispatch(
          updateAskPrice(fewCentsBidResponse.publisherPriceOptions.prices[indexToBeSelected])
        )
      } else {
        dispatch(updateAskPrice(-1))
      }
    }
  }, [fewCentsBidResponse])

  useEffect(() => {
    if (priceRef.current) {
      priceRef.current = false
    } else {
      if (askPrice && fewCentsBidResponse?.customerPriceOptions) {
        const selectedIndex = fewCentsBidResponse.customerPriceOptions.prices.indexOf(askPrice)
        if (selectedIndex > -1) {
          setSelectedTipjarPriceIndex(selectedIndex)
        }
      }
    }
  }, [askPrice])

  let priceOptions = fewCentsBidResponse.publisherPriceOptions
  let currency = fewCentsBidResponse?.publisherCurrency
    ? fewCentsBidResponse.customerPriceOptions
    : fewCentsBidResponse.publisherPriceOptions

  if (isAuthenticated || fewCentsBidResponse?.customerCurrency) {
    priceOptions = fewCentsBidResponse.customerPriceOptions
    currency = fewCentsBidResponse?.customerCurrency
  }

  return {
    priceOptions,
    currency,
    selectedTipjarPriceIndex,
    selectedIndex: selectedTipjarPriceIndex,
    handleTipjarOptionChange,
    currencyDisplayString
  }
}

export default useTipjarPrices
