import usePostloginPrice from '../../hooks/usePostloginPrice'
import { LinkContainer } from '../Styled'
import { Heading3, NormalText } from '../Styled/Typography'

type PrimaryCardProps = {
  fcBalanceText?: string
}

export const PostLogin = ({ fcBalanceText }: PrimaryCardProps) => {
  const { buttonText, handleButtonClick } = usePostloginPrice()
  return (
    <LinkContainer
      flexDirection="column"
      onClick={handleButtonClick}
      hasRewards={!!fcBalanceText}
      invertColors={true}
    >
      {fcBalanceText ? <NormalText lineHeight="25px !important">{fcBalanceText}</NormalText> : null}
      <Heading3 lineHeight="25px !important" fontWeight="600">
        {buttonText}
      </Heading3>
    </LinkContainer>
  )
}
