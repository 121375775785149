import { useAppSelector } from '../../store'
import { Absolute } from '../Bubble/styled'
import { Logout } from '../Icons'

export const LogoutButton = ({ handleLogout, ...props }: any) => {
  const config = useAppSelector((store: any) => store?.paywall?.config)

  return !config?.isSimEnabled ? (
    <Absolute title="Logout" onClick={handleLogout} {...props}>
      <Logout />
    </Absolute>
  ) : null
}
