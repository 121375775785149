import styled from 'styled-components'

export const Fonts = styled.div<{ isLinkout: boolean }>`
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  position: relative;
  margin: ${({ theme, isLinkout }) => (theme.hasPlayer || isLinkout ? 'auto' : '16px auto')};
`
